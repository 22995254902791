import React, { useState, useEffect } from "react";
import { Button, Collapse, Modal, Toast, ToastContainer } from "react-bootstrap";
import { IoIosArrowDown, IoIosArrowUp } from "react-icons/io"
import { BiLoaderAlt } from 'react-icons/bi';
import { DateTime } from 'luxon'
import Auth from "../../utils/Auth";
import classes from "../../pages/expert/ExpertCreateCompetition.module.css";
import { DateTimeUtil } from "../../utils/DateTimeUtil";
import { FileOperationsUtil } from "../../utils/FileOperationsUtil";
import { AuthorizedDataService } from "../../services/AuthorizedDataService";
import { AppConstants } from "../../constants/AppConstants";
import { ErrorMessages } from "../../constants/ErrorMessages";
import { SuccessMessage } from "../../constants/SuccessMessage";
import { DataService } from "../../services/DataService";
import { EditCompetitionDetails } from "./EditCompetitionDetails";
import { EditJudgementParameters } from "./EditJudgementParameters";
import { EditCompetitionCertificate } from "./EditCompetitionCertificate";
import { EditAdvancedCompetitionSettings } from "./EditAdvancedCompetitionSettings";
import { EditCompetitionChallenge } from "./EditCompetitionChallenge";
import { ErrorSuccessAlertMessage } from "../errorSuccessMessage/ErrorSuccessAlertMessage";
import { EditJudges } from "./EditJudges";
import { AiOutlineCheckCircle, AiOutlineExclamationCircle } from "react-icons/ai";
import modalClass from "../cards/CompetitionsCards.module.css";
import { AddMedalCriteria } from "../createCompetitionComponents/AddMedalCriteria/AddMedalCriteria";
import { EditGymnasticsJudgementParameters } from "./EditGymnasticsJudgementParameters";
import { StringUtils } from "../../utils/StringUtils";

export const EditCompetitionComponent = (props) => {
    const stages = AppConstants.editCompetitionActions;
    const basicDetailsFormLabels = AppConstants.addCompetitionDetailsLabels;
    const gymnasticsStages = AppConstants.editGymnasticsCompetitionActions;
    const certificateFromLabels = AppConstants.addCertificateFormLabels;

    const [competitionDetails, setCompetitionDetails] = useState({});
    const [judgingStartedFlag, setJudgingStartedFlag] = useState(AppConstants.falseText);
    const [configuredCertificateDetails, setConfiguredCertificateDetails] = useState(AppConstants.emptyString);
    const [previousRubricsDetails, setPreviousRubricsDetails] = useState([]);
    const [basicDetailsFormData, setBasicDetailsFormData] = useState({});
    const [problemStatementData, setProblemStatementData] = useState({});
    const [rubricsData, setRubricsData] = useState({});
    const [certificateData, setCertificateData] = useState({});
    const [judgesData, setJudgesData] = useState([]);
    const [showToast, setShowToast] = useState(false);
    const [toastHeading, setToastHeading] = useState(AppConstants.emptyString);
    const [toastBody, setToastBody] = useState(AppConstants.emptyString);
    const [open, setOpen] = useState([true, false, false, false, false, false]);
    const [basicDetailsFormError, setBasicDetailsFormError] = useState({});
    const [skills, setSkills] = useState([]);
    const [showSpinner, setShowSpinner] = useState(false);
    const [guidelinesError, setGuidelinesError] = useState(AppConstants.emptyString);
    const [toastBackground, setToastBackground] = useState(AppConstants.alertVarient[0]);
    const [template, setTemplate] = useState({});
    const [competitionLink, setCompetitionLink] = useState(AppConstants.emptyString);
    const [formErrors, setFormErrors] = useState([]);
    const [formValid, setFormValid] = useState(false);
    const [allowedParticipants, setAllowedParticipants] = useState(AppConstants.emptyString);
    const [advancedSettingsData, setAdvancedSettingsData] = useState({});
    const [rubricsConfigured, setRubricsConfigured] = useState(AppConstants.trueText);
    const timeZone = DateTimeUtil.getPreferredTimeZoneForUser();
    const [isExpertAssociatedWithClubQrencia, setIsExpertAssociatedWithClubQrencia] = useState(AppConstants.falseText);
    const [schoolId, setSchoolId] = useState(AppConstants.emptyString);
    const [clubQrenciaValidation, setClubQrenciaValidation] = useState(AppConstants.emptyString);
    const [competitionMinimumCost, setCompetitionMinimumCost] = useState(AppConstants.emptyString);
    const [showLoader, setShowLoader] = useState(AppConstants.trueText);
    const [showDetailsError, setShowDetailsError] = useState(AppConstants.falseText);
    const [showModal, setShowModal] = useState(AppConstants.falseText);
    const [modalTypeError, setModalTypeError] = useState(AppConstants.falseText);
    const [modalMessage, setModalMessage] = useState(AppConstants.emptyString);
    const [minimumSignupAge, setMinimumSignupAge] = useState(AppConstants.emptyString);
    const [isGymnasticsSelected, setIsGymnasticsSelected] = useState(AppConstants.falseText);
    const [medalErrors, setMedalErrors] = useState({});
    const [medalData, setMedalData] = useState({});
    const [rubricsErrors, setRubricsErrors] = useState({});
    const [executionMaximumScore, setExecutionMaximumScore] = useState(0);

    const numberInputs = document.querySelectorAll('input[type=number]');
    numberInputs.forEach(input => {
        input.addEventListener('wheel', (e) => e.preventDefault());
    });

    // To fetch competition specific details (competition details, rubrics, judges, problem statement, certificate details)
    useEffect(() => {
        const getCertificateData = async () => {
            const url = `${AppConstants.getCompetitonSpecificCertificateDetailsApi}${props.competitionId}`
            const response = await AuthorizedDataService.getRequest(
                url,
                AppConstants.emptyString,
                AppConstants.emptyString
            );
            return response;
        }

        const fetchCompetitionDetails = async () => {
            const params = {
                competitionId: props.competitionId,
                skillName: props.selectedSkillName
            }
            const url = StringUtils.createURLWithParams(AppConstants.fetchCompetitionDetailsWithParameterAPI, params);
            const response = await AuthorizedDataService.getRequest(
                url,
                AppConstants.emptyString,
                AppConstants.emptyString
            );

            return response;
        };

        const fetchCompetitionSpecificRubrics = async () => {
            const url = `${AppConstants.getCompetitionSpecificRubricsApi}${props.competitionId}`;
            const response = await AuthorizedDataService.getRequest(
                url,
                AppConstants.emptyString,
                AppConstants.emptyString
            );
            if (response) {
                let rubricsArray = [];
                response.forEach((res, i) => rubricsArray.push({ ...res, editable: true, marks: res.aspectMarks, isIncludedFlag: AppConstants.yText.toUpperCase(), included: true, previousRubrics: AppConstants.trueText }));
                setPreviousRubricsDetails(rubricsArray);
            } else {
                setPreviousRubricsDetails([]);
            }
        };

        const fetchBasicDetails = async () => {
            const [basicDetails, certificate] = await Promise.all([fetchCompetitionDetails(), getCertificateData()]);
            if (basicDetails && certificate) {
                setConfiguredCertificateDetails(certificate);
                setCompetitionDetails(basicDetails);
                setShowLoader(AppConstants.falseText);
                setShowDetailsError(AppConstants.falseText);
            } else {
                setShowLoader(AppConstants.falseText);
                setShowDetailsError(AppConstants.trueText);
            }
        };

        const fetchJudgingStatus = async () => {
            const url = `${AppConstants.judgingStartedAPI}${props.competitionId}`;
            const response = await AuthorizedDataService.getRequestWithResponseCode(
                url,
                AppConstants.emptyString,
                AppConstants.emptyString
            );

            if (response.status === AppConstants.httpResponseCodes.responseCode500) {
                setJudgingStartedFlag(AppConstants.falseText);
            } else {
                setJudgingStartedFlag(AppConstants.trueText);
            }
        }

        fetchCompetitionSpecificRubrics();
        fetchBasicDetails();
        fetchJudgingStatus();
    }, [props.competitionId]);

    useEffect(() => {
        setOpen(createOpenArray(0));
    }, [isGymnasticsSelected]);

    useEffect(() => {
        const fetchSkillsForExpert = async () => {
            const url = AppConstants.fetchExpertSkillsApi;
            const response = await AuthorizedDataService.getRequest(
                url,
                AppConstants.emptyString,
                AppConstants.emptyString
            );
            if (response) {
                // Sort the skills alphabetically based on skillName
                const sortedSkills = response.sort((a, b) =>
                    a.skillName.localeCompare(b.skillName)
                );

                // Set the sorted skills array in the state
                setSkills(sortedSkills);
            }

        };

        const fetchConfigurableKeys = async () => {
            const url = AppConstants.fetchConfigurableKeysAPI;
            const response = await DataService.get(
                url,
                AppConstants.emptyString,
                AppConstants.emptyString
            );
            if (response) {
                setCompetitionMinimumCost(parseInt(response.find(item => item.keyName === AppConstants.competitionMinCostKey).keyValue));
                setMinimumSignupAge(parseInt(response.find(item => item.keyName === AppConstants.signupMinimumAgeKey).keyValue));
                setExecutionMaximumScore(parseInt(response.find(item => item.keyName === AppConstants.maxEScoreKeyName).keyValue));
            }
        };

        async function fetchSkillsForAgency() {
            const url = AppConstants.getSkillsofAgencyAPI;
            const response = await AuthorizedDataService.getRequest(
                url,
                AppConstants.emptyString,
                AppConstants.emptyString
            );

            if (response) {
                // Sort the skills alphabetically based on skillName
                setSkills(response.sort((a, b) =>
                    a.skillName.localeCompare(b.skillName)
                ));
            }
        };

        async function getClubQrenciaAssociationStatus() {
            let url = AppConstants.expertAssociationWithSchoolAPI;
            const response = await AuthorizedDataService.getRequest(
                url,
                AppConstants.emptyString,
                AppConstants.emptyString
            );
            if (response) {
                setIsExpertAssociatedWithClubQrencia(response.associationFlag);
                setSchoolId(response.schoolId);
            } else {
                setIsExpertAssociatedWithClubQrencia(AppConstants.nText);
            }
        };

        getClubQrenciaAssociationStatus();
        fetchConfigurableKeys();

        if (Auth.getLoggedInUserDetails().userRole === AppConstants.userRoleAgency) {
            fetchSkillsForAgency();
        } else {
            fetchSkillsForExpert();
        }
    }, []);

    useEffect(() => {
        const getCertificateTemplate = async () => {
            const url = AppConstants.fetchCertificateTemplateApi;
            const response = await AuthorizedDataService.getRequest(
                url,
                AppConstants.emptyString,
                AppConstants.emptyString
            );

            setTemplate(response);
        }
        getCertificateTemplate();
    }, []);

    useEffect(() => {
        let errorValues = Object.values(basicDetailsFormError);
        setFormErrors(errorValues);

        if (errorValues?.length === 0 && basicDetailsFormData.title?.trim() != AppConstants.emptyString && guidelinesError === AppConstants.emptyString && basicDetailsFormData.description?.replace(/<(.|\n)*?>/g, '').trim().length !== 0) {
            setFormValid(true);
        } else {
            setFormValid(false);
        }
    }, [basicDetailsFormError, guidelinesError, basicDetailsFormData.description]);

    useEffect(() => {
        setIsGymnasticsSelected(isGymnasticsSkillSelected(basicDetailsFormData));
    }, [skills, basicDetailsFormData.skill]);

    // function to capture basic details
    const changeBasicFormDetailsData = (newData) => {
        setBasicDetailsFormData({ ...newData });
        setAllowedParticipants(newData.allowedNumberOfParticipants);
        setShowToast(false);
    }

    // function to capture basic details form errors
    const changeBasicDetailsFormError = (errors, guidelines_error) => {
        setBasicDetailsFormError(errors);
        setGuidelinesError(guidelines_error);
    }

    // function to capture problem statement data
    const changeProblemStatementData = (newData) => {
        setProblemStatementData({ ...problemStatementData, ...newData })
        setShowToast(false);
    }

    // function to capture rubrics data
    const changeRubricsData = (newData) => {
        setRubricsData({
            rubrics: newData.rubrics,
            outOfScore: newData.outOfScore,
            showPercentageFlag: newData.showPercentageFlag
        })
        setRubricsConfigured(newData.rubricsConfigured);
        setRubricsErrors(newData.rubricsErrors);
        setShowToast(false);
    }

    // function to capture certificate data
    const changeCertificateData = (newData) => {
        setCertificateData({ ...newData });
        setShowToast(false);
    };

    // function to capture the medals data
    const changeMedalsData = (newData) => {
        setMedalData(newData.medalData);
        setMedalErrors(newData.medalErrors);
    }

    const checkCertificateValidity = () => {
        let certificateErrorMessages = Object.values(certificateData.certificateDataErrors);
        for (let i = AppConstants.zeroIndex; i < certificateErrorMessages.length; i++) {
            if (certificateErrorMessages[i] !== AppConstants.emptyString) {
                return false;
            }
        }

        if (certificateData.logoUpdated && certificateData.messageUpdated) {
            if (certificateData.signatoryOneUpdated === AppConstants.incompleteText || certificateData.signatoryTwoUpdated === AppConstants.incompleteText || certificateData.signatoryThreeUpdated === AppConstants.incompleteText) {
                return false;
            } else if (certificateData.signatoryOneUpdated === AppConstants.emptyString && certificateData.signatoryTwoUpdated === AppConstants.emptyString && certificateData.signatoryThreeUpdated === AppConstants.emptyString) {
                return false;
            } else {
                return true;
            }
        } else if (certificateData.logoUpdated || certificateData.messageUpdated) {
            return false;
        } else {
            if (certificateData.signatoryOneUpdated === AppConstants.emptyString && certificateData.signatoryTwoUpdated === AppConstants.emptyString && certificateData.signatoryThreeUpdated === AppConstants.emptyString) {
                return true;
            } else {
                return false;
            }
        }
    }
    const createOpenArray = (trueIndex = null) => {
        let newArray = new Array(getCompetitionStages().length).fill(false);
        if (trueIndex !== null) {
            newArray[trueIndex] = true;
        };

        return newArray;
    }
    const validateRubrics = () => {
        if (!isGymnasticsSelected) {
            return AppConstants.emptyString;
        }

        if ((rubricsData.outOfScore === AppConstants.emptyString || rubricsData.outOfScore === AppConstants.nullText) && rubricsData.showPercentageFlag === AppConstants.yText && rubricsConfigured) {
            return ErrorMessages.gymnasticsOutOfScoreRequiredError;
        }

        const firstError = rubricsErrors.find(error =>
            Object.values(error).some(value => value)
        );

        return firstError ? Object.values(firstError).find(value => value) : AppConstants.emptyString;
    };

    const validateMedals = () => {
        if (!isGymnasticsSelected || rubricsData.showPercentageFlag !== AppConstants.yText) {
            return AppConstants.emptyString;
        }

        const allAcademyCriteriaEmpty = Object.values(medalData.academy).every(value => value === AppConstants.emptyString || value === null);
        const allSchoolCriteriaEmpty = Object.values(medalData.school).every(value => value === AppConstants.emptyString || value === null);

        if ((!allAcademyCriteriaEmpty && allSchoolCriteriaEmpty) || (!allSchoolCriteriaEmpty && allAcademyCriteriaEmpty)) {
            return allAcademyCriteriaEmpty ? ErrorMessages.addMedalCriteriaForAcademyError : ErrorMessages.addMedalCriteriaForSchoolError;
        }


        if (medalData.awardType === AppConstants.gymnasticsAwardType.medalBased && allAcademyCriteriaEmpty && allSchoolCriteriaEmpty) {
            return ErrorMessages.addMedalCriteriaError;
        }

        return Object.values(medalErrors.academy).find(error => error) || Object.values(medalErrors.school).find(error => error) || AppConstants.emptyString;
    };

    // function to capture judges data
    const changeJudgesData = (newData) => setJudgesData([...newData]);

    // function to capture advanced settings data
    const changeAdvancedSettings = (newData) => {
        setAdvancedSettingsData(newData);
    };

    const createDateTimeObject = (date, time) => {
        if (!date || !time) {
            return AppConstants.nullText;
        }
        const dateArr = date.split(AppConstants.hyphen);
        const timeArr = time.split(AppConstants.colon);
        const dateTime = DateTime.fromObject({
            year: dateArr[0],
            month: dateArr[1],
            day: dateArr[2],
            hour: timeArr[0],
            minute: timeArr[1],
            second: 0
        }, { zone: timeZone }
        );

        return dateTime;
    };

    const formatDateTime = (dateTime) => {
        return dateTime.toFormat(`${AppConstants.jsDateTimeStartFormat} \'GMT\'ZZZ \'(${DateTimeUtil.getFullZoneName(timeZone)})\'`);
    };

    const formatRichTextContent = (content) => {
        var regex = /^(<p\s+class="[^"]*"><br><\/p>)+|(<p\s+class="[^"]*"><br><\/p>)+$/gm;
        let htmlText = content.replace(regex, AppConstants.emptyEditorString);
        const modifiedText = htmlText.replace(/^(<p><br><\/p>)+|(<p><br><\/p>)+$/g, '');
        if (modifiedText === AppConstants.emptyString) {
            return AppConstants.emptyEditorString
        } else {
            return modifiedText;
        }
    }

    // Function to build request object for competitionDetails
    const buildBasicDetailsProps = (filePaths) => {
        const start_date_time = formatDateTime(createDateTimeObject(basicDetailsFormData.date, basicDetailsFormData.startTime));
        const end_date_time = formatDateTime(createDateTimeObject(basicDetailsFormData.endDate, basicDetailsFormData.endTime));

        let registration_close_time;

        if (basicDetailsFormData.registrationClosingDate !== AppConstants.emptyString && basicDetailsFormData.registrationClosingTime !== AppConstants.emptyString) {
            registration_close_time = formatDateTime(createDateTimeObject(basicDetailsFormData.registrationClosingDate, basicDetailsFormData.registrationClosingTime));
        } else {
            // Setting default value of registration closing time to 1 minute before competition starting time
            let date = createDateTimeObject(basicDetailsFormData.endDate, basicDetailsFormData.endTime);
            registration_close_time = formatDateTime(date.minus({ minutes: 1 })); // Subtracting 1 minute from Competition end time.
        }

        let competitionBasicDetails = {
            title: basicDetailsFormData.title.trim(),
            description: formatRichTextContent(basicDetailsFormData.description),
            stringStartTime: start_date_time,
            stringEndTime: end_date_time,
            registrationClosesOn: registration_close_time,
            thumbnailImagePath: filePaths.thumbnailImagePath ? filePaths.thumbnailImagePath : (basicDetailsFormData.uploadedThumbnailImage ? competitionDetails.thumbnailImageRelativePath : AppConstants.nullText),
            competitionType: basicDetailsFormData.competitionType,
            vcLink: basicDetailsFormData.vcLink?.trim() || AppConstants.nullText,
            geoLocation: basicDetailsFormData.competitionType === AppConstants.competitionTypeOffline ? basicDetailsFormData.geoLocation?.trim() : AppConstants.nullText,
            venueLocation: basicDetailsFormData.competitionType === AppConstants.competitionTypeOffline ? basicDetailsFormData.venueLocation.replace(/\n/g, ' ')?.trim() : AppConstants.nullText,
            skillId: skills.length === 1 ? skills[0].skillId : basicDetailsFormData.skill,
            skillName: skills.length === 1 ? skills[0].skillName : skills.filter(skill => basicDetailsFormData.skill === skill.skillId)[0]?.skillName,
            promotionImagePathMobile: filePaths.promotionImagePathMobile ? filePaths.promotionImagePathMobile : (basicDetailsFormData.uploadedPromotionImageMobile ? competitionDetails.promotionImageMobileRelativePath : AppConstants.nullText),
            promotionImagePathTablet: filePaths.promotionImagePathTablet ? filePaths.promotionImagePathTablet : (basicDetailsFormData.uploadedPromotionImageTablet ? competitionDetails.promotionImageTabletRelativePath : AppConstants.nullText),
            promotionImagePathWeb: filePaths.promotionImagePathWeb ? filePaths.promotionImagePathWeb : (basicDetailsFormData.uploadedPromotionImageWeb ? competitionDetails.promotionImageWebRelativePath : AppConstants.nullText),
            allowedNumberOfParticipants: basicDetailsFormData.allowedNumberOfParticipants,
            maxAge: basicDetailsFormData.maxAge || AppConstants.nullText,
            minAge: basicDetailsFormData.minAge || AppConstants.nullText,
            resultDeclared: basicDetailsFormData.resultDeclareBy ? basicDetailsFormData.resultDeclareBy : AppConstants.nullText,
            resultDate: basicDetailsFormData.resultDeclareDate ? basicDetailsFormData.resultDeclareDate : AppConstants.nullText,
            allowSubmissionFlag: basicDetailsFormData.submission,
            clubQrenciaFlag: competitionDetails.clubQrenciaFlg,
            guidelinesDocumentPath: filePaths.guidelinesDocumentPath ? filePaths.guidelinesDocumentPath : (basicDetailsFormData.uploadedGuidelinesDocument ? competitionDetails.guidelinesDocumentRelativePath : AppConstants.nullText),
            guidelinesText: formatRichTextContent(basicDetailsFormData.guidelinesText) === AppConstants.emptyEditorString ? AppConstants.nullText : formatRichTextContent(basicDetailsFormData.guidelinesText),
            schoolId: competitionDetails.selectedClasses ? schoolId : AppConstants.nullText,
            competitionFeeWaiverFlag: competitionDetails.competitionFeeWaiverFlag,
            disablePublicEnrolmentFlag: competitionDetails.disablePublicEnrolmentFlag,
            disablePerformanceResultFlag: !rubricsConfigured ? AppConstants.yText : advancedSettingsData.performanceReport === AppConstants.falseText ? AppConstants.nText : AppConstants.yText,
            selectedClasses: competitionDetails.selectedClasses,
            cost: (competitionDetails.clubQrenciaFlg === AppConstants.yText || competitionDetails.competitionFeeWaiverFlag === AppConstants.yText) ? AppConstants.zeroIndex : basicDetailsFormData.cost,
            payOfflineFlag: (competitionDetails.clubQrenciaFlg === AppConstants.yText || competitionDetails.competitionFeeWaiverFlag === AppConstants.yText) ? AppConstants.nText : advancedSettingsData.enableOfflinePayment ? AppConstants.yText : AppConstants.nText,
            awardType: isGymnasticsSelected ? (medalData.awardType || AppConstants.nullText) : AppConstants.nullText,
            showPercentageFlag: isGymnasticsSelected ? rubricsData.showPercentageFlag : AppConstants.nText
        }

        return competitionBasicDetails;
    };

    const getRubricsForGymnastics = (data) => {
        return data.filter(criterion => criterion.isCriteriaIncluded).flatMap(criterion =>
            criterion.aspects.map(aspect => ({
                criterionId: criterion.criterionId,
                aspectDescription: aspect.aspectDescription,
                marks: AppConstants.nullText,
                gender: criterion.gender,
                included: aspect.included
            }))
        );
    };

    // Function to build request object for competition challenge
    const buildCompetitionChallengeProps = (filepaths) => {
        let isChallengeTextAdded = problemStatementData.problemStatement?.replace(/<(.|\n)*?>/g, '').trim().length === 0 ? AppConstants.falseText : AppConstants.trueText;

        let competitionChallenge = {
            challengeText: !isChallengeTextAdded ? AppConstants.nullText : formatRichTextContent(problemStatementData.problemStatement),
            challengePath: filepaths.challengePath ? filepaths.challengePath : problemStatementData.uploadedProblemStatementPath ? competitionDetails.questionPaperFileRelativePath : AppConstants.nullText,
            challengeRubrics: []
        };

        if (judgingStartedFlag) {
            competitionChallenge.challengeRubrics = AppConstants.nullText;
            return competitionChallenge;
        }

        const rbx = isGymnasticsSelected ? getRubricsForGymnastics(rubricsData.rubrics) : rubricsData.rubrics;
        rbx.forEach(rubrics => {
            if (rubrics.included && rubrics.aspectDescription?.trim().length) {
                competitionChallenge.challengeRubrics.push({
                    criteriaId: rubrics.criterionId,
                    aspectDescription: rubrics.aspectDescription,
                    aspectMarks: rubrics.marks || AppConstants.nullText,
                    gender: rubrics.gender || AppConstants.nullText,
                    levelWiseOutOfValue: rubricsData.outOfScore || AppConstants.nullText,
                    executionMaxScore: executionMaximumScore
                });
            }
        });

        return competitionChallenge;
    };

    // Function to build request object for competition certificate
    const buildCertificateDataProps = (filePaths) => {
        let competitionCertificate = {
            title: certificateData.certificateMessage?.trim() ? AppConstants.certificateOfParticipation : AppConstants.nullText,
            description: AppConstants.nullText,
            certificateMessage: certificateData.certificateMessage.trim() || AppConstants.nullText,
            numberOfMeritParticipants: (isGymnasticsSelected && medalData.awardType === AppConstants.gymnasticsAwardType.medalBased) ? AppConstants.nullText : ((certificateData.meritRange === AppConstants.emptyString || certificateData.meritRange === AppConstants.nullText) ? 3 : certificateData.meritRange),
            logoOnePath: filePaths.logoOnePath ? filePaths.logoOnePath : (certificateData.uploadedLogoOne ? configuredCertificateDetails.logoOnePath : AppConstants.nullText),
            logoTwoPath: filePaths.logoTwoPath ? filePaths.logoTwoPath : (certificateData.uploadedLogoTwo ? configuredCertificateDetails.logoTwoPath : AppConstants.nullText),
            logoThreePath: filePaths.logoThreePath ? filePaths.logoThreePath : (certificateData.uploadedLogoThree ? configuredCertificateDetails.logoThreePath : AppConstants.nullText) || AppConstants.nullText,
            logoFourPath: AppConstants.nullText,
            signatoryOneName: certificateData.signatoryOneName || AppConstants.nullText,
            signatoryTwoName: certificateData.signatoryTwoName || AppConstants.nullText,
            signatoryThreeName: certificateData.signatoryThreeName || AppConstants.nullText,
            signatoryOneDesignation: certificateData.signatoryOneDesignation || AppConstants.nullText,
            signatoryTwoDesignation: certificateData.signatoryTwoDesignation || AppConstants.nullText,
            signatoryThreeDesignation: certificateData.signatoryThreeDesignation || AppConstants.nullText,
            signatoryImageOnePath: filePaths.signatoryImageOnePath ? filePaths.signatoryImageOnePath : (certificateData.uploadedSignatoryImageOne ? configuredCertificateDetails.signatoryImageOnePath : AppConstants.nullText),
            signatoryImageTwoPath: filePaths.signatoryImageTwoPath ? filePaths.signatoryImageTwoPath : (certificateData.uploadedSignatoryImageTwo ? configuredCertificateDetails.signatoryImageTwoPath : AppConstants.nullText),
            signatoryImageThreePath: filePaths.signatoryImageThreePath ? filePaths.signatoryImageThreePath : (certificateData.uploadedSignatoryImageThree ? configuredCertificateDetails.signatoryImageThreePath : AppConstants.nullText),
            signatoryOneOrganization: certificateData.signatoryOneOrganization || AppConstants.nullText,
            signatoryTwoOrganization: certificateData.signatoryTwoOrganization || AppConstants.nullText,
            signatoryThreeOrganization: certificateData.signatoryThreeOrganization || AppConstants.nullText,
            signatoryFourName: AppConstants.nullText,
            signatoryFourDesignation: AppConstants.nullText,
            signatoryFourOrganization: AppConstants.nullText,
            signatoryImageFourPath: AppConstants.nullText,
            templateId: certificateData.certificateMessage?.trim() ? template[0]?.id : AppConstants.nullText
        };

        return competitionCertificate;
    };

    const buildJudgeDetailsProps = () => {
        let competitionJudges = [];
        let initialJudges = competitionDetails.judgeDetails;
        let modifiedJudgesList = judgesData?.map(judge => judge.judgeEmail);
        let initialJudgesEmails = initialJudges?.map(judge => judge.judgeEmail);
        for (let i = 0; i < initialJudges.length; i++) {
            let data = {
                judgeName: initialJudges[i].judgeName,
                judgeEmail: initialJudges[i].judgeEmail,
                judgeUniqueId: initialJudges[i].judgeUniqueId,
                isJudgeDeleteFlg: AppConstants.nText
            };

            if (modifiedJudgesList.includes(initialJudges[i].judgeEmail)) {
                competitionJudges.push({ ...data, isJudgeDeleteFlg: rubricsConfigured ? AppConstants.nText : AppConstants.yText })
            } else {
                competitionJudges.push({ ...data, isJudgeDeleteFlg: AppConstants.yText });
            }
        }

        if (rubricsConfigured) {
            judgesData.forEach(judge => {
                if (!initialJudgesEmails.includes(judge.judgeEmail)) {
                    competitionJudges.push(judge);
                }
            });
        }

        return competitionJudges;
    };

    // scroll function
    const scrollToSection = () => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth'
        });
    };

    const closeModal = () => {
        setShowModal(false);
        if (competitionLink.length) {
            props.showCompetitionDetails(competitionDetails);
            setCompetitionLink(AppConstants.emptyString);
        }
    }

    const setCollapseContainerState = (step) => {
        setOpen(prevState => prevState.map((state, i) => {
            if (i <= step) state = false;
            if (i === step + 1) state = true;
            return state;
        }));
    };

    const saveCurrentStep = ({ step }) => {
        setCollapseContainerState(step);
        scrollToSection();
    };

    // function to manage error handling operations
    const performAllErrorHandling = (errorMsg) => {
        setToastBackground(AppConstants.alertVarient[1]);
        setToastBody(errorMsg);
        setToastHeading(ErrorMessages.error);
        setShowToast(true);
        setShowSpinner(false);
    };


    const buildMedalDataProps = () => {
        if (!isGymnasticsSelected) return AppConstants.nullText;

        const createMedalData = (medalType, medalSource) => {
            return Object.entries(medalSource)
                .filter(([, value]) => value !== AppConstants.emptyString)
                .map(([key, value]) => ({
                    totalScorePercentage: value,
                    criteria: AppConstants.moreThanEqualsText,
                    medal: AppConstants.gymnasticsMedals[key].medalName,
                    instituteType: medalType
                }));
        };

        const academyData = createMedalData(AppConstants.agencyTypes.academy, medalData.academy);
        const schoolData = createMedalData(AppConstants.agencyTypes.school, medalData.school);

        return [...academyData, ...schoolData];
    };

    const uploadFiles = async () => {
        try {
            let filePaths = {
                thumbnailImagePath: AppConstants.nullText,
                promotionImagePathMobile: AppConstants.nullText,
                promotionImagePathTablet: AppConstants.nullText,
                promotionImagePathWeb: AppConstants.nullText,
                guidelinesDocumentPath: AppConstants.nullText,
                challengePath: AppConstants.nullText,
                logoOnePath: AppConstants.nullText,
                logoTwoPath: AppConstants.nullText,
                logoThreePath: AppConstants.nullText,
                signatoryImageOnePath: AppConstants.nullText,
                signatoryImageTwoPath: AppConstants.nullText,
                signatoryImageThreePath: AppConstants.nullText,
            };

            // mandatory files
            let params = [];

            // other files
            if (basicDetailsFormData.thumbnailImage?.size) params.push({ file: basicDetailsFormData.thumbnailImage, previousPath: competitionDetails.thumbnailImageRelativePath, folder: AppConstants.competitionDetailsUploadFolder, id: basicDetailsFormLabels.thumbnailImage.name, access: AppConstants.publicText });
            if (basicDetailsFormData.promotionImageForWeb?.size) params.push({ file: basicDetailsFormData.promotionImageForWeb, previousPath: competitionDetails.promotionImageWebRelativePath, folder: AppConstants.competitionDetailsUploadFolder, id: basicDetailsFormLabels.promotionImageForWeb.name, access: AppConstants.publicText });
            if (basicDetailsFormData.promotionImageForMobile?.size) params.push({ file: basicDetailsFormData.promotionImageForMobile, previousPath: competitionDetails.promotionImageMobileRelativePath, folder: AppConstants.competitionDetailsUploadFolder, id: basicDetailsFormLabels.promotionImageForMobile.name, access: AppConstants.publicText })
            if (basicDetailsFormData.promotionImageForTablet?.size) params.push({ file: basicDetailsFormData.promotionImageForTablet, previousPath: competitionDetails.promotionImageTabletRelativePath, folder: AppConstants.competitionDetailsUploadFolder, id: basicDetailsFormLabels.promotionImageForTablet.name, access: AppConstants.publicText })
            if (basicDetailsFormData.guidelinesFile?.size) params.push({ file: basicDetailsFormData.guidelinesFile, folder: AppConstants.competitionDetailsUploadFolder, id: "Guidelines-Document", previousPath: competitionDetails.guidelinesDocumentRelativePath, access: AppConstants.publicText });
            if (problemStatementData.statementFile?.size) params.push({ file: problemStatementData.statementFile, folder: AppConstants.challengesUploadFolder, id: "Problem-Statement", previousPath: competitionDetails.questionPaperFileRelativePath });
            if (certificateData.logoOne?.size) params.push({ file: certificateData.logoOne, folder: AppConstants.certificateUploadFolder, id: certificateFromLabels.logoOne.name, previousPath: configuredCertificateDetails.logoOnePath });
            if (certificateData.logoTwo?.size) params.push({ file: certificateData.logoTwo, folder: AppConstants.certificateUploadFolder, id: certificateFromLabels.logoTwo.name, previousPath: configuredCertificateDetails.logoTwoPath });
            if (certificateData.logoThree?.size) params.push({ file: certificateData.logoThree, folder: AppConstants.certificateUploadFolder, id: certificateFromLabels.logoThree.name, previousPath: configuredCertificateDetails.logoThreePath });
            if (certificateData.signatoryImageOne?.size) params.push({ file: certificateData.signatoryImageOne, folder: AppConstants.certificateUploadFolder, id: certificateFromLabels.signatoryImageOne.name, previousPath: configuredCertificateDetails.signatoryImageOnePath });
            if (certificateData.signatoryImageTwo?.size) params.push({ file: certificateData.signatoryImageTwo, folder: AppConstants.certificateUploadFolder, id: certificateFromLabels.signatoryImageTwo.name, previousPath: configuredCertificateDetails.signatoryImageTwoPath });
            if (certificateData.signatoryImageThree?.size) params.push({ file: certificateData.signatoryImageThree, folder: AppConstants.certificateUploadFolder, id: certificateFromLabels.signatoryImageThree.name, previousPath: configuredCertificateDetails.signatoryImageThreePath });

            const timestamp = DateTimeUtil.fetchCurrentTimestamp();
            const userDetails = Auth.getLoggedInUserDetails();
            let responseData = [];

            for (let i = 0; i < params.length; i++) {
                let { file, folder, id, access, previousPath } = params[i];
                let ext = file.name.split(".").pop();
                let path, response;

                if (previousPath) {
                    let folderUrl = previousPath.substr(0, previousPath.lastIndexOf("\/") + 1);
                    path = `${folderUrl}${timestamp}${i}${AppConstants.hyphen}${id}${AppConstants.dot}${ext}`;
                } else {
                    path = `${AppConstants.competitionUploadFolder}${AppConstants.forwardSlash}${userDetails.userId}${AppConstants.hyphen}${timestamp}${AppConstants.forwardSlash}${folder}${AppConstants.forwardSlash}${timestamp}${i}${AppConstants.hyphen}${id}${AppConstants.dot}${ext}`;
                }

                if (access === AppConstants.publicText) {
                    response = await FileOperationsUtil.uploadFileThroughPreSignedURL(file, path, AppConstants.s3filePermissionPublic, AppConstants.s3FileContentDispositionInline);
                } else {
                    response = await FileOperationsUtil.uploadFileThroughPreSignedURL(file, path, AppConstants.s3filePermissionPrivate, AppConstants.s3FileContentDispositionInline);
                }

                if (!(response instanceof Error)) {
                    responseData.push({ id, response });
                } else {
                    return AppConstants.nullText;
                }
            }

            responseData?.forEach((data, i) => {
                if (data.response instanceof Error) {
                    throw new Error(data.response);
                } else {
                    const { id, response } = data;
                    const location = response.objectKey;
                    if (id === basicDetailsFormLabels.thumbnailImage.name) filePaths.thumbnailImagePath = location;
                    if (id === basicDetailsFormLabels.promotionImageForMobile.name) filePaths.promotionImagePathMobile = location;
                    if (id === basicDetailsFormLabels.promotionImageForTablet.name) filePaths.promotionImagePathTablet = location;
                    if (id === basicDetailsFormLabels.promotionImageForWeb.name) filePaths.promotionImagePathWeb = location;
                    if (id === "Guidelines-Document") filePaths.guidelinesDocumentPath = location;
                    if (id === "Problem-Statement") filePaths.challengePath = location;
                    if (id === certificateFromLabels.logoOne.name) filePaths.logoOnePath = location;
                    if (id === certificateFromLabels.logoTwo.name) filePaths.logoTwoPath = location;
                    if (id === certificateFromLabels.logoThree.name) filePaths.logoThreePath = location;
                    if (id === certificateFromLabels.signatoryImageOne.name) filePaths.signatoryImageOnePath = location;
                    if (id === certificateFromLabels.signatoryImageTwo.name) filePaths.signatoryImageTwoPath = location;
                    if (id === certificateFromLabels.signatoryImageThree.name) filePaths.signatoryImageThreePath = location;
                }
            });

            return filePaths;
        } catch (error) {
            return AppConstants.nullText;
        }
    };

    // submit form data
    const handleFormSubmission = async () => {
        setShowSpinner(true);
        const certificateValid = checkCertificateValidity();
        const certificateRemoved = (competitionDetails.participantsDetails?.length > 0 && !certificateData.messageUpdated && configuredCertificateDetails.certificateMessage) ? true : false;
        const rubricsError = validateRubrics();
        const medalErrorText = validateMedals();
        const isMeritRangeValid = (isGymnasticsSelected && medalData.awardType === AppConstants.gymnasticsAwardType.medalBased) ? AppConstants.trueText : basicDetailsFormData.allowedNumberOfParticipants >= certificateData.meritRange;

        setOpen(createOpenArray());
        try {
            if (formValid && certificateValid && !certificateRemoved && rubricsError === AppConstants.emptyString && medalErrorText === AppConstants.emptyString && isMeritRangeValid && clubQrenciaValidation === AppConstants.emptyString) {
                const filePaths = await uploadFiles();
                if (filePaths === AppConstants.nullText) throw new Error(ErrorMessages.competitionEditFailedError);

                const data = {
                    competitionDetails: buildBasicDetailsProps(filePaths),
                    competitionChallenge: buildCompetitionChallengeProps(filePaths),
                    certificateInformation: buildCertificateDataProps(filePaths),
                    competitionJudges: buildJudgeDetailsProps(),
                    locale: DateTimeUtil.getPreferredTimeZoneForUser(),
                    competitionMedalDetails: buildMedalDataProps(),
                    competitionUniqueId: competitionDetails.uniqueId,
                };

                const url = AppConstants.editCompetitionAPI;
                const response = await AuthorizedDataService.postRequestWithResponseCode(
                    url,
                    data,
                    AppConstants.emptyString,
                    AppConstants.emptyString
                );

                if (response) {
                    if (response.ok && response.status === AppConstants.httpResponseCodes.responseCode200) {
                        const body = await response.json();
                        const link = `${process.env.REACT_APP_FRONT_END_URL}${body.competitionLink}`;
                        setCompetitionLink(link);
                        setModalMessage(SuccessMessage.editCompetitionSuccessful);
                        setShowSpinner(AppConstants.falseText);
                        setShowModal(AppConstants.trueText);
                        setModalTypeError(AppConstants.falseText);
                    } else if (response.status === AppConstants.httpResponseCodes.responseCode406) {
                        setOpen(createOpenArray(4));
                        scrollToSection();
                        const body = await response.json();
                        const emailList = body.emails?.substring(1, body.emails?.length - 1);
                        setModalMessage(`${ErrorMessages.editCompetitionRemoveJudgeError}${emailList}`);
                        setCompetitionLink(AppConstants.emptyString);
                        setModalTypeError(AppConstants.trueText);
                        setShowModal(AppConstants.trueText);
                        setShowSpinner(false);
                    } else {
                        setOpen(createOpenArray());
                        scrollToSection();
                        setCompetitionLink(AppConstants.emptyString);
                        setModalTypeError(AppConstants.trueText);
                        setShowModal(AppConstants.trueText);
                        setShowSpinner(false);
                    }
                } else {
                    setOpen(createOpenArray());
                    scrollToSection();
                    setCompetitionLink(AppConstants.emptyString);
                    setModalTypeError(AppConstants.trueText);
                    setShowModal(AppConstants.trueText);
                    setShowSpinner(false);
                }
            } else {
                scrollToSection();
                if (!formValid) {
                    setOpen(createOpenArray(0));
                    if (formErrors.length > 0) {
                        performAllErrorHandling(formErrors[0]);
                    } else if (formErrors.length === 0 && basicDetailsFormData.title?.trim().length !== 0) {
                        performAllErrorHandling(ErrorMessages.addGuidelinesError)
                    } else {
                        performAllErrorHandling(ErrorMessages.addCompetitionDetailsText);
                    }
                } else if (rubricsError !== AppConstants.emptyString) {
                    setOpen(createOpenArray(2));
                    performAllErrorHandling(rubricsError);
                } else if (!certificateValid) {
                    setOpen(createOpenArray(3));
                    let imageErrors = certificateData.certificateDataErrors;
                    if (imageErrors.logoOne !== AppConstants.emptyString) {
                        performAllErrorHandling(imageErrors.logoOne);
                    } else if (imageErrors.logoTwo !== AppConstants.emptyString) {
                        performAllErrorHandling(imageErrors.logoTwo);
                    } else if (imageErrors.logoThree !== AppConstants.emptyString) {
                        performAllErrorHandling(imageErrors.logoThree);
                    } else if (!certificateData.logoUpdated) {
                        performAllErrorHandling(ErrorMessages.atleastOneLogoError);
                    } else if (!certificateData.messageUpdated) {
                        performAllErrorHandling(ErrorMessages.certificateMessageError)
                    } else if (imageErrors.signatoryImageOne !== AppConstants.emptyString) {
                        performAllErrorHandling(imageErrors.signatoryImageOne);
                    } else if (certificateData.signatoryOneUpdated === AppConstants.incompleteText) {
                        performAllErrorHandling(ErrorMessages.signatoryOneError);
                    } else if (imageErrors.signatoryImageTwo !== AppConstants.emptyString) {
                        performAllErrorHandling(imageErrors.signatoryImageTwo);
                    } else if (certificateData.signatoryTwoUpdated === AppConstants.incompleteText) {
                        performAllErrorHandling(ErrorMessages.signatoryTwoError);
                    } else if (imageErrors.signatoryImageThree !== AppConstants.emptyString) {
                        performAllErrorHandling(imageErrors.signatoryImageThree);
                    } else if (certificateData.signatoryThreeUpdated === AppConstants.incompleteText) {
                        performAllErrorHandling(ErrorMessages.signatoryThreeError);
                    } else {
                        performAllErrorHandling(ErrorMessages.addOneSignatoryDetails);
                    }
                } else if (certificateRemoved) {
                    performAllErrorHandling(ErrorMessages.pelaseConfigureCertificateError);
                } else if (basicDetailsFormData.allowedNumberOfParticipants < ((isGymnasticsSelected && medalData.awardType === AppConstants.gymnasticsAwardType.medalBased) ? 0 : certificateData.meritRange)) {
                    setOpen(createOpenArray(3));
                    performAllErrorHandling(ErrorMessages.meritRangeLessThanParticipantLimit)
                } else if (medalErrorText !== AppConstants.emptyString) {
                    setOpen(createOpenArray(6));
                    performAllErrorHandling(medalErrorText);
                }
            }
        } catch (error) {
            setOpen(createOpenArray());
            scrollToSection();
            setCompetitionLink(AppConstants.emptyString);
            performAllErrorHandling(ErrorMessages.competitionEditFailedError);
        }
    };

    const isGymnasticsSkillSelected = (basicDetailsData) => {
        return skills.length === 1 && skills[0].skillName?.toLowerCase() === AppConstants.gymnasticsSkillName.toLowerCase() ? AppConstants.trueText : skills.filter(skill => basicDetailsData.skill === skill.skillId)[0]?.skillName?.toLowerCase() === AppConstants.gymnasticsSkillName.toLowerCase() ? AppConstants.trueText : AppConstants.falseText
    };

    const getCompetitionStages = () => {
        return isGymnasticsSelected ? gymnasticsStages : stages;
    };

    return (
        <>
            {showLoader ? <div className={classes.pageLoader}><BiLoaderAlt className="spinner" /></div> : showDetailsError ? <div className={classes.errorContainer}>
                <ErrorSuccessAlertMessage message={ErrorMessages.fetchCourseDetailsError} varient={AppConstants.alertVarient[1]} />
            </div> : <div>
                <div id="editCompetitionComponents" className={classes.infoComponents}>
                    {
                        getCompetitionStages().map((stage, idx) => {
                            return (<div key={idx} className={classes.collapseContainer}>
                                <div className={classes.headerSection}>
                                    <div className={classes.heading}>
                                        {stage}
                                    </div>
                                    <div className={classes.collapseIcons}>
                                        {!open[idx] && <IoIosArrowDown onClick={() => setOpen(prevState => prevState.map((state, i) => i === idx ? !state : state))} />}
                                        {open[idx] && <IoIosArrowUp onClick={() => setOpen(prevState => prevState.map((state, i) => i === idx ? !state : state))} />}
                                    </div>
                                </div>
                                <Collapse in={open[idx]}>
                                    <div id="competition-collapse-container">
                                        <div className={classes.collapseDiv}>
                                            {idx === 0 && <EditCompetitionDetails minimumSignupAge={minimumSignupAge} skills={skills} saveCurrentStep={saveCurrentStep} stage={stage} changeBasicFormDetailsData={changeBasicFormDetailsData} changeBasicDetailsFormError={changeBasicDetailsFormError} competitionMinimumCost={competitionMinimumCost} feeWaiverFlag={advancedSettingsData.feeWaiver} clubQrenciaFlg={advancedSettingsData.onlyForClubQrencia} rubricsConfigured={rubricsConfigured} competitionData={competitionDetails} />}
                                            {idx === 1 && <EditCompetitionChallenge saveCurrentStep={saveCurrentStep} changeProblemStatementData={changeProblemStatementData} stage={stage} competitionData={competitionDetails} />}
                                            {idx === 2 && (isGymnasticsSelected ? <EditGymnasticsJudgementParameters saveCurrentStep={saveCurrentStep} changeRubricsData={changeRubricsData} skillId={basicDetailsFormData.skill} stage={stage} previousSkill={competitionDetails.skillUniqueId} previousRubricsDetails={previousRubricsDetails} judgingStartedFlag={judgingStartedFlag} status={competitionDetails.competitionStatus} enrolledParticipants={competitionDetails.participantsDetails?.length} selectedShowPercentageFlag={competitionDetails.showPercentageFlag} /> : <EditJudgementParameters saveCurrentStep={saveCurrentStep} changeRubricsData={changeRubricsData} skillId={basicDetailsFormData.skill} stage={stage} previousSkill={competitionDetails.skillUniqueId} previousRubricsDetails={previousRubricsDetails} judgingStartedFlag={judgingStartedFlag} status={competitionDetails.competitionStatus} enrolledParticipants={competitionDetails.participantsDetails?.length} />)}
                                            {idx === 3 && <EditCompetitionCertificate saveCurrentStep={saveCurrentStep} changeCertificateData={changeCertificateData} rubricsConfigured={rubricsConfigured} template={template} stage={stage} allowedParticipants={allowedParticipants} certificateData={configuredCertificateDetails} competitionTitle={competitionDetails.title} enrolledParticipants={competitionDetails.participantsDetails?.length} isGymnasticsSelected={isGymnasticsSelected} awardType={medalData?.awardType} />}
                                            {idx === 4 && <EditJudges isGymnasticsSkillSelected={isGymnasticsSelected} rubricsConfigured={rubricsConfigured} saveCurrentStep={saveCurrentStep} changeJudgesData={changeJudgesData} stage={stage} competitionStatus={competitionDetails.competitionStatus} judgeDetails={competitionDetails.judgeDetails} enrolledParticipants={competitionDetails.participantsDetails?.length} selectedSkill={basicDetailsFormData.skill} />}
                                            {idx === 5 && <EditAdvancedCompetitionSettings cost={basicDetailsFormData.cost} rubricsConfigured={rubricsConfigured} saveCurrentStep={saveCurrentStep} stage={stage} changeAdvancedSettings={changeAdvancedSettings} isExpertAssociatedWithClubQrencia={isExpertAssociatedWithClubQrencia} schoolId={schoolId} competitionDetails={competitionDetails} isGymnasticsSelected={isGymnasticsSelected} />}
                                            {idx === 6 && isGymnasticsSelected && <AddMedalCriteria rubricsConfigured={rubricsConfigured} saveCurrentStep={saveCurrentStep} stage={stage} changeMedalsData={changeMedalsData} competitionDetails={competitionDetails} judgingStartedFlag={judgingStartedFlag} status={competitionDetails.competitionStatus} enrolledParticipants={competitionDetails.participantsDetails?.length} editFlag={AppConstants.trueText} showPercentageFlag={rubricsData.showPercentageFlag} />}
                                        </div>
                                    </div>
                                </Collapse>
                            </div>);
                        })
                    }
                    <div className={classes.createButton}>
                        {<Button disabled={showSpinner} type="submit" onClick={handleFormSubmission} className={classes.createComptitionButton}>{showSpinner ? <BiLoaderAlt className="spinner" /> : "Submit"}</Button>}
                    </div>
                </div>

                <ToastContainer
                    className="p-3"
                    position='middle-center'
                    style={{ zIndex: 100 }}
                >
                    <Toast show={showToast}
                        onClose={() => setShowToast(false)}
                        bg={toastBackground} animation delay={5000} autohide={false}>
                        <Toast.Header>
                            <strong className="me-auto">{toastHeading}</strong>
                        </Toast.Header>
                        <Toast.Body className="text-white">
                            {toastBody} <br></br>
                        </Toast.Body>
                    </Toast>
                </ToastContainer>

                <Modal show={showModal} onHide={closeModal} backdrop="static" keyboard={false} centered>
                    <Modal.Body className={modalClass.modalBody}>
                        {(modalTypeError === AppConstants.falseText) && <div className={modalClass.modalSuccessIcon}><AiOutlineCheckCircle /></div>}
                        {(modalTypeError === AppConstants.trueText) && <div className={modalClass.modalErrorIcon}><AiOutlineExclamationCircle /></div>}
                        <div className={modalClass.modalAlertText}>
                            {modalMessage}
                        </div>
                        <div className={modalClass.modalAlertText}>
                            {competitionLink && !advancedSettingsData.feeWaiver && <a className={classes.shareLink} href={competitionLink} target={AppConstants.openInNewTab} rel="noreferrer">{AppConstants.shareCompetitionLinkText}</a>}
                        </div>
                    </Modal.Body>
                    <Modal.Footer className={modalClass.modalFooter}>
                        <Button className={modalClass.modalCloseButton} onClick={closeModal}>Close</Button>
                    </Modal.Footer>
                </Modal>
            </div>}
        </>
    );
};