import React, { useState, useEffect } from "react";
import { Button, Form, Row } from "react-bootstrap";
import { useFormik } from "formik";
import * as Yup from 'yup';
import { MdDelete } from "react-icons/md";
import { Tooltip } from "react-tooltip";
import { RiInformationFill } from "react-icons/ri";
import { AppConstants } from "../../../constants/AppConstants";
import { ErrorMessages } from "../../../constants/ErrorMessages";
import classes from "./AddCertificate.module.css";
import formClass from "../../../common/FormStyling.module.css"
import { CertificateTemplate } from "../CertificateTemplate/CertificateTemplate";
import parse from 'html-react-parser'
import { FileUploadInput } from "../../fileUploadInput/FileUploadInput";
import { FileOperationsUtil } from "../../../utils/FileOperationsUtil";
import { BiLoaderAlt } from "react-icons/bi";
import { AiOutlineDownload } from "react-icons/ai";

export const AddCertificate = (props) => {
    const { saveCurrentStep, changeCertificateData, certificateData, stage, isGymnasticsSelected, awardType } = props;

    const initialValues = {
        logoOne: {},
        logoTwo: {},
        logoThree: {},
        meritRange: (certificateData.numberOfMeritParticipants === null || certificateData.numberOfMeritParticipants === undefined) ? AppConstants.emptyString : certificateData.numberOfMeritParticipants,
        certificateMessage: certificateData.certificateMessage || AppConstants.emptyString,
        signatoryOneName: certificateData.signatoryOneName || AppConstants.emptyString,
        signatoryOneDesignation: certificateData.signatoryOneDesignation || AppConstants.emptyString,
        signatoryOneOrganization: certificateData.signatoryOneOrganization || AppConstants.emptyString,
        signatoryTwoName: certificateData.signatoryTwoName || AppConstants.emptyString,
        signatoryTwoDesignation: certificateData.signatoryTwoDesignation || AppConstants.emptyString,
        signatoryTwoOrganization: certificateData.signatoryTwoOrganization || AppConstants.emptyString,
        signatoryThreeName: certificateData.signatoryThreeName || AppConstants.emptyString,
        signatoryThreeDesignation: certificateData.signatoryThreeDesignation || AppConstants.emptyString,
        signatoryThreeOrganization: certificateData.signatoryThreeOrganization || AppConstants.emptyString,
        signatoryImageOne: {},
        signatoryImageTwo: {},
        signatoryImageThree: {}
    };

    const formLabels = AppConstants.addCertificateFormLabels;
    const stages = AppConstants.createCompetitionActions;
    const validImgaeInput = AppConstants.supportedImageFormats;

    const [validated, setValidated] = useState(false);
    const [previewLogo, setPreviewLogo] = useState([null, null, null, null]);
    const [previewSignature, setPreviewSignature] = useState([null, null, null]);
    const [formSubmitting, setFormSubmitting] = useState(false);
    const [signatoryOneUpdated, setSignatoryOneUpdated] = useState(AppConstants.emptyString);
    const [signatoryTwoUpdated, setSignatoryTwoUpdated] = useState(AppConstants.emptyString);
    const [signatoryThreeUpdated, setSignatoryThreeUpdated] = useState(AppConstants.emptyString);
    const [logoUpdated, setLogoUpdated] = useState(false);
    const [messageUpdated, setMessageUpdated] = useState(false);
    const [logoOneSpinner, setLogoOneSpinner] = useState(AppConstants.falseText);
    const [logoTwoSpinner, setLogoTwoSpinner] = useState(AppConstants.falseText);
    const [logoThreeSpinner, setLogoThreeSpinner] = useState(AppConstants.falseText);
    const [signatureOneSpinner, setSignatureOneSpinner] = useState(AppConstants.falseText);
    const [signatureTwoSpinner, setSignatureTwoSpinner] = useState(AppConstants.falseText);
    const [signatureThreeSpinner, setSignatureThreeSpinner] = useState(AppConstants.falseText);
    const [errorValues, setErrorValues] = useState({
        logoOne: AppConstants.emptyString,
        logoTwo: AppConstants.emptyString,
        logoThree: AppConstants.emptyString,
        signatoryImageOne: AppConstants.emptyString,
        signatoryImageTwo: AppConstants.emptyString,
        signatoryImageThree: AppConstants.emptyString
    });

    const validationSchema = Yup.object().shape({
        certificateMessage: Yup.string().notRequired(),
        meritRange: Yup.number().notRequired().min(0, ErrorMessages.invalidMeritRange).integer(`Number of merit certificates ${ErrorMessages.invalidDecimalNumber}`).max(props.allowedParticipants, ErrorMessages.meritRangeLessThanParticipantLimit)
    });

    const { handleSubmit, values, touched, errors, handleBlur, setFieldValue, handleChange, setFieldTouched } = useFormik({
        initialValues,
        validationSchema,
        enableReinitialize: true,
        handleSubmit: () => { },
    });

    useEffect(() => {
        setFieldTouched(formLabels.meritRange.name);
        if (!props.rubricsConfigured) {
            setFieldValue(formLabels.meritRange.name, AppConstants.zeroIndex);
        } else {
            setFieldValue(formLabels.meritRange.name, (certificateData.numberOfMeritParticipants === null || certificateData.numberOfMeritParticipants === undefined) ? AppConstants.emptyString : certificateData.numberOfMeritParticipants);
        }
    }, [props.rubricsConfigured])

    useEffect(() => {
        setFieldTouched(formLabels.meritRange.name);
    }, [props.allowedParticipants]);

    useEffect(() => {
        let isMessageUpdated = false;

        let isLogoUpdated = false;
        if (values.certificateMessage?.trim().length > 0) isMessageUpdated = true;
        else isMessageUpdated = false;

        if (values.logoOne.size > 0 || values.logoTwo.size > 0 || values.logoThree.size > 0) {
            isLogoUpdated = true;
        } else {
            isLogoUpdated = false;
        }
        // validate signatory one
        if (values.signatoryImageOne?.size > 0 && values.signatoryOneName.trim().length > 0 && values.signatoryOneDesignation.trim().length > 0 && values.signatoryOneOrganization.trim().length > 0) {
            setSignatoryOneUpdated(AppConstants.completeText);
        } else if (values.signatoryImageOne?.size > 0 || values.signatoryOneName.trim().length > 0 || values.signatoryOneDesignation.trim().length > 0 || values.signatoryOneOrganization.trim().length > 0) {
            setSignatoryOneUpdated(AppConstants.incompleteText);
        } else {
            setSignatoryOneUpdated(AppConstants.emptyString);
        }

        // validate signatory Two
        if (values.signatoryImageTwo?.size > 0 && values.signatoryTwoName.trim().length > 0 && values.signatoryTwoDesignation.trim().length > 0 && values.signatoryTwoOrganization.trim().length > 0) {
            setSignatoryTwoUpdated(AppConstants.completeText);
        } else if (values.signatoryImageTwo?.size > 0 || values.signatoryTwoName.trim().length > 0 || values.signatoryTwoDesignation.trim().length > 0 || values.signatoryTwoOrganization.trim().length > 0) {
            setSignatoryTwoUpdated(AppConstants.incompleteText)
        } else {
            setSignatoryTwoUpdated(AppConstants.emptyString);
        }

        // validate signatory Three
        if (values.signatoryImageThree?.size > 0 && values.signatoryThreeName.trim().length > 0 && values.signatoryThreeDesignation.trim().length > 0 && values.signatoryThreeOrganization.trim().length > 0) {
            setSignatoryThreeUpdated(AppConstants.completeText);
        } else if (values.signatoryImageThree?.size > 0 || values.signatoryThreeName.trim().length > 0 || values.signatoryThreeDesignation.trim().length > 0 || values.signatoryThreeOrganization.trim().length > 0) {
            setSignatoryThreeUpdated(AppConstants.incompleteText);
        } else {
            setSignatoryThreeUpdated(AppConstants.emptyString)
        };

        setLogoUpdated(isLogoUpdated);
        setMessageUpdated(isMessageUpdated);

    }, [values]);

    useEffect(() => {
        const changeParentState = () => {
            changeCertificateData({ ...values, messageUpdated, logoUpdated, signatoryOneUpdated, signatoryTwoUpdated, signatoryThreeUpdated, certificateDataErrors: errorValues });
        }
        changeParentState();
    }, [values, logoUpdated, messageUpdated, signatoryOneUpdated, signatoryTwoUpdated, signatoryThreeUpdated, errorValues]);

    function checkNumberValidation(event) {
        let excludedKeywords = ["-", "e", "E", AppConstants.dot, AppConstants.emptyString];
        if (excludedKeywords.includes(event.key)) event.preventDefault();
    };

    useEffect(() => {
        const getUploadedFiles = async () => {
            if (certificateData.logoOneAbsolutePath) {
                let fileData = await FileOperationsUtil.fetchFileBinaryData(certificateData.logoOneAbsolutePath, formLabels.logoOne.name);
                setFieldValue(formLabels.logoOne.name, fileData ? fileData.dataTransfer.files.item(0) : AppConstants.emptyString);
                setPreviewLogo(prevState => prevState.map((state, i) => i === 0 ? certificateData.logoOneAbsolutePath : state));
            }
            if (certificateData.logoTwoAbsolutePath) {
                let fileData = await FileOperationsUtil.fetchFileBinaryData(certificateData.logoTwoAbsolutePath, formLabels.logoTwo.name);
                setFieldValue(formLabels.logoTwo.name, fileData ? fileData.dataTransfer.files.item(0) : AppConstants.emptyString);
                setPreviewLogo(prevState => prevState.map((state, i) => i === 1 ? certificateData.logoTwoAbsolutePath : state));
            }
            if (certificateData.logoThreeAbsolutePath) {
                let fileData = await FileOperationsUtil.fetchFileBinaryData(certificateData.logoThreeAbsolutePath, formLabels.logoThree.name);
                setFieldValue(formLabels.logoThree.name, fileData ? fileData.dataTransfer.files.item(0) : AppConstants.emptyString);
                setPreviewLogo(prevState => prevState.map((state, i) => i === 2 ? certificateData.logoThreeAbsolutePath : state));
            }
            if (certificateData.signatoryImageOneAbsolutePath) {
                let fileData = await FileOperationsUtil.fetchFileBinaryData(certificateData.signatoryImageOneAbsolutePath, formLabels.signatoryImageOne.name);
                setFieldValue(formLabels.signatoryImageOne.name, fileData ? fileData.dataTransfer.files.item(0) : AppConstants.emptyString);
                setPreviewSignature(prevState => prevState.map((state, i) => i === 0 ? certificateData.signatoryImageOneAbsolutePath : state));
            }
            if (certificateData.signatoryImageTwoAbsolutePath) {
                let fileData = await FileOperationsUtil.fetchFileBinaryData(certificateData.signatoryImageTwoAbsolutePath, formLabels.signatoryImageTwo.name);
                setFieldValue(formLabels.signatoryImageTwo.name, fileData ? fileData.dataTransfer.files.item(0) : AppConstants.emptyString);
                setPreviewSignature(prevState => prevState.map((state, i) => i === 1 ? certificateData.signatoryImageTwoAbsolutePath : state));
            }
            if (certificateData.signatoryImageThreeAbsolutePath) {
                let fileData = await FileOperationsUtil.fetchFileBinaryData(certificateData.signatoryImageThreeAbsolutePath, formLabels.signatoryImageThree.name);
                setFieldValue(formLabels.signatoryImageThree.name, fileData ? fileData.dataTransfer.files.item(0) : AppConstants.emptyString);
                setPreviewSignature(prevState => prevState.map((state, i) => i === 2 ? certificateData.signatoryImageThreeAbsolutePath : state));
            }
        }

        if (certificateData.certificateMessage) {
            getUploadedFiles();
        } else {
            setPreviewLogo([AppConstants.nullText, AppConstants.nullText, AppConstants.nullText, AppConstants.nullText]);
            setPreviewSignature([AppConstants.nullText, AppConstants.nullText, AppConstants.nullText]);
        }
    }, [certificateData]);

    const removeFile = (id) => {
        const input = document.getElementById(id);
        input.value = AppConstants.emptyString;
        input.type = AppConstants.inputFieldTypes.file;
        setFieldValue(id, AppConstants.emptyString);
        setErrorValues(errorValues => ({ ...errorValues, [id]: AppConstants.emptyString }));
        if (id === formLabels.logoOne.name) {
            setPreviewLogo(prevState => prevState.map((state, i) => i === 0 ? null : state));
        } else if (id === formLabels.logoTwo.name) {
            setPreviewLogo(prevState => prevState.map((state, i) => i === 1 ? null : state));
        } else if (id === formLabels.logoThree.name) {
            setPreviewLogo(prevState => prevState.map((state, i) => i === 2 ? null : state));
        } else if (id === formLabels.signatoryImageOne.name) {
            setPreviewSignature(prevState => prevState.map((state, i) => i === 0 ? null : state));
        } else if (id === formLabels.signatoryImageTwo.name) {
            setPreviewSignature(prevState => prevState.map((state, i) => i === 1 ? null : state));
        } else if (id === formLabels.signatoryImageThree.name) {
            setPreviewSignature(prevState => prevState.map((state, i) => i === 2 ? null : state));
        }
    }

    const changeLogo = (event, title) => {
        let name = event.target?.name;
        if (event.target.files[0]) {
            let type = event.target.files[0].type;
            if (!validImgaeInput.includes(type)) {
                setErrorValues(errorValues => ({ ...errorValues, [name]: `${title} ${ErrorMessages.fileTypeOtherThanJpgOrPngError}` }))
                return null;
            } else if (event.target.files[0].size > AppConstants.image5MBSizeLimit) {
                setErrorValues(errorValues => ({ ...errorValues, [name]: `${title} ${ErrorMessages.fileSizeMoreThan5mbError}` }))
                return null;
            }

            setFieldValue(name, event.target.files[0])
            setErrorValues(errorValues => ({ ...errorValues, [name]: AppConstants.emptyString }))
            const image = URL.createObjectURL(event.target.files[0]);
            if (name === formLabels.logoOne.name) {
                setPreviewLogo(prevState => prevState.map((state, i) => i === 0 ? image : state));
            } else if (name === formLabels.logoTwo.name) {
                setPreviewLogo(prevState => prevState.map((state, i) => i === 1 ? image : state));
            } else if (name === formLabels.logoThree.name) {
                setPreviewLogo(prevState => prevState.map((state, i) => i === 2 ? image : state));
            }
        } else {
            setErrorValues(errorValues => ({ ...errorValues, [name]: AppConstants.emptyString }))
            if (name === formLabels.logoOne.name) {
                setPreviewLogo(prevState => prevState.map((state, i) => i === 0 ? null : state));
            } else if (name === formLabels.logoTwo.name) {
                setPreviewLogo(prevState => prevState.map((state, i) => i === 1 ? null : state));
            } else if (name === formLabels.logoThree.name) {
                setPreviewLogo(prevState => prevState.map((state, i) => i === 2 ? null : state));
            }
            setFieldValue(name, {});
        }
    }

    const changeSignature = (event, title) => {
        let name = event.target?.name;
        if (event.target?.files[0]) {
            let type = event.target.files[0].type;
            if (!validImgaeInput.includes(type)) {
                setErrorValues(errorValues => ({ ...errorValues, [name]: `${title} ${ErrorMessages.fileTypeOtherThanJpgOrPngError}` }))
                return null;
            } else if (event.target.files[0].size > AppConstants.image5MBSizeLimit) {
                setErrorValues(errorValues => ({ ...errorValues, [name]: `${title} ${ErrorMessages.fileSizeMoreThan5mbError}` }))
                return null;
            }
            setFieldValue(name, event.target.files[0]);
            setErrorValues(errorValues => ({ ...errorValues, [name]: AppConstants.emptyString }))
            const image = URL.createObjectURL(event.target.files[0]);
            if (name === formLabels.signatoryImageOne.name) {
                setPreviewSignature(prevState => prevState.map((state, i) => i === 0 ? image : state));
            } else if (name === formLabels.signatoryImageTwo.name) {
                setPreviewSignature(prevState => prevState.map((state, i) => i === 1 ? image : state));
            } else if (name === formLabels.signatoryImageThree.name) {
                setPreviewSignature(prevState => prevState.map((state, i) => i === 2 ? image : state));
            }
        } else {
            setErrorValues(errorValues => ({ ...errorValues, [name]: AppConstants.emptyString }))
            if (name === formLabels.signatoryImageOne.name) {
                setPreviewSignature(prevState => prevState.map((state, i) => i === 0 ? null : state));
            } else if (name === formLabels.signatoryImageTwo.name) {
                setPreviewSignature(prevState => prevState.map((state, i) => i === 1 ? null : state));
            } else if (name === formLabels.signatoryImageThree.name) {
                setPreviewSignature(prevState => prevState.map((state, i) => i === 2 ? null : state));
            }
            setFieldValue(name, {});
        }
    }

    const handleButtonClick = () => {
        saveCurrentStep({ step: stages.indexOf(stage) });
    };

    const downloadFile = async (fileUrl, name) => {
        let resp = await FileOperationsUtil.downloadFileWithLoader(fileUrl, `${props.selectedCompetitionTitle}-${name}`);
        if (resp) {
            if (name === formLabels.logoOne.name) {
                setLogoOneSpinner(AppConstants.falseText);
            } else if (name === formLabels.logoTwo.name) {
                setLogoTwoSpinner(AppConstants.falseText);
            } else if (name === formLabels.logoThree.name) {
                setLogoThreeSpinner(AppConstants.falseText);
            } else if (name === formLabels.signatoryImageOne.name) {
                setSignatureOneSpinner(AppConstants.falseText);
            } else if (name === formLabels.signatoryImageTwo.name) {
                setSignatureTwoSpinner(AppConstants.falseText);
            } else if (name === formLabels.signatoryImageThree.name) {
                setSignatureThreeSpinner(AppConstants.falseText);
            }
        } else {
            if (name === formLabels.logoOne.name) {
                setLogoOneSpinner(AppConstants.falseText);
            } else if (name === formLabels.logoTwo.name) {
                setLogoTwoSpinner(AppConstants.falseText);
            } else if (name === formLabels.logoThree.name) {
                setLogoThreeSpinner(AppConstants.falseText);
            } else if (name === formLabels.signatoryImageOne.name) {
                setSignatureOneSpinner(AppConstants.falseText);
            } else if (name === formLabels.signatoryImageTwo.name) {
                setSignatureTwoSpinner(AppConstants.falseText);
            } else if (name === formLabels.signatoryImageThree.name) {
                setSignatureThreeSpinner(AppConstants.falseText);
            }
        }
    }

    return (
        <div>
            <div className={classes.configureCertContainer}>
                <Form className={classes.inputContainer} noValidate validated={validated} onSubmit={handleSubmit} >
                    <Row className="mb-2" >
                        <div className={classes.uploadFileRow}>
                            <FileUploadInput id={formLabels.logoOne.name} label={formLabels.logoOne.label} name={formLabels.logoOne.name} accept="image/png,image/jpg,image/jpeg" handleFileChange={(event) => changeLogo(event, formLabels.logoOne.label)} infoMessage={AppConstants.preferredLogoHeightMessage} inputFile={values.logoOne} />
                            {certificateData.logoOnePath && <button type="button" onClick={() => { downloadFile(certificateData.logoOneAbsolutePath, formLabels.logoOne.name); setLogoOneSpinner(AppConstants.trueText); }} data-tooltip-id="downloadLogoOne" className={classes.removeBtn}>{logoOneSpinner ? <BiLoaderAlt className="spinner" /> : <AiOutlineDownload />}</button>}
                            <button type="button" onClick={() => removeFile(formLabels.logoOne.name)} data-tooltip-id="removeLogoOne" className={classes.removeBtn}><MdDelete /></button>
                            <Tooltip className={classes.tooltipMessage} id="removeLogoOne"><span className={classes.tooltipMessage}>Remove file</span></Tooltip>
                        </div>
                        {errorValues.logoOne && <div className="errorText">{errorValues.logoOne}</div>}
                    </Row>
                    <Row className="mb-2" >
                        <div className={classes.uploadFileRow}>
                            <FileUploadInput id={formLabels.logoTwo.name} label={formLabels.logoTwo.label} name={formLabels.logoTwo.name} accept="image/png, image/jpeg" handleFileChange={(event) => changeLogo(event, formLabels.logoTwo.label)} infoMessage={AppConstants.preferredLogoHeightMessage} inputFile={values.logoTwo} />
                            {certificateData.logoTwoPath && <button type="button" onClick={() => { downloadFile(certificateData.logoTwoAbsolutePath, formLabels.logoTwo.name); setLogoTwoSpinner(AppConstants.trueText) }} data-tooltip-id="downloadLogoTwo" className={classes.removeBtn}>{logoTwoSpinner ? <BiLoaderAlt className="spinner" /> : <AiOutlineDownload />}</button>}
                            <button type="button" onClick={() => removeFile(formLabels.logoTwo.name)} data-tooltip-id="removeLogoTwo" className={classes.removeBtn}><MdDelete /></button>
                            <Tooltip className={classes.tooltipMessage} id="removeLogoTwo"><span className={classes.tooltipMessage}>Remove file</span></Tooltip>
                        </div>
                        {errorValues.logoTwo && <div className="errorText">{errorValues.logoTwo}</div>}
                    </Row>
                    <Row className="mb-2" >
                        <div className={classes.uploadFileRow}>
                            <FileUploadInput id={formLabels.logoThree.name} label={formLabels.logoThree.label} name={formLabels.logoThree.name} accept="image/png, image/jpeg" handleFileChange={(event) => changeLogo(event, formLabels.logoThree.label)} infoMessage={AppConstants.preferredLogoHeightMessage} inputFile={values.logoThree} />
                            {certificateData.logoThreePath && <button type="button" onClick={() => { downloadFile(certificateData.logoThreeAbsolutePath, formLabels.logoThree.name); setLogoThreeSpinner(AppConstants.trueText) }} data-tooltip-id="downloadLogoThree" className={classes.removeBtn}>{logoThreeSpinner ? <BiLoaderAlt className="spinner" /> : <AiOutlineDownload />}</button>}
                            <button type="button" onClick={() => removeFile(formLabels.logoThree.name)} data-tooltip-id="removeLogoThree" className={classes.removeBtn}><MdDelete /></button>
                            <Tooltip className={classes.tooltipMessage} id="removeLogoThree"><span className={classes.tooltipMessage}>Remove file</span></Tooltip>
                        </div>
                        {errorValues.logoThree && <div className="errorText">{errorValues.logoThree}</div>}
                    </Row>
                    <Row className="mb-2" >
                        <Form.Group className="mb-2" controlId="message">
                            <Form.Label className={formClass.formLabel}>{formLabels.certificateMessage.label}</Form.Label>
                            <Form.Control className={formClass.formTextAreaInput}
                                placeholder="Certificate Message"
                                as="textarea"
                                maxLength={140}
                                name={formLabels.certificateMessage.name}
                                value={values.certificateMessage} onBlur={handleBlur}
                                onChange={handleChange}
                            />
                            <Form.Control.Feedback className={classes.errorText} type="invalid" >{errors.certificateMessage}</Form.Control.Feedback>
                        </Form.Group>
                    </Row>
                    {!(isGymnasticsSelected && awardType === AppConstants.gymnasticsAwardType.medalBased) && <Row className="mb-2" >
                        <Form.Group className="mb-2" controlId="meritRange">
                            <Form.Label className={props.rubricsConfigured ? formClass.formLabel : formClass.formLabelDisabled}>{formLabels.meritRange.label}<RiInformationFill data-tooltip-id="defaultMeritNumber" className={classes.infoButton} /> </Form.Label>
                            <Form.Control className={formClass.formInput}
                                placeholder="Number of Merit Certificates"
                                type="number"
                                name={formLabels.meritRange.name}
                                value={values.meritRange} onBlur={handleBlur}
                                onKeyDown={event => checkNumberValidation(event)}
                                disabled={!props.rubricsConfigured}
                                onChange={handleChange} isInvalid={touched.meritRange && errors.meritRange}
                            />
                            {<Tooltip className={classes.tooltipMessage} id="defaultMeritNumber"><span className={classes.tooltipMessage}>{parse(AppConstants.meritCertificateInfoMessage)}</span></Tooltip>}
                            <Form.Control.Feedback className={classes.errorText} type="invalid" >{errors.meritRange}</Form.Control.Feedback>
                        </Form.Group>
                    </Row>}
                    <Row className="mb-2" >
                        <Form.Group className="mb-2" controlId="signatoryOneName">
                            <Form.Label className={formClass.formLabel}>{formLabels.signatoryOneName.label}</Form.Label>
                            <Form.Control className={formClass.formInput}
                                type="text"
                                placeholder="Name"
                                maxLength={26}
                                name={formLabels.signatoryOneName.name}
                                value={values.signatoryOneName} onBlur={handleBlur}
                                onChange={handleChange}
                            />
                        </Form.Group>
                    </Row>
                    <Row className="mb-2" >
                        <div className={classes.uploadFileRow}>
                            <FileUploadInput id={formLabels.signatoryImageOne.name} label={formLabels.signatoryImageOne.label} name={formLabels.signatoryImageOne.name} accept="image/png, image/jpeg" handleFileChange={(event) => changeSignature(event, formLabels.signatoryImageOne.label)} inputFile={values.signatoryImageOne} />
                            {certificateData.signatoryImageOnePath && <button type="button" onClick={() => { downloadFile(certificateData.signatoryImageOneAbsolutePath, formLabels.signatoryImageOne.name); setSignatureOneSpinner(AppConstants.trueText) }} data-tooltip-id="downloadSignatoryOne" className={classes.removeBtn}>{signatureOneSpinner ? <BiLoaderAlt className="spinner" /> : <AiOutlineDownload />}</button>}
                            <button type="button" onClick={() => removeFile(formLabels.signatoryImageOne.name)} data-tooltip-id="removeSignatoryOne" className={classes.removeBtn}><MdDelete /></button>
                            <Tooltip className={classes.tooltipMessage} id="removeSignatoryOne"><span className={classes.tooltipMessage}>Remove file</span></Tooltip>
                        </div>
                        {errorValues.signatoryImageOne && <div className="errorText">{errorValues.signatoryImageOne}</div>}
                    </Row>
                    <Row className="mb-2" >
                        <Form.Group className="mb-2" controlId="signatoryOneDesignation">
                            <Form.Label className={formClass.formLabel}>{formLabels.signatoryOneDesignation.label}</Form.Label>
                            <Form.Control className={formClass.formInput}
                                type="text"
                                placeholder="Designation"
                                maxLength={30}
                                name={formLabels.signatoryOneDesignation.name}
                                value={values.signatoryOneDesignation} onBlur={handleBlur}
                                onChange={handleChange}
                            />
                        </Form.Group>
                    </Row>
                    <Row className="mb-2" >
                        <Form.Group className="mb-2" controlId="signatoryOneOrganization">
                            <Form.Label className={formClass.formLabel}>{formLabels.signatoryOneOrganization.label}</Form.Label>
                            <Form.Control className={formClass.formInput}
                                type="text"
                                maxLength={35}
                                placeholder="Organization"
                                name={formLabels.signatoryOneOrganization.name}
                                value={values.signatoryOneOrganization} onBlur={handleBlur}
                                onChange={handleChange}
                            />
                        </Form.Group>
                    </Row>

                    <Row className="mb-2" >
                        <Form.Group className="mb-2" controlId="signatoryTwoName">
                            <Form.Label className={formClass.formLabel}>{formLabels.signatoryTwoName.label}</Form.Label>
                            <Form.Control className={formClass.formInput}
                                type="text"
                                placeholder="Name"
                                maxLength={26}
                                name={formLabels.signatoryTwoName.name}
                                value={values.signatoryTwoName} onBlur={handleBlur}
                                onChange={handleChange}
                            />
                        </Form.Group>
                    </Row>
                    <Row className="mb-2" >
                        <div className={classes.uploadFileRow}>
                            <FileUploadInput id={formLabels.signatoryImageTwo.name} label={formLabels.signatoryImageTwo.label} name={formLabels.signatoryImageTwo.name} accept="image/png, image/jpeg" handleFileChange={(event) => changeSignature(event, formLabels.signatoryImageTwo.label)} inputFile={values.signatoryImageTwo} />
                            {certificateData.signatoryImageTwoPath && <button type="button" onClick={() => { downloadFile(certificateData.signatoryImageTwoAbsolutePath, formLabels.signatoryImageTwo.name); setSignatureTwoSpinner(AppConstants.trueText) }} data-tooltip-id="downloadSignatoryTwo" className={classes.removeBtn}>{signatureTwoSpinner ? <BiLoaderAlt className="spinner" /> : <AiOutlineDownload />}</button>}
                            <button type="button" onClick={() => removeFile(formLabels.signatoryImageTwo.name)} data-tooltip-id="removeSignatoryTwo" className={classes.removeBtn}><MdDelete /></button>
                            <Tooltip className={classes.tooltipMessage} id="removeSignatoryTwo"><span className={classes.tooltipMessage}>Remove file</span></Tooltip>
                        </div>
                        {errorValues.signatoryImageTwo && <div className="errorText">{errorValues.signatoryImageTwo}</div>}
                    </Row>
                    <Row className="mb-2" >
                        <Form.Group className="mb-2" controlId="signatoryTwoDesignation">
                            <Form.Label className={formClass.formLabel}>{formLabels.signatoryTwoDesignation.label}</Form.Label>
                            <Form.Control className={formClass.formInput}
                                type="text"
                                maxLength={30}
                                placeholder="Designation"
                                name={formLabels.signatoryTwoDesignation.name}
                                value={values.signatoryTwoDesignation} onBlur={handleBlur}
                                onChange={handleChange}
                            />
                        </Form.Group>
                    </Row>

                    <Row className="mb-2" >
                        <Form.Group className="mb-2" controlId="signatoryTwoOrganization">
                            <Form.Label className={formClass.formLabel}>{formLabels.signatoryTwoOrganization.label}</Form.Label>
                            <Form.Control className={formClass.formInput}
                                type="text"
                                maxLength={35}
                                placeholder="Organization"
                                name={formLabels.signatoryTwoOrganization.name}
                                value={values.signatoryTwoOrganization} onBlur={handleBlur}
                                onChange={handleChange}
                            />
                        </Form.Group>
                    </Row>

                    <Row className="mb-2" >
                        <Form.Group className="mb-2" controlId="signatoryThreeName">
                            <Form.Label className={formClass.formLabel}>{formLabels.signatoryThreeName.label}</Form.Label>
                            <Form.Control className={formClass.formInput}
                                type="text"
                                maxLength={26}
                                placeholder="Name"
                                name={formLabels.signatoryThreeName.name}
                                value={values.signatoryThreeName} onBlur={handleBlur}
                                onChange={handleChange}
                            />
                        </Form.Group>
                    </Row>
                    <Row className="mb-2" >
                        <div className={classes.uploadFileRow}>
                            <FileUploadInput id={formLabels.signatoryImageThree.name} label={formLabels.signatoryImageThree.label} name={formLabels.signatoryImageThree.name} accept="image/png, image/jpeg" handleFileChange={(event) => changeSignature(event, formLabels.signatoryImageThree.label)} inputFile={values.signatoryImageThree} />
                            {certificateData.signatoryImageThreePath && <button type="button" onClick={() => { downloadFile(certificateData.signatoryImageThreeAbsolutePath, formLabels.signatoryImageThree.name); setSignatureThreeSpinner(AppConstants.trueText) }} data-tooltip-id="downloadSignatoryThree" className={classes.removeBtn}>{signatureThreeSpinner ? <BiLoaderAlt className="spinner" /> : <AiOutlineDownload />}</button>}
                            <button type="button" onClick={() => removeFile(formLabels.signatoryImageThree.name)} data-tooltip-id="removeSignatoryThree" className={classes.removeBtn}><MdDelete /></button>
                            <Tooltip className={classes.tooltipMessage} id="removeSignatoryThree"><span className={classes.tooltipMessage}>Remove file</span></Tooltip>
                        </div>
                        {errorValues.signatoryImageThree && <div className="errorText">{errorValues.signatoryImageThree}</div>}
                    </Row>
                    <Row className="mb-2" >
                        <Form.Group className="mb-2" controlId="signatoryThreeDesignation">
                            <Form.Label className={formClass.formLabel}>{formLabels.signatoryThreeDesignation.label}</Form.Label>
                            <Form.Control className={formClass.formInput}
                                type="text"
                                maxLength={30}
                                placeholder="Designation"
                                name={formLabels.signatoryThreeDesignation.name}
                                value={values.signatoryThreeDesignation} onBlur={handleBlur}
                                onChange={handleChange}
                            />
                        </Form.Group>
                    </Row>
                    <Row className="mb-2" >
                        <Form.Group className="mb-2" controlId="signatoryThreeOrganization">
                            <Form.Label className={formClass.formLabel}>{formLabels.signatoryThreeOrganization.label}</Form.Label>
                            <Form.Control className={formClass.formInput}
                                type="text"
                                placeholder="Organization"
                                maxLength={35}
                                name={formLabels.signatoryThreeOrganization.name}
                                value={values.signatoryThreeOrganization} onBlur={handleBlur}
                                onChange={handleChange}
                            />
                        </Form.Group>
                    </Row>
                </Form>
                <div className={classes.previewContainer}>
                    <CertificateTemplate values={values} previewLogo={previewLogo} previewSignature={previewSignature} template={props.template} />
                </div>
            </div>
            <div className={classes.nextBtn}>
                <Button disabled={formSubmitting} onClick={handleButtonClick} type="submit">{AppConstants.nextText}</Button>
            </div>
        </div>
    );
};