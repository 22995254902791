import { useMemo, useState } from "react";
import { FaMedal } from "react-icons/fa6";
import { Table } from "react-bootstrap";
import { AppConstants } from "../../constants/AppConstants";
import classes from "./ScoreboardTable.module.css";
import { AiOutlineExclamationCircle } from "react-icons/ai";
import { ErrorMessages } from "../../constants/ErrorMessages";
import { DateTime } from "luxon";
import { DateTimeUtil } from "../../utils/DateTimeUtil";

export const ScoreboardTable = ({ scoreboardData }) => {
    const gymnasticsMedals = AppConstants.gymnasticsMedals;
    const [configuredCriteria, setConfiguredCriteria] = useState([]);
    const timeZone = DateTimeUtil.getPreferredTimeZoneForUser();

    const learnersByCategory = useMemo(() => {
        const male = [];
        const female = [];
        const other = [];
        const allCriteria = new Set();

        scoreboardData.participantScoreDetails.forEach(participant => {
            if (participant.learnerGender === AppConstants.gender[0]) {
                male.push(participant);
            } else if (participant.learnerGender === AppConstants.gender[1]) {
                female.push(participant);
            } else if (participant.learnerGender === AppConstants.gender[2]) {
                other.push(participant);
            }

            participant.gymnasticsConfiguredCriteria?.forEach(criteria => allCriteria.add(criteria.criteriaName));
        });

        setConfiguredCriteria([...allCriteria]);
        return { male, female, other };
    }, [scoreboardData]);

    const getMedalForLearner = (medal) => {
        if (!medal) {
            return AppConstants.hyphen;
        }
        return (
            <>
                {medal} <FaMedal className={
                    medal === gymnasticsMedals.gold.medalName ? classes.goldMedal :
                        medal === gymnasticsMedals.silver.medalName ? classes.silverMedal :
                            classes.bronzeMedal
                } />
            </>
        );
    };

    const getCriteriaScore = (criteriaName, studentRecord) => {
        const criteria = studentRecord.gymnasticsConfiguredCriteria.find(item => item.criteriaName === criteriaName);

        if (!criteria) return AppConstants.notAvailableText;
        const isGenderMatch = criteria.criteriaGender === studentRecord.learnerGender || criteria.criteriaGender === AppConstants.bothText;

        return !isGenderMatch ? AppConstants.notAvailableText : criteria.criteriaFinalScore === null ? AppConstants.hyphen : criteria.criteriaFinalScore;
    };

    const checkIfCompetitionIsOngoingOrPast = () => {
        const competitionStartDateTime = DateTime.fromISO(scoreboardData.utcStartDate?.replace(" ", "T"), { zone: 'utc' }).setZone(timeZone);
        const currentDateTime = DateTime.now();
        if (currentDateTime > competitionStartDateTime) {
            return true;
        } else {
            return false
        }
    }

    const getTableContent = (studentDetails) => (
        <>
            {scoreboardData.awardType === AppConstants.gymnasticsAwardType.rankBased && <p className={classes.tableGenderHeading}><span>{studentDetails[0].learnerGender} Participants</span></p>}
            <Table bordered className={`${classes.table} mb-4`}>
                <thead className={classes.tableHead}>
                    <tr className={classes.tableHeadings}>
                        <th className={classes.tableData}>S.No</th>
                        <th className={classes.tableData}>Id</th>
                        <th className={classes.tableData}>Name</th>
                        <th className={classes.tableData}>Institute Name</th>
                        <th className={classes.tableData}>Institute Type</th>
                        <th className={classes.tableData}>Participation Mode</th>
                        {configuredCriteria.map((criteria, index) => (
                            <th key={index} className={classes.tableData}>{criteria}</th>
                        ))}
                        <th className={classes.tableData}>Final Score</th>
                        {scoreboardData.showPercentageFlag === AppConstants.yText && <th className={classes.tableData}>Percentage</th>}
                        {scoreboardData.awardType === AppConstants.gymnasticsAwardType.medalBased && <th className={classes.tableData}>Medal</th>}
                        {scoreboardData.awardType === AppConstants.gymnasticsAwardType.rankBased && <th className={classes.tableData}>Rank</th>}
                    </tr>
                </thead>
                <tbody className={classes.tableBody}>
                    {studentDetails.map((record, index) => (
                        <tr key={index} className={classes.tableBodyRow}>
                            <td>{index + 1}</td>
                            <td>{record.learnerQrenciaId}</td>
                            <td className={classes.learnerNameData}>
                                {record.learnerProfileImage ?
                                    <img className={classes.profileImage} src={record.learnerProfileImage} alt="profile" /> :
                                    <div className={classes.learnerInitials}>{record.learnerInitials}</div>
                                }
                                {record.learnerName}
                            </td>
                            <td>{record.instituteName || AppConstants.notAvailableText}</td>
                            <td>{record.instituteType || AppConstants.notAvailableText}</td>
                            <td>{record.participationMode || AppConstants.notAvailableText}</td>
                            {configuredCriteria.map((criteria, i) => (
                                <td key={i} className={classes.tableData}>
                                    {getCriteriaScore(criteria, record)}
                                </td>
                            ))}
                            <td className={classes.tableData}>{record.learnerTotalScore && record.markedStatus === AppConstants.doneText ? record.learnerTotalScore : "-"}</td>
                            {scoreboardData.showPercentageFlag === AppConstants.yText && <td className={classes.tableData}>{record.learnerScorePercentage && record.markedStatus === AppConstants.doneText ? `${record.learnerScorePercentage} %` : "-"}</td>}
                            {scoreboardData.awardType === AppConstants.gymnasticsAwardType.medalBased && <td className={classes.tableData}>{record.markedStatus === AppConstants.doneText ? getMedalForLearner(record.learnerMedal) : "-"}</td>}
                            {scoreboardData.awardType === AppConstants.gymnasticsAwardType.rankBased && <td className={classes.tableData}>{record.markedStatus === AppConstants.doneText ? record.participantRank : "-"}</td>}
                        </tr>
                    ))}
                </tbody>
            </Table>
        </>
    );

    return (
        <div className={classes.scoreboardTable}>
            {scoreboardData.judgingEnabledFlag === AppConstants.yText && scoreboardData.awardType === AppConstants.gymnasticsAwardType.rankBased && checkIfCompetitionIsOngoingOrPast()
                && <div className={classes.judgingEnabledWarningMessage}><p><AiOutlineExclamationCircle /> {ErrorMessages.resultErrorJudgingInProgress}</p></div>}
            {scoreboardData.awardType === AppConstants.gymnasticsAwardType.rankBased ? (
                <>
                    {learnersByCategory.male.length > 0 && getTableContent(learnersByCategory.male)}
                    {learnersByCategory.female.length > 0 && getTableContent(learnersByCategory.female)}
                    {learnersByCategory.other.length > 0 && getTableContent(learnersByCategory.other)}
                </>
            ) : (
                getTableContent(scoreboardData.participantScoreDetails)
            )}
        </div>
    );
};
