import { useState, useEffect } from "react";
import { TbShare3 } from 'react-icons/tb';
import { BiLoaderAlt } from "react-icons/bi";
import { BsLinkedin, BsFacebook, BsFillEnvelopeAtFill, BsFillCheckCircleFill } from 'react-icons/bs';
import { AiOutlineCopy } from 'react-icons/ai';
import parse from 'html-react-parser';
import { Button, Table, Accordion } from "react-bootstrap";
import Moment from 'react-moment';
import 'moment-timezone';
import { FaArrowLeft } from "react-icons/fa";
import { AppConstants } from "../../constants/AppConstants";
import { ErrorMessages } from "../../constants/ErrorMessages";
import { DateTimeUtil } from "../../utils/DateTimeUtil";
import { MathUtil } from "../../utils/MathUtil";
import { PaymentsUtil } from "../../utils/PaymentsUtil";
import { FileOperationsUtil } from "../../utils/FileOperationsUtil";
import { AuthorizedDataService } from "../../services/AuthorizedDataService";
import { ErrorSuccessAlertMessage } from "../errorSuccessMessage/ErrorSuccessAlertMessage";
import classes from "../expertManageCourses/viewCourseDetailsAccordion/ViewCourseDetailsAccordion.module.css";
import "../expertManageCompetitionComponent/viewCompetitionDetailsAccordion.css";
import { RiTwitterXLine } from "react-icons/ri";
import { GrDocumentZip } from "react-icons/gr";
import { StudentQueryCard } from "../cards/studentQueryCards/StudentQueryCard";

export const ViewWorkshopDetailsAccordion = (props) => {
    const timeZone = DateTimeUtil.getPreferredTimeZoneForUser();
    const [selectedWorkshop, setSelectedWorkshop] = useState({});
    const [certificateTemplateSpinner, setCertificateTemplateSpinner] = useState(AppConstants.falseText);
    const [showLinkCopied, setShowLinkCopied] = useState(AppConstants.falseText);
    const [showSocialMediaPannel, setShowSocialMediaPannel] = useState(AppConstants.falseText);
    const [linkToShare, setLinkToShare] = useState(AppConstants.falseText);
    const [showLoader, setShowLoader] = useState(AppConstants.trueText);
    const [showDetailsError, setShowDetailsError] = useState(AppConstants.falseText);
    const [basicDetailsError, setBasicDetailsError] = useState(AppConstants.emptyString);
    const [certificateSpinner, setCertificateSpinner] = useState([]);
    const [certificateDownloadMessage, setCertificateDownloadMessage] = useState(AppConstants.falseText);
    const [downloadZipSpinner, setDownloadZipSpinner] = useState(AppConstants.falseText);
    const [downloadZipError, setDownloadZipError] = useState(AppConstants.emptyString);
    const [respondedQueries, setRespondedQueries] = useState([]);
    const [openQueries, setOpenQueries] = useState([]);
    const [fetchQueryFlag, setFetchQueryFlag] = useState(0);

    const [participantsDetailsError, setParticipantsDetailsError] = useState(AppConstants.falseText);
    const [participantsDetailsErrorMessage, setParticipantsDetailsErrorMessage] = useState(AppConstants.emptyString);
    const [downloadParticipantsSpinner, setDownloadParticipantsSpinner] = useState(AppConstants.falseText);


    useEffect(() => {
        const fetchWorkshopDetails = async () => {
            const url = `${AppConstants.getSpecificWorkshopDetailsAPI}${props.selectedWorkshop}`;

            const response = await AuthorizedDataService.getRequest(
                url,
                AppConstants.emptyString,
                AppConstants.emptyString
            );
            if (response !== undefined) {
                setSelectedWorkshop(response);
                setShowLoader(AppConstants.falseText);
                setShowDetailsError(AppConstants.falseText);
                setCertificateSpinner(new Array(response.participantsDetails?.length).fill(false));
            } else {
                setSelectedWorkshop([]);
                setShowLoader(AppConstants.falseText);
                setShowDetailsError(AppConstants.trueText);
            }
        };

        fetchWorkshopDetails();
    }, []);

    useEffect(() => {
        const fetchStudentQueries = async () => {
            const url = `${AppConstants.getStudentQueriesAPI}${selectedWorkshop.id}&learningActivityType=${AppConstants.learningActivitiesTypes.workshop}`;

            const response = await AuthorizedDataService.getRequest(
                url,
                AppConstants.emptyString,
                AppConstants.emptyString
            );
            if (response !== undefined) {
                setRespondedQueries(response.filter(query => query.respondedOn !== null));
                setOpenQueries(response.filter(query => query.respondedOn === null));
            } else {
                setRespondedQueries([]);
                setOpenQueries([]);
            }
        };
        if (selectedWorkshop.id) {
            fetchStudentQueries();
        }
    }, [selectedWorkshop, fetchQueryFlag]);


    const updateFetchDataFlag = () => {
        setFetchQueryFlag(fetchQueryFlag + 1);
    };

    function shareOnSocialMedia(shareData) {
        setShowSocialMediaPannel(!showSocialMediaPannel);
        setLinkToShare(`${process.env.REACT_APP_FRONT_END_URL}${AppConstants.workshopsText}${AppConstants.forwardSlash}${shareData.skillCategorySlug}${AppConstants.forwardSlash}${shareData.skillSlug}${AppConstants.forwardSlash}${shareData.workshopSlug}`);
    }
    //function to copy to clipboard
    function copyToClipBoard(event) {
        event.preventDefault();
        navigator.clipboard.writeText(linkToShare);
        setShowLinkCopied(AppConstants.trueText);
        setTimeout(() => {
            setShowLinkCopied(AppConstants.falseText);
        }, 3000);
    }
    //function Send on Mail
    function sendOnMail() {
        window.open(`${AppConstants.mailToURI}` + `${linkToShare}`, AppConstants.openInNewTab);
    }
    //Function to Share on facebook
    function shareOnFacebook() {
        window.open(`${AppConstants.facebookShareURI}` + `${linkToShare}`, AppConstants.openInNewTab);
    }
    //Function to share on Linkedin
    function shareOnLinkedIn() {
        window.open(`${AppConstants.linkedInShareURI}` + `${encodeURIComponent(linkToShare)}`, AppConstants.openInNewTab);
    }
    //function to share on twitter
    function shareOnTwitter() {
        window.open(`${AppConstants.twitterShareURI}` + `${linkToShare}`, AppConstants.openInNewTab);
    }

    const downloadCertificateTemplate = async (workshop) => {
        setCertificateTemplateSpinner(AppConstants.trueText);
        let url = `${AppConstants.downloadWorkshopCertificateAPI}${workshop.uniqueId}`;
        const name = `${workshop.workshopTitle}${AppConstants.hyphen}${AppConstants.certificateFileNameWithPDFExtension}`;
        const fileName = name.replace(/\s+/g, AppConstants.hyphen);
        const response = await FileOperationsUtil.downloadFile(
            url,
            fileName,
            AppConstants.emptyString,
            AppConstants.emptyString
        );
        if (response !== undefined) {
            if (response.ok) {
                setCertificateTemplateSpinner(AppConstants.falseText);
            } else {
                setCertificateTemplateSpinner(AppConstants.falseText);
                setBasicDetailsError(`${ErrorMessages.fileDownloadError}${AppConstants.certificateTest}${ErrorMessages.fileDownloadErrorPartTwo}`);
                let timer = setTimeout(() => {
                    setBasicDetailsError(AppConstants.emptyString);
                }, AppConstants.messageDisappearTime);
                return () => { clearTimeout(timer) };
            }
        } else {
            setCertificateTemplateSpinner(AppConstants.falseText);
            setBasicDetailsError(`${ErrorMessages.fileDownloadError}${AppConstants.certificateTest}${ErrorMessages.fileDownloadErrorPartTwo}`);
            let timer = setTimeout(() => {
                setBasicDetailsError(AppConstants.emptyString);
            }, AppConstants.messageDisappearTime);
            return () => { clearTimeout(timer) };
        }

    };

    //convert duration in hours and min
    function displayTimeInHoursAndMinutes(decimalNumber) {
        const hours = Math.floor(decimalNumber);
        const minutes = Math.round((decimalNumber - hours) * 60);
        if (minutes === 0) {
            return `${hours}h`;
        } else {
            return `${hours}h ${minutes}min`;
        }
    };

    const downloadStudentCertificate = async (participantQrenciaId, index) => {
        try {
            setCertificateSpinner(certificateSpinner => certificateSpinner.map((state, idx) => idx === index ? true : state));
            const url = `${AppConstants.downloadWorkshopCertificateAPI}${selectedWorkshop.uniqueId}${AppConstants.studentQrenciaIdParam}${participantQrenciaId}`;
            const name = `${selectedWorkshop.workshopTitle}${AppConstants.hyphen}${AppConstants.certificateFileNameWithPDFExtension}`;
            const fileName = name.replace(/\s+/g, AppConstants.hyphen);
            const response = await FileOperationsUtil.downloadFile(
                url,
                fileName,
                AppConstants.emptyString,
                AppConstants.emptyString
            );

            if (response !== undefined) {
                if (response.ok && response.status === 200) {
                    setCertificateDownloadMessage(AppConstants.emptyString);
                    setCertificateSpinner(certificateSpinner => certificateSpinner.map((state, idx) => idx === index ? false : state));
                } else {
                    setCertificateSpinner(certificateSpinner => certificateSpinner.map((state, idx) => idx === index ? false : state));
                    setCertificateDownloadMessage(`${ErrorMessages.fileDownloadError}${AppConstants.certificateTest}${ErrorMessages.fileDownloadErrorPartTwo}`);
                    let timer = setTimeout(() => {
                        setCertificateDownloadMessage(AppConstants.emptyString);
                    }, AppConstants.messageDisappearTime);
                    return () => { clearTimeout(timer) };
                }
            } else {
                setCertificateSpinner(certificateSpinner => certificateSpinner.map((state, idx) => idx === index ? false : state));
                setCertificateDownloadMessage(`${ErrorMessages.fileDownloadError}${AppConstants.certificateTest}${ErrorMessages.fileDownloadErrorPartTwo}`);
                let timer = setTimeout(() => {
                    setCertificateDownloadMessage(AppConstants.emptyString);
                }, AppConstants.messageDisappearTime);
                return () => { clearTimeout(timer) };
            }
        } catch (error) {
            setCertificateSpinner(certificateSpinner => certificateSpinner.map((state, idx) => idx === index ? true : state));
            setCertificateDownloadMessage(`${ErrorMessages.fileDownloadError}${AppConstants.certificateTest}${ErrorMessages.fileDownloadErrorPartTwo}`);
            let timer = setTimeout(() => {
                setCertificateDownloadMessage(AppConstants.emptyString);
            }, AppConstants.messageDisappearTime);
            return () => { clearTimeout(timer) };
        }
    };

    const downloadCertificateZip = async (workshop) => {
        setDownloadZipSpinner(true);
        let url = `${AppConstants.generateCertificateZipForWorkshopAPI}${workshop.uniqueId}`;
        const name = `${workshop.workshopTitle}${AppConstants.hyphen}${AppConstants.certificatesZipFileName}`;
        const fileName = name.replace(/\s+/g, AppConstants.hyphen);
        const response = await FileOperationsUtil.downloadFile(
            url,
            fileName,
            AppConstants.emptyString,
            AppConstants.emptyString
        );
        if (response !== undefined) {
            if (response.ok) {
                setDownloadZipSpinner(AppConstants.falseText);
            } else {
                setDownloadZipSpinner(AppConstants.falseText);
                setDownloadZipError(`${ErrorMessages.fileDownloadError}ZIP file.${ErrorMessages.fileDownloadErrorPartTwo}`);
                let timer = setTimeout(() => {
                    setDownloadZipError(AppConstants.emptyString);
                }, AppConstants.messageDisappearTime);
                return () => { clearTimeout(timer) };
            }
        } else {
            setDownloadZipSpinner(AppConstants.falseText);
            setDownloadZipError(`${ErrorMessages.fileDownloadError}ZIP file.${ErrorMessages.fileDownloadErrorPartTwo}`);
            let timer = setTimeout(() => {
                setDownloadZipError(AppConstants.emptyString);
            }, AppConstants.messageDisappearTime);
            return () => { clearTimeout(timer) };
        }
    }


    //download registered candidates
    async function downloadWorkshopEnrolledStudent(workshop) {
        setDownloadParticipantsSpinner(AppConstants.trueText);
        const url = `${AppConstants.downloadWorkshopParticipants}${workshop.uniqueId}`;
        const name = `${workshop.workshopTitle}${AppConstants.hyphen}${AppConstants.registeredParticipantsFileText}`
        const fileName = name.replace(/\s+/g, AppConstants.hyphen);
        const response = await FileOperationsUtil.downloadFile(
            url,
            fileName,
            AppConstants.emptyString,
            AppConstants.emptyString,
            { preferredTimezone: DateTimeUtil.getPreferredTimeZoneForUser() }
        );
        if (response !== undefined) {
            if (response.ok) {
                setDownloadParticipantsSpinner(AppConstants.falseText);
            } else {
                setDownloadParticipantsSpinner(AppConstants.falseText);
                setParticipantsDetailsError(AppConstants.trueText);
                setParticipantsDetailsErrorMessage(`${ErrorMessages.fileDownloadError}${AppConstants.participantsListText}${ErrorMessages.fileDownloadErrorPartTwo}`);
                let timer = setTimeout(() => {
                    setParticipantsDetailsError(AppConstants.falseText);
                    setParticipantsDetailsErrorMessage(AppConstants.emptyString);
                }, AppConstants.messageDisappearTime);
                return () => { clearTimeout(timer) };
            }
        } else {
            setDownloadParticipantsSpinner(AppConstants.falseText);
            setParticipantsDetailsError(AppConstants.trueText);
            setParticipantsDetailsErrorMessage(`${ErrorMessages.fileDownloadError}${AppConstants.participantsListText}${ErrorMessages.fileDownloadErrorPartTwo}`);
            let timer = setTimeout(() => {
                setParticipantsDetailsError(AppConstants.falseText);
                setParticipantsDetailsErrorMessage(AppConstants.emptyString);
            }, AppConstants.messageDisappearTime);
            return () => { clearTimeout(timer) };
        }
    }

    return (
        <div className="expertCompDetailsAccordion">
            {showLoader ? <div className={classes.pageLoader}><BiLoaderAlt className="spinner" /></div> : showDetailsError ? <div>
                <div><FaArrowLeft onClick={() => props.onClickHide()} className={classes.goBackButton} />
                </div>
                <ErrorSuccessAlertMessage message={ErrorMessages.fetchWorkshopDetailsError} varient={AppConstants.alertVarient[1]} /></div>
                : <div>
                    <div><FaArrowLeft onClick={() => props.onClickHide()} className={classes.goBackButton} /></div>
                    <Accordion defaultActiveKey={['0']} alwaysOpen>
                        <Accordion.Item eventKey="0">
                            <Accordion.Header>Workshop Details</Accordion.Header>
                            <Accordion.Body>
                                <div className="row expertAccordionContentSection">
                                    <Table striped bordered>
                                        <tbody>
                                            <tr>
                                                <td className={classes.tableLabelColumn}>Title</td>
                                                <td className={classes.tableDescriptionColumn}>{selectedWorkshop.workshopTitle !== AppConstants.nullText ? selectedWorkshop.workshopTitle : AppConstants.notAvailableText}
                                                    <TbShare3 className={classes.shareIcon} onClick={() => { shareOnSocialMedia(selectedWorkshop) }} />
                                                    {showSocialMediaPannel && <div className={classes.shareIconPannelContainerParent}>
                                                        <div className={classes.shareIconPannelContainer}>
                                                            <BsLinkedin className={classes.shareOnLinkedIn} onClick={shareOnLinkedIn} />
                                                            <BsFacebook className={classes.shareOnFacebook} onClick={shareOnFacebook} />
                                                            <RiTwitterXLine className={classes.shareOnTwitter} onClick={shareOnTwitter} />
                                                            <BsFillEnvelopeAtFill className={classes.shareOnMailButton} onClick={sendOnMail} />
                                                            {!showLinkCopied && <AiOutlineCopy className={classes.copyToClipboard} onClick={copyToClipBoard} />}
                                                            {showLinkCopied && <BsFillCheckCircleFill className={classes.showLinkCopiedIcon} />}
                                                        </div>
                                                    </div>}
                                                </td>
                                            </tr>
                                            <tr>
                                                <td className={classes.tableLabelColumn}>Workshop Id</td>
                                                <td className={classes.tableDescriptionColumn}>{selectedWorkshop.workshopId !== AppConstants.nullText ? selectedWorkshop.workshopId : AppConstants.notAvailableText}</td>
                                            </tr>
                                            <tr>
                                                <td className={classes.tableLabelColumn}>Description</td>
                                                <td className={classes.tableDescriptionColumn}><div className={`${classes.quillDescription} quillListIndent ql-editor`}>{selectedWorkshop.workshopDescription !== AppConstants.nullText ? parse(selectedWorkshop.workshopDescription) : AppConstants.notAvailableText}</div></td>
                                            </tr>
                                            {selectedWorkshop.descriptionDocumentRelativePath !== AppConstants.nullText && <tr>
                                                <td className={classes.tableLabelColumn}>Workshop Brochure</td>
                                                {selectedWorkshop.descriptionDocumentRelativePath !== AppConstants.nullText && <td className={classes.tableDescriptionColumn}><Button target={AppConstants.openInNewTab} download="problemStatement" className={classes.downloadBtn} href={selectedWorkshop.descriptionDocumentAbsolutePath}>Download</Button></td>}
                                                {selectedWorkshop.descriptionDocumentRelativePath === AppConstants.nullText && <td className={classes.tableDescriptionColumn}>{AppConstants.notAvailableText}</td>}
                                            </tr>}
                                            <tr>
                                                <td className={classes.tableLabelColumn}>Mode of delivery</td>
                                                <td className={classes.tableDescriptionColumn}>{selectedWorkshop.workshopModeOfDelivery !== AppConstants.nullText ? selectedWorkshop.workshopModeOfDelivery : AppConstants.notAvailableText}</td>
                                            </tr>
                                            {selectedWorkshop.workshopModeOfDelivery.toUpperCase() === AppConstants.modeOfDelivery[0].toUpperCase() && selectedWorkshop.workshopLink !== null && <tr>
                                                <td className={classes.tableLabelColumn}>Workshop Link</td>
                                                <td className={classes.tableDescriptionColumn}><a target={AppConstants.openInNewTab} href={selectedWorkshop.workshopLink}>Click Here</a></td>
                                            </tr>}
                                            {selectedWorkshop.workshopModeOfDelivery.toUpperCase() === AppConstants.modeOfDelivery[1].toUpperCase() && selectedWorkshop.address !== null && <tr>
                                                <td className={classes.tableLabelColumn}>Address</td>
                                                <td className={classes.tableDescriptionColumn}><a href={selectedWorkshop.geoLocation} target={AppConstants.openInNewTab}>{selectedWorkshop.address}</a></td>
                                            </tr>}
                                            <tr>
                                                <td className={classes.tableLabelColumn}>Start Time</td>
                                                <td className={classes.tableDescriptionColumn}><Moment format={AppConstants.twelveHoursTimeFormat} tz={timeZone}>{selectedWorkshop.utcStartDate.replace(' ', 'T') + 'Z'}</Moment> | <Moment format={AppConstants.competitionDatesNewDateFormat} tz={timeZone}>{selectedWorkshop.utcStartDate.replace(' ', 'T') + 'Z'}</Moment> {timeZone === AppConstants.utcTimezone && `(${selectedWorkshop.perferredTimeZone})`}</td>
                                            </tr>
                                            <tr>
                                                <td className={classes.tableLabelColumn}>End Time</td>
                                                <td className={classes.tableDescriptionColumn}><Moment format={AppConstants.twelveHoursTimeFormat} tz={timeZone}>{selectedWorkshop.utcEndDate.replace(' ', 'T') + 'Z'}</Moment> | <Moment format={AppConstants.competitionDatesNewDateFormat} tz={timeZone}>{selectedWorkshop.utcEndDate.replace(' ', 'T') + 'Z'}</Moment> {timeZone === AppConstants.utcTimezone && `(${selectedWorkshop.perferredTimeZone})`}</td>
                                            </tr>
                                            {selectedWorkshop.workshopUtcRegistrationClosesOn && <tr>
                                                <td className={classes.tableLabelColumn}>Registration Deadline</td>
                                                <td className={classes.tableDescriptionColumn}><Moment format={AppConstants.twelveHoursTimeFormat} tz={timeZone}>{selectedWorkshop.workshopUtcRegistrationClosesOn.replace(' ', 'T') + 'Z'}</Moment> | <Moment format={AppConstants.competitionDatesNewDateFormat} tz={timeZone}>{selectedWorkshop.workshopUtcRegistrationClosesOn.replace(' ', 'T') + 'Z'}</Moment> {timeZone === AppConstants.utcTimezone && `(${selectedWorkshop.preferredTimezone})`}</td>
                                            </tr>}
                                            <tr>
                                                <td className={classes.tableLabelColumn}>Enrolled Students</td>
                                                <td className={classes.tableDescriptionColumn}>{selectedWorkshop.participantDetails !== AppConstants.nullText ? MathUtil.formatNumberToLocaleString(selectedWorkshop.participantDetails.length) : AppConstants.notAvailableText}</td>
                                            </tr>
                                            <tr>
                                                <td className={classes.tableLabelColumn}>Cost</td>
                                                <td className={classes.tableDescriptionColumn}>{(selectedWorkshop.workshopCost !== null && selectedWorkshop.workshopCost !== 0) ? PaymentsUtil.calculateCost({ cost: selectedWorkshop.workshopCost, discount: 0 }) : AppConstants.freeText}</td>
                                            </tr>
                                            <tr>
                                                <td className={classes.tableLabelColumn}>Offline Payment Enabled</td>
                                                <td className={classes.tableDescriptionColumn}>{selectedWorkshop.payOfflineFlag === AppConstants.yText ? "Yes" : "No"}</td>
                                            </tr>
                                            <tr>
                                                <td className={classes.tableLabelColumn}>Duration (in hours)</td>
                                                <td className={classes.tableDescriptionColumn}>{selectedWorkshop.workshopDuration !== AppConstants.nullText ? displayTimeInHoursAndMinutes(selectedWorkshop.workshopDuration) : AppConstants.notAvailableText}</td>
                                            </tr>
                                            <tr>
                                                <td className={classes.tableLabelColumn}>Created On</td>
                                                <td className={classes.tableDescriptionColumn}><Moment format={AppConstants.competitionDatesNewDateFormat} tz={timeZone}>{selectedWorkshop.createdOn.replace(' ', 'T') + 'Z'}</Moment> {timeZone === AppConstants.utcTimezone && `(${selectedWorkshop.perferredTimeZone})`}</td>
                                            </tr>
                                            {selectedWorkshop.templateId !== null && selectedWorkshop.templateId !== 0 && <tr>
                                                <td className={classes.tableLabelColumn}>Certificate Template</td>
                                                <td className={classes.tableDescriptionColumn}><Button className={classes.downloadBtn} onClick={() => { downloadCertificateTemplate(selectedWorkshop) }}>Download {certificateTemplateSpinner && <BiLoaderAlt className="expertSpinnerIcon" />}</Button></td>
                                            </tr>}
                                        </tbody>
                                    </Table>
                                    <p className={classes.spocHeadingText}>SPOC Details</p>
                                    <Table striped bordered>
                                        <tbody>
                                            <tr>
                                                <td className={classes.tableLabelColumn}>SPOC Name</td>
                                                <td className={classes.tableDescriptionColumn}>{selectedWorkshop.spocName !== AppConstants.nullText ? selectedWorkshop.spocName : AppConstants.notAvailableText}</td>
                                            </tr>
                                            <tr>
                                                <td className={classes.tableLabelColumn}>SPOC Email</td>
                                                <td className={classes.tableDescriptionColumn}>{selectedWorkshop.spocEmail !== AppConstants.nullText ? selectedWorkshop.spocEmail : AppConstants.notAvailableText}</td>
                                            </tr>
                                            <tr>
                                                <td className={classes.tableLabelColumn}>SPOC Mobile</td>
                                                <td className={classes.tableDescriptionColumn}>{selectedWorkshop.spocMobileNumber !== AppConstants.nullText ? selectedWorkshop.spocMobileNumber : AppConstants.notAvailableText}</td>
                                            </tr>
                                        </tbody>
                                    </Table>
                                    {basicDetailsError.length > 0 && <div className={classes.errorSuccessContainer}><ErrorSuccessAlertMessage message={basicDetailsError} varient={AppConstants.alertVarient[1]} /></div>}
                                </div>
                            </Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item eventKey="1">
                            <Accordion.Header>Participants</Accordion.Header>
                            <Accordion.Body>
                                <div className="expertAccordionContentSection">
                                    {selectedWorkshop.participantDetails?.length > 0 &&
                                        <div className={classes.participantDetailsTable}>
                                            <Table striped bordered className={classes.paymentTable}>
                                                <thead>
                                                    <tr>
                                                        <th className={classes.tableHeaderOne}>Qrencia ID</th>
                                                        <th className={classes.tableHeaderTwo}>User Name</th>
                                                        <th className={classes.tableHeaderTwo}>Email</th>
                                                        <th className={classes.tableHeaderTwo}>Phone</th>
                                                        <th className={classes.tableHeaderEnrolledDate}>Enrolled On</th>
                                                        {(selectedWorkshop.workshopCost !== null && selectedWorkshop.workshopCost !== 0) && <th className={classes.tableHeaderThree}>Last Payment Method</th>}
                                                        <th className={classes.tableHeaderThree}>Certificate</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {selectedWorkshop.participantDetails?.map((participants, index) => (
                                                        <tr key={participants.userQrenciaId}>
                                                            <td className={classes.tableDescriptionColumnOne}>{participants.userQrenciaId}</td>
                                                            <td className={classes.tableDescriptionColumnTwo}>{participants.name}</td>
                                                            <td className={classes.tableDescriptionColumnEmail}>{participants.userEmail}</td>
                                                            <td className={classes.tableDescriptionColumnTwo}>{participants.countryMobileCode}{participants.mobileNumber}</td>
                                                            <td className={classes.tableDescriptionEnrolledDate}><Moment format="hh:mm A" tz={timeZone}>{participants.enrolledOn?.replace(' ', 'T') + 'Z'}</Moment>, <Moment format="DD MMM YYYY" tz={timeZone}>{participants.enrolledOn?.replace(' ', 'T') + 'Z'}</Moment></td>
                                                            {(selectedWorkshop.workshopCost !== null && selectedWorkshop.workshopCost !== 0) && <td className={classes.tableDescriptionColumnTwo}>{participants.paymentMode}</td>}
                                                            <td className={classes.tableDescriptionColumnThree}>{(props.selectedTab === AppConstants.workshopStatus.past && selectedWorkshop.templateId !== 0 && selectedWorkshop.templateId !== null) ? <Button className={classes.downloadBtn} disabled={certificateSpinner[index]} onClick={() => downloadStudentCertificate(participants.userQrenciaId, index)}>Download {certificateSpinner[index] && <BiLoaderAlt className="spinner" />}</Button> : AppConstants.notAvailableText}</td>
                                                        </tr>
                                                    ))}
                                                </tbody>
                                            </Table>
                                        </div>}
                                    {(selectedWorkshop.participantDetails !== AppConstants.nullText && selectedWorkshop.participantDetails.length !== 0) &&
                                        <div className={`expertCompleteCompButtonCnt ${classes.downloadParticipantSheetSection}`} >
                                            <Button className={classes.downloadZip} onClick={() => { downloadWorkshopEnrolledStudent(selectedWorkshop) }}>Participants List{downloadParticipantsSpinner && <BiLoaderAlt className="expertSpinnerIcon" />}</Button>
                                            {(props.selectedTab === AppConstants.workshopStatus.past && selectedWorkshop.templateId !== 0 && selectedWorkshop.templateId !== null) &&
                                                <Button disabled={downloadZipSpinner} className={classes.downloadZip} onClick={() => downloadCertificateZip(selectedWorkshop)}>Certificates&nbsp;&nbsp;{downloadZipSpinner ? <BiLoaderAlt className="expertSpinnerIcon" /> : <GrDocumentZip />}</Button>}
                                        </div>}
                                    {certificateDownloadMessage && <ErrorSuccessAlertMessage message={certificateDownloadMessage} varient={AppConstants.alertVarient[1]} />}
                                    {downloadZipError && <ErrorSuccessAlertMessage message={downloadZipError} varient={AppConstants.alertVarient[1]} />}
                                    {participantsDetailsErrorMessage && <ErrorSuccessAlertMessage message={participantsDetailsErrorMessage} varient={AppConstants.alertVarient[1]} />}
                                    {selectedWorkshop.participantDetails?.length === 0 && <div><p className="expertNoParticipantsErroMessage">{ErrorMessages.noWorkshopParticipantsError}</p></div>}
                                </div>
                            </Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item eventKey="2">
                            <Accordion.Header>Queries</Accordion.Header>
                            <Accordion.Body>
                                {(openQueries.length > 0 || respondedQueries.length > 0) && <div>
                                    {
                                        openQueries.map((query, i) => {
                                            return <StudentQueryCard key={query.queryUniqueId} query={{ ...query, title: selectedWorkshop.workshopTitle, learningActivityType: AppConstants.learningActivitiesTypes.workshop }} updateFetchDataFlag={updateFetchDataFlag} />
                                        })
                                    }
                                    {
                                        respondedQueries.map((query, i) => {
                                            return <StudentQueryCard key={query.queryUniqueId} query={{ ...query, title: selectedWorkshop.workshopTitle, learningActivityType: AppConstants.learningActivitiesTypes.workshop }} updateFetchDataFlag={updateFetchDataFlag} />
                                        })
                                    }
                                </div>}
                                {(openQueries.length === 0 && respondedQueries.length === 0) && <div className="expertAccordionContentSection"><p className="expertNoParticipantsErroMessage">{ErrorMessages.noQuestionAskedFallbackMessage}</p></div>}
                            </Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item eventKey="3">
                            <Accordion.Header>FAQ</Accordion.Header>
                            <Accordion.Body>
                                {selectedWorkshop.workShopFaqs?.length > 0 &&
                                    selectedWorkshop.workShopFaqs?.length > 0 && selectedWorkshop.workShopFaqs?.map((faq, i) => <div className={classes.addedFaqs} key={i}>
                                        <div className={classes.displayedFaqs}>
                                            <div className={classes.questions}>Q{i + 1} : {faq.question}</div>
                                            <div className={`${classes.answers} quillListIndent ql-editor`}>{parse(faq.answer)}</div>
                                        </div>
                                    </div>)
                                }
                                {selectedWorkshop.workShopFaqs?.length === 0 && <div className="expertAccordionContentSection"><p className="expertNoParticipantsErroMessage">{ErrorMessages.expertCourseNoFaqAddedFallBackMessage}</p></div>}
                            </Accordion.Body>
                        </Accordion.Item>
                    </Accordion>
                </div>}
        </div>
    )
}
