export const ErrorMessages = {
    "invalidResponseMessage": "HTTP error! status:",
    "noResponseMessage": "Server Error",
    "invalidUserName": "Please enter valid name",
    "invalidUserFirstName": "Please enter valid first name",
    "invalidUserLastName": "Please enter valid last name",
    "invalidEmailFormat": "Please enter a valid email address",
    "invalidPassword": "Please enter a valid password",
    "invalidMobileIndian": "Please enter a valid 10 digit mobile number",
    "invalidMobileNonIndian": "Please enter a valid 6 to 16 digit mobile number",
    "requiredField": "Required",
    "invalidTitle": "Title exceeds maximum length. Please limit the title to 50 characters",
    "invalidDescription": "Please limit description to 500 characters",
    "invalidParticipationLimit": "Participants limit must be more than 0",
    "invalidAge": "Invalid age. You can only host a competition for age {{minimumSignupAge}} and above",
    "invalidMaxAge": "Max age should be more than min age",
    "loginFailed": "Email id or password is incorrect or the account doesn't exist",
    "signupDuplicateEmailError": "User already exists, Try again with a unique email address",
    "signupInvalidReferralError": "The referral code you have entered appears to be incorrect or expired",
    "signupError": "Sorry about that! It looks like something went wrong on our end, please try again in a few minutes",
    "invalidUserNameWithRegex": "Invalid name, please enter a valid name without special character",
    "invalidUserFirstNameWithRegex": "Invalid first name, please enter name without special characters",
    "invalidUserLastNameWithRegex": "Invalid last name, please enter name without special characters",
    "invalidUserGender": "Please select gender",
    "invalidQtalkCharacterLimit": "The qtalk title must be between 3 to 60 characters",
    "invalidQtalkTitle": "Please enter a valid qtalk title",
    "invalidQtalkWebFile": "Please upload a valid qtalk web background image",
    "invalidQtalkTabFile": "Please upload a valid  qtalk tab background image",
    "invalidQtalkMobileFile": "Please upload a valid qtalk mobile background image",
    "invalidQtalkThumbnailFile": "Please upload a valid qtalk thumbnail image",
    "invalidQtalkEventFile": "Please upload a valid qtalk event image",
    "invalidQtalkDate": "Please enter valid qtalk date",
    "invalidQtalkStartTime": "Please enter valid qtalk start time",
    "invalidQtalkEndTime": "Please enter valid qtalk end time",
    "invalidQtalkVideoLink": "Please enter valid qtalk video link",
    "invalidQtalkVideoId": "Please enter valid qtalk video id",
    "invalidQtalkSkillCategory": "Please select a valid qtalk skill category",
    "invalidQtalkSkill": "Please select a valid qtalk skill",
    "invalidQtalkDescription": "Please enter a valid qtalk description",
    "invalidSpeakerName": "Please enter a valid speaker name",
    "invalidSpeakerDesignation": "Please enter a valid speaker designation",
    "invalidQtalkKeyHighlight": "Please enter valid qtalk key highlights",
    "invalidSpeakerHighlight": "Please enter valid speaker highlights",
    "invalidSeoThumbnailFile": "Please enter a valid seo thumbnail image",
    "invalidSeoTitle": "Please enter a valid seo title",
    "invalidSeoDescription": "Please enter a valid seo description",
    "invalidSpeakerProfileFile": "Please enter a valid speaker profile image",
    "invalidQtalkDateTime": "Please enter future date and time",
    "qtalkInternalServerError": "The qtalk cannot be created at this moment, please try again later",
    "qtalkCreatedSuccessfully": "Qtalk created successfully",
    "fileUploadError": "There occured some issue while uploading the qtalkk files, please try creating qtalk after some time",
    "basicCompetitionDetailsError": "Basic details could not be added. Please try again.",
    "userProfileMoreThan5mbError": "Please select image less than 5mb",
    "userProfileImageTypeError": "Please select jpeg or png file",
    "completeProfileInternalServerError": "Could not submit user details, please try again in few minutes",
    "completeProfileInvalidDob": "Date of birth cannot be a future date",
    "completeProfileInvalidState": "Please select valid state",
    "completeProfileInvalidCity": "Please select valid city",
    "completeProfileInvalidGender": "Please select valid gender",
    "completeProfileRequestEmailError": "Could not send OTP, please try again in few minutes",
    "completeProfileVerifyEmailError": "Something went wrong, email address could not be verified",
    "completeProfileInvalidEmailOTP": "Please enter valid 4 digit OTP sent to your email address",
    "completeProfileRequestMobileError": "Could not send OTP, please try again in few minutes",
    "completeProfileVerifyMobileError": "Something went wrong, mobile number could not be verified",
    "completeProfileInvalidMobileOTP": "Please enter valid 4 digit OTP sent to your mobile number",
    "expertFetchCompetitionDetailsError": "Error: Unable to fetch competition details. Please try again later or contact support for assistance",
    "expertNoParticipantsError": "No participants are currently enrolled in this competition, please check back later",
    "expertCancelCompetitionError": "Error: Unable to cancel competition ",
    "expertCancelCompetitionErrorPartTwo": ", please try again later or contact support for assistance",
    "fileDownloadError": "Error: Unable to download ",
    "fileDownloadErrorPartTwo": " Please try again later or contact support for assistance",
    "expertCompleteCompetitionError": "Error: Could not close competition, please try again later or contact support for assistance",
    "expertCompleteCompetitionsAlertMessage": "Please confirm that all participants have been assessed before proceeding, Are you sure you want to mark this competition as complete?",
    "expertNoJudgesErrorMessage": "No judges are currently assigned to this competition.",
    "expertEnableJudgingError": "Error: Failed to enable judging. Please try again later or contact support for assistance",
    "expertDisableJudgingError": "Error: Failed to disable judging. Please try again later or contact support for assistance",
    "cannotCreateCompetitionText": "Competition could not be created. Please try again later",
    "addCertificateDetailsText": "Please add Certificate Details",
    "addCompetitionDetailsText": "Please add Competition Details",
    "executableFileUploadError": "Executable files are not allowed!",
    "studentSubmitSolutionError": "Could not submit solution, please try again or contact support for assistance",
    "studentSubmitSolutionSessionError": "Your session has been expired. Please login to upload the solution",
    "studentSubmitSolutionInvalidfileError": "Please select valid file!",
    "somethingWentWrongText": "Something Went Wrong!!",
    "error": "Error",
    "enterValidUrl": "Please enter a valid URL",
    "invalidDecimalNumber": "cannot be a decimal value",
    "enterGuidelinesError": "Please add competition guidelines",
    "addCertificateLogo": "Please add atleast 1 certificate logo",
    "addSignatory": "Please complete atleast 1 signatory Details",
    "resultErrorJudgingInProgress": "Judging is still in progress final result may vary",
    "noUpcomingCompetitionnFallBackMessage": "No upcoming competition available to display at the moment",
    "noOngoingCompetitionnFallBackMessage": "No ongoing competition available to display at the moment",
    "noCancelledCompetitionnFallBackMessage": "No cancelled competition available to display at the moment",
    "downloadCompetitionResultError": "Error: Unable to download competition result, please try again later or contact support for assistance",
    "pleaseAddText": "Please add",
    "pleaseEnterText": "Please enter a",
    "endTimeBeforeStartTimeError": "Invalid competition end time. Please select a time that is later than competition start time",
    "registrationTimeBeforeStartTimeError": "Invalid registration closing time. Please select a time that is before competition start time",
    "registrationDateBeforeStartDate": "Invalid registration closing date. Please select a date that is earlier than competition start date",
    "selectSkill": "Please select a skill",
    "pleaseSelectText": "Please select a",
    "judgesInvalidEmail": "Please enter a valid email",
    "invalidSchoolId": "Invalid school id",
    "addGuidelinesError": "Please add guidelines",
    "downloadJudgingSheetError": "Error: Unable to download judging sheet, please try again later or contact support for assistance",
    "submitMarksError": "Error: Unable to submit marks, please try again later or contact support for assistance",
    "saveMarksError": "Error: Unable to save the marks, please try again later or contact support for assistance",
    "downloadCompetitionSummaryError": "Error: Unable to download competition summary, please try again later or contact support for assistance",
    "enrollmentFailCompetitionStartedError": "Oops!! You cannot enrol in competition. It has already started",
    "enrollmentFailRegistrationClosedError": "Oops!! Registration is closed for this competition",
    "enrollmentFailStudentAlreadyEnrolledError": "You have already enrolled in this competition",
    "enrollmentFailRegistrationFullError": "Oops!! Registration is closed as all seats have been filled",
    "enrollmentFailError": "<p>Oops! We could not enrol you at the moment. Looks like an unusual technical glitch. We recommend you to try again after a few minutes or raise your concern at <a href='mailto:support@qrencia.com'>support@qrencia.com</a></p>",
    "enrollmentUserNotStudentError": "Only student can enrol in the competition",
    "enrollmentUserNotLoggedInError": "Please login to enrol in competition",
    "noCoursesFoundText": "No Courses Found",
    "noMentorsFoundText": "No Mentors Found",
    "noQtalksFoundText": "No Qtalks Found",
    "noWorkshopsFoundText": "No Workshops Found",
    "noBrainBoostersFoundText": "No Brain Boosters Found",
    "noNudgesFoundText": "No Nudges Found",
    "noCompetitionsFoundText": "No Competitions Found",
    "noInterestSelectedMessage": "Please choose at least one skill of interest to make your experience more personalized.",
    "sendJudgingLinkError": "Error: Unable to send the link to all associated judges, please try again later",
    "pastDateError": "cannot be a past date",
    "startTimeError": "Invalid competition start time. Please select a time that is later than current time",
    "registrationBeforeCurrentTimeError": "Invalid registration closing time. Please select a time that is later than the current time",
    "fileSizeMoreThan5mbError": "cannot be more than 5mb in size",
    "fileTypeOtherThanJpgOrPngError": "must be of jpg,jpeg or png format",
    "fileTypeOtherThanPdfError": "Please select pdf file",
    "addCertificateLogoError": "Please add atleast 1 certificate logo",
    "addCertificateSignatureError": "Please add atleast 1 signature image",
    "noPastCompetitionFallbackMesssage": "No past competition available to display at the moment",
    "solutionAlreadyUploaded": "Your have already uploaded solution for this competition",
    "downloadSolution": "Download",
    "studentRaiseNudgeInvalidSkillError": "Please select a skill",
    "studentRaiseNudgeInvalidQuestionError": "Please enter a question",
    "studentRaiseNudgeInvalidDescriptionError": "Please enter a description",
    "studentRaiseNudgeNoExpertError": "Currently, there are no experts available to respond to your nudge. Please check back later for a response",
    "studentRaiseNudgeError": "An unexpected error occurred. Please contact support for assistance",
    "studentViewNudgesFallbackMessages": ["You haven't raised any nudges yet!", "No resolved nudges are available to display!"],
    "invalidMeritRange": "Number of merit certificates should be greater than or equal to 0",
    "meritRangeLessThanParticipantLimit": "Number of merit certificates cannot exceed allowed number of participants",
    "uploadJudgingSheetError": "Error: Unable to upload judging sheet, please try again later or contact support for assistance",
    "invalidJudgingSheetFormatError": "Upload failed. Please ensure the file format is .xlsx, then try again.",
    "expertObrIncorrectEmail": "Error! Incorrect email",
    "expertObrCouldNotGet": "Error! Could not fetch obr details",
    "expertObrCouldNotCreate": "Internal Server Error: Please try again later or contact support",
    "individualJudgingLinkFailure": "Failed to share judging link. Please check the email address and your internet connection, then try again. Contact support if the issue persists.",
    "noRecentlyPublishedActivitiesText": "No recently published activities available for you. Please check back later.",
    "noRecentlyPublishedCompetitionsText": "No recently published competitions available for you. Please check back later.",
    "noRecentlyPublishedSkillLabsText": "No recently published skill labs available for you. Please check back later.",
    "noRecentlyPublishedWorkshopsText": "No recently published workshops available for you. Please check back later.",
    "noRecentlyPublishedCoursesText": "No recently published courses available for you. Please check back later.",
    "expertViewActiveNudgesFallbackMessages": ["You have no", "nudges at the moment"],
    "expertViewClosedNudgesFallbackMessages": ["You have no", "nudges to view"],
    "expertRespondToNudgeInvalidResponseError": "Response required! Please enter your response before submitting",
    "expertRevertToNudgeError": "An unexpected error occurred. Please contact support for assistance",
    "expertRevertToNudgeResponseMoreThanLimitError": "Response text cannot be more than 500 characters",
    "studentRaiseNudgeDescriptionMoreThanLimitError": "Description text cannot be more than 500 characters",
    "expertObrIncorrectFirstName": "First name cannot contain special characters: Please enter a valid name",
    "agencyObrIncorrectAgencyName": "Agency name cannot contain special characters: Please enter a valid name",
    "agencyNameDoubleQuotesError": "Agency name cannot contain double quotes: Please enter a valid name",
    "agencyObrIncorrecttName": "Agency name cannot contain special characters: Please enter a valid name",
    "expertObrfirstNameMinLength": "First name should be at least 3 characters long",
    "agencyObrfirstNameMinLength": "Agency name should be at least 3 characters long",
    "expertObrLasttNameMinLength": "Last name should be at least 3 characters long",
    "noObrFound": "There are no OBRs to show",
    "expertObrIncorrectLastName": "Last name cannot contain special characters: Please enter a valid name",
    "expertObrIncorrectDob": "Invalid date of birth: Please enter a valid date that is in the past",
    "signatoryOneError": "Please complete first signatory details",
    "signatoryTwoError": "Please complete second signatory details",
    "signatoryThreeError": "Please complete third signatory details",
    "atleastOneLogoError": "Please add atleast one logo to configure certificate",
    "certificateTitleError": "Please enter a title to configure certificate",
    "certificateMessageError": "Please enter a message to configure certificate",
    "addOneSignatoryDetails": "Please add atleast one signatory details",
    "invalidResetPasswordLink": "Password reset link has expired - Request again",
    "passwordDoNotMatchError": "Confirmation password should match with password",
    "obrCommentLength": "Maximum character limit exceeded. Please enter a comment within 500 characters",
    "certificateNotAvailableError": "Certificate is not available for this competition",
    "obrCommentEmpty": "Please provide a comment before submitting your review of the OBR form",
    "cannotSendEmailError": "Internal Server Error: Please try again later or contact support",
    "obrSubmitError": "Internal Server Error: Please try again later or contact support",
    "uploadFileMoreThanZeroBytes": "Please upload file having size more than 0 bytes.",
    "attachmentsLessThan25Mb": "Size of attachments should be less than 25MB",
    "passwordResetFailedError": "Something went wrong. Please try again",
    "emailVerificationError": "Please enter a registered email address",
    "editCompetitionFailedError": "Edit failed! Please check your internet connection, then try again. Contact support if the issue persists.",
    "noResponseFallbackMessage": "Expert Response Pending",
    "expertObrDetailsNotSavedError": "Error! something went wrong, please try again later or contact support for assistance",
    "expertObrDetailsNotSentForReview": "Internal Server Error: Please try again later or contact support",
    "unsavedDetailsError": "Please save the onboarding details as draft before sending for review",
    "obrRequestInReviewMessage": "Your request is under review. We will update you with an email on your status",
    "obrLinkExpiredMessage": "Looks like link has expired or your request has been declined",
    "judgeEmailAlreadyAdded": "Email already added. Please choose a different email address.",
    "obrDeactivateError": "Internal Server Error: Please try again later or contact support",
    "obrActivateError": "Internal Server Error: Please try again later or contact support",
    "obrApporveError": "Internal Server Error: Please try again later or contact support",
    "obrRejectError": "Internal Server Error: Please try again later or contact support",
    "obrCountryMissingError": "Country field is required",
    "obrMissingStateError": "State field is required",
    "obrStateEmpty": "State field is required",
    "obrMissingCityError": "City field is required",
    "obrMissingSkills": "Skills field is required",
    "obrMissingPhoneNumber": "Phone number field is required",
    "passwordLengthShortError": "Password needs to be at least 8 characters long",
    "passwordLinkFailedError": "Something went wrong! Please try again",
    "createOBRNegativeMobileNumberError": "Error! Invalid Phone number",
    "expertObrMissingFirstName": "Error! Missing First Name",
    "agencyObrMissingName": "Error! Missing Agency Name",
    "agencyObrMissingAgencyType": "Error! Missing Agency Type",
    "expertObrInValidFirstName": "Please enter a valid first name",
    "agencyObrInValidName": "Please enter a valid agency name",
    "expertObrInValidLastName": "Please enter a valid last name",
    "expertObrMissingLastName": "Error! Missing Last Name",
    "expertObrMissingDob": "Error! Missing Date of Birth",
    "pastRegistrationDateError": "Invalid registration closing date. Please select a date that is earlier than competition start date",
    "createObrUserAlreadyExistsError": "User already exists, try again with a unique email address",
    "noNewObrFound": "You have no recently created OBR",
    "noInTransitObrFound": "You have no OBR currently in transit for review or approval",
    "createObrInvalidStateError": "Please enter valid state",
    "courseDescriptionSizeLimit": "Course brochure should be less than 50 mb in size",
    "courseDescriptionPdfFormat": "Course brochure must be of PDF format.",
    "courseEndDateError": "Invalid end date. Please select a date greater than the course start date",
    "enterAddressError": "Please provide an address",
    "minimumCostError": "Please enter a cost which is greater than or equal to 0",
    "minimumDiscountError": "Please enter a discount which is greater or equal to 0",
    "courseDurationError": "Please enter a course duration which is greater than 0",
    "courseDurationIn30intervalError": "Please enter duration like 0.5, 1, 1.5, 2, etc.",
    "courseClassesError": "Invalid number of classes. Please a number which is greater than 0",
    "promotionVideoTypeError": "Promotion video must be a video file",
    "courseDurationRequired": "Please enter the duration of course",
    "courseClassesRequiredError": "Please enter number of classes for the course",
    "certificateDescriptionError": "Please enter a description to configure certificate",
    "promotionVideoFileSizeError": "Promotion video must be less than 200 mb in size",
    "courseCreationError": "We encountered a technical issue while creating your course on Qrencia. Please contact support for assistance.",
    "addCourseDetailsError": "Please enter course details",
    "courseStartDateRequired": "Please enter course start date",
    "courseEndDateRequired": "Please enter course end date",
    "failedToPublishError": "Failed to publish course. Please try again later",
    "failedToUnpublishError": "Failed to unpublish course. Please try again later",
    "failedCourseUpdate": "Edit failed! Please try again or contact support if the issue persists.",
    "courseStartTimeError": "Invalid start time. Please select a time that is later than current time",
    "courseEndTimeError": "Invalid end time. Please select a time greater than the start time",
    "courseEndTimeBeforeCurrentTime": "Invalid course end time. Please select a time that is later than the current time",
    "coursePastEndDateError": "Course end date cannot be a past date",
    "noPublishedCoursesFallbackMessage": "You have not published any course yet. Publish your first course now!",
    "noUnpublishedCoursesFallbackMessage": "No unpublished courses available to display at the moment",
    "fetchCourseDetailsError": "Error: Unable to fetch course details. Please try again later or contact support for assistance",
    "noCourseParticipantsError": "No participants are currently enrolled in this course, please check back later",
    "studentQueryResponseError": "Error! Unable to respond to query. Please try again later",
    "noQuestionAskedFallbackMessage": "No queries are raised by the students at the moment. Please check back later",
    "noUpcomingCoursesFallbackMessage": "You have no courses for the selected date.",
    "studentCourseQuerySubmitError": "Error! Unable to submit your query. Please try again.",
    "onlyStudentRaiseCourseQueryError": "Only logged in students can raise a query.",
    "onlyStudentEnrolCourseError": "Only Students can enrol in the course",
    "studentAlreadyEnrolledCourseError": "You are already enrolled in this course",
    "noUpcomingEnroledCoursesFallbackMessage": "No upcoming courses to display at the moment",
    "noOngoingEnroledCoursesFallbackMessage": "No ongoing courses to display at the moment",
    "noPastEnroledCoursesFallbackMessage": "No past courses to display at the moment",
    "noCoursesFallBackMessage": "No courses are available to display at the moment",
    "expertNoUpcomingCoursesFallbackMessage": "No upcoming courses available to display at the moment",
    "expertNoOngoingCoursesFallbackMessage": "No ongoing courses available to display at the moment",
    "expertNoPastCoursesFallbackMessage": "No past courses available to display at the moment",
    "expertNoUpcomingWorkshopFallbackMessage": "No upcoming workshops available to display at the moment",
    "expertNoOngoingWorkshopFallbackMessage": "No ongoing workshops available to display at the moment",
    "expertNoPastWorkshopFallbackMessage": "No past workshops available to display at the moment",
    "coursePageInvalidReviewComment": "Please enter valid comment",
    "expertObrPageAddDob": "Please enter date of birth",
    "expertObrProfilePictureError": "Please select a profile picture",
    "expertObrInvalidExperienceError": "Please enter valid years of experience",
    "expertObrOrganizationNameError": "Please enter skill institute name",
    "expertObrDesignationError": "Please enter your designation at the institute",
    "expertObrWorkEmailError": "Please enter a valid work email address",
    "expertObrOrgCountryError": "Please add skill institute's country",
    "expertObrOrgStateError": "Please add skill institute's state",
    "expertObrOrgCityError": "Please add skill institute's city",
    "expertObrOrgMobileError": "Please enter a valid work mobile number",
    "expertObrOrgLinkedinLinkError": "Please enter a valid linkedin page URL of your institute",
    "expertObrOrgWebsiteLinkError": "Please enter a valid website URL of your institute",
    "expertObrOrgFacebookLinkError": "Please enter a valid facebook page URL of your institute",
    "expertObrOrgInstagramLinkError": "Please enter a valid instagram handle URL of your institute",
    "expertObrOrgYoutubeLinkError": "Please enter a valid youtube channel URL of your institute",
    "expertObrOrgAddressError": "Please enter address of your skill institute",
    "expertObrOrgGeolocationError": "Please enter a valid google maps location URL of your institute",
    "expertObrOrgDescriptionError": "Please enter a short description for your skill institute",
    "expertObrOrgCentreError": "You have added centre details only. Please fill skill institute information along with centre details",
    "highestQualificationFileError": "Please add a file for qualification in skill",
    "expertObrIdProofError": "Please add a file for identity proof",
    "expertObrAddressProofError": "Please add a file for address proof",
    "expertObrMicrositeWebsiteError": "Please add a valid website link for your microsite information",
    "expertObrMicrositeFacebookError": "Please add a valid facebook link for your microsite information",
    "expertObrMicrositeLinkedinError": "Please add a valid linkedin link for your microsite information",
    "expertObrMicrositeInstagramError": "Please add a valid instagram link for your microsite information",
    "expertObrMicrositeYoutubeError": "Please add a valid youtube link for your microsite information",
    "expertObrMicrositeVideoFormatError": "Video message format is not valid!",
    "expertCourseNoFaqAddedFallBackMessage": "No FAQs are currently available to display",
    "invalidCourseDescription": "Please limit description to 500 characters",
    "invalidCourseTitile": "Please limit the title to 100 characters",
    "downloadIndividualCertificateError": "Error: Unable to download competition certificate, please try again later or contact support for assistance",
    "noUpcomingEnroledCompetitionsFallbackMessage": "No upcoming competitions to display at the moment",
    "noJudgingParametersConfiguredWarning": "No Judging parameters have been configured for this competition",
    "signupInvalidDateOfBirth": "Please enter date of birth",
    "signupDateOfBirthGreaterThanToday": "Invalid date of birth: Please enter a valid date that is in the past",
    "signupEmailOtpVerifyError": "Invalid OTP. Please check and try again",
    "signupVerifyEmailError": "Please verify your email to complete sign up",
    "signupGenerateMobileOtpError": "Failed to generate OTP. Please refresh and retry",
    "signupVerifyMobileError": "Please verify your mobile number to complete sign up",
    "adminApproveCompetitionError": "Fee waiver request have been rejected for this competition. We have updated the organizer on the status of competition",
    "tryAgainErrorText": "Something went wrong! Please try again",
    "approveRejectRestriction": "Only admin can approve or reject the fee waiver request",
    "loginToContinueText": "Please login to continue",
    "noFeaturedLearningActivitiesText": "No featured learning activities available for you. Please check back later.",
    "noFeaturedLearningCompetitionsText": "No featured competitions available for you. Please check back later.",
    "noFeaturedLearningSkillLabsText": "No featured skill labs available for you. Please check back later.",
    "noFeaturedLearningWorkshopsText": "No featured workshops available for you. Please check back later.",
    "noFeaturedLearningCoursesText": "No featured courses available for you. Please check back later.",
    "bulkSignupRegistraionClosedError": "The registration is closed for",
    "bulkSignupSeatsFullError": "The registration is closed as all seats have been filled for",
    "bulkSignupCompetitionStartedError": "You cannot enrol in",
    "bulkSignupEligibilityError": "The registration is closed for",
    "eoiMissingStateError": "Please enter the state",
    "eoiMissingCountryError": "Please enter the country",
    "eoiMissingCityError": "Please enter the city",
    "eoiMissingSkillError": "Please select the skill(s) that you are interested in",
    "eoiMissingFirstNameError": "Please enter first name",
    "eoiMissingLastNameError": "Please enter last name",
    "eoiMissingDobError": "Please enter date of birth",
    "eoiMissingEmailError": "Please enter an email address",
    "eoiInvalidEmailError": "Please enter a valid email address",
    "eoiMissingMobileError": "Please enter a phone number",
    "eoiInvalidMobileError": "Please enter a valid phone number",
    "eoiInvalidDobError": "Invalid date of birth. Please select a date of birth that is in the past",
    "eoiFormSubmitError": "We are experiencing some technical difficulties on our end.  Please try again later or contact our support team at 8290125695",
    "allFieldsAreRequiredText": "Please fill in all the required details.",
    "alreadyEnroledInCourseError": "You have already enrolled in this course",
    "courseEnrolStartedError": "Oops!! You cannot enrol in course. It has already started",
    "bulkEnrolParticipantSheetError": "Error! Something went wrong while enroling the participants. Please try again or contact support for assistance.",
    "bulkEnrolUploadSheetError": "An error occured while uploading participant sheet. Please try again or contact support for assistance",
    "bulkEnrolSheetFileTypeError": "Upload failed. Please ensure the file format is .xlsx, then try again.",
    "bulkEnrolSheetDownloadError": "Unable to download template sheet. Please try again or contact support for assistance",
    "competitionAgeLimitEnrolError": "Oops!! You are not eligible to enrol in the competition as your age does not meet the competition age criteria",
    "unpublishedCourseEnrolmentError": "Oops!! You cannot enrol in an unpublished course",
    "pleaseSelectCompetitionErrorMessage": "Please select a competition.",
    "registrationDateBeforeEndDate": "Invalid registration closing date. Please select a date that is on or before the competition end date",
    "registrationTimeBeforeEndTimeError": "Invalid registration closing time. Please select a time that is before competition end time",
    "competitionEndDateError": "Invalid competition end date. Please select a date that is on or after the competition start date",
    "resultErrorJudgingDisabled": "Judging is disabled",
    "aiDrawingUploadFileSizeError": "Please upload file with size less than 6MB",
    "aiDrawingUploadFileFormatError": "Invalid file format, uploaded file must be of jpg or jpeg",
    "aiDrawingUploadParseError": "Image could not be parsed at this moment, please try again",
    "cannotEditPastCourseInfo": "You cannot edit a past course",
    "cannotEditPastWorkshopInfo": "You cannot edit a past workshop",
    "cannotPublishPastCourseInfo": "You cannot publish a past course",
    "missingUserRoleError": "Error! Missing User Role",
    "somethingWentWrongPleaseTryAgainError": "Error!! Something went wrong. Please try again.",
    "validImageFormatError": "Please select valid image of format .jpg /.jpeg /.png",
    "mapStudentsSelectClassAndSectionMessage": "Please select a class and a section to view the list of students",
    "agencyNotExpertIdError": "Expert with Qrencia Id",
    "doesNotExistsText": " does not exists",
    "agencyShowExpertDetailsError": "Something went wrong while fetching the expert details. Please try again or contact support for assistance.",
    "mappingLinkSendError": "Something went wrong while sending mapping invitation to expert. Please try again or contact support for assistance.",
    "onlyExpertApproveInvitationStatusMessage": "Only experts can approve or reject the invitation from school",
    "expertAcceptInvitationRequestFailed": "Something went wrong while accepting the invitation. Please try again later",
    "expertRejectInvitationRequestFailed": "Something went wrong while rejecting the invitation. Please try again later",
    "expertAlreadyAssociatedWithSameSchool": "Expert is already associated with your school!",
    "expertAlreadyAssociatedWithTheSchool": " Expert is already associated with the school!",
    "noLearningActivitiesMessage": "No Learning Activities to display at the moment",
    "competitionNotEligibleErrorMessage": "Oops!! You are not eligible to enrol in this competition as you do not meet the enrolment criteria",
    "competitionNotAssociatedToStudentErrorMessage": "Only associated students can enrol in this school's competition",
    "pleaseLogInToEnrolError": "Please log in to enrol",
    "initiatePaymentFailedError": "<p>We are experiencing some technical difficulties with payments. Please try again later or contact <a href='mailto:support@qrencia.com'>support@qrencia.com</a> for assistance.</p>",
    "paymentDeductedEnrolmentError": "<p>We have successfully received your payment with Payment ID {{transactionId}}.<br/>However, due to an unusual glitch, your enrolment is taking longer than expected. Our team is looking into the same and you shall receive the enrolment confirmation with necessary details shortly.</p>",
    "paymentDeductedRaiseNudgeError": "<p>We have successfully received your payment with Payment ID {{transactionId}}.<br/>However, due to an unusual glitch, is taking longer than expected to raise your nudge. Our team is looking into the same and you shall see the nudge in your open nudges section shortly<p>",
    "verifyPaymentFailedError": "<p>Looks like your payment did not go through!<br/>Our payment partner PhonePe has not received your money.<br />Please contact your bank in case of any discrepancy. Your Payment ID for this attempted transaction is {{transactionId}}</p>",
    "verifyPaymentFailedException": "<p>We are experiencing some technical difficulties in completing your payment.<br />Please contact <a href='mailto:support@qrencia.com'>support@qrencia.com</a> for assistance.</p>",
    "nudgePaymentVerificationError": "<p>Looks like your payment did not go through!<br/>Our payment partner PhonePe has not received your money.<br/>Please contact your bank in case of any discrepancy. Your Payment ID for this attempted transaction is {{transactionId}}</p>",
    "landingPageSkillSelectionError": "Please enter a valid skill",
    "onlyStudentRaiseNudgeError": "Only logged in students can raise a Nudge.",
    "studentRaiseNudgeInvalidExpertError": "Please select an expert",
    "aiImageGenerationError": "Image could not be generated at this moment, please try again.",
    "minimumCompetitionCostError": "The minimum cost should be ",
    "captchaVerificationFailedError": "Captcha Verification Failed! please try again.",
    "competitionEditFailedError": "Edit failed! Please try again or contact support if the issue persists.",
    "pelaseConfigureCertificateError": "Error! Please configure a certificate to edit the competition",
    "editCompetitionRemoveJudgeError": "Edit Failed! The following judges have started the evaluation for the competition - ",
    "competitionEndDatePastError": "Competition end date cannot be a past date",
    "competitionEndTimePastError": "Invalid competition end time. Please select a time that is later than the current time",
    "resultDeclareMandatoryError": "Please select a value for 'Declare Results' field",
    "enterCompetitionDescriptionError": "Please enter a description",
    "workshopDescriptionSizeLimit": "Workshop brochure should be less than 50 mb in size",
    "workshopDescriptionPdfFormat": "Workshop brochure must be of PDF format.",
    "invalidWorkshopTitle": "Please limit the title to 100 characters",
    "workshopStartDateRequired": "Please enter workshop start date",
    "workshopEndDateRequired": "Please enter workshop end date",
    "workshopEndDateError": "Invalid end date. Please select a date greater than the workshop start date",
    "workshopDurationRequired": "Please enter the duration of workshop",
    "workshopDurationError": "Please enter a workshop duration which is greater than 0",
    "workshopDurationIn30intervalError": "Please enter duration like 0.5, 1, 1.5, 2, etc.",
    "workshopStartTimeError": "Invalid start time. Please select a time that is later than current time",
    "workshopEndTimeError": "Invalid end time. Please select a time greater than the start time",
    "workshopEndTimeBeforeCurrentTime": "Invalid workshop end time. Please select a time that is later than the current time",
    "workshopPastEndDateError": "Workshop end date cannot be a past date",
    "workshopCreationError": "We encountered a technical issue while creating your workshop on Qrencia. Please contact support for assistance.",
    "addWorkshopDetailsError": "Please enter workshop details",
    "failedToPublishWorkshopError": "Failed to publish workshop. Please try again later",
    "failedToUnpublishWorkshopError": "Failed to unpublish workshop. Please try again later",
    "cannotPublishPastWorkshopInfo": "You cannot publish a past workshop",
    "noPublishedWorkshopsFallbackMessage": "You have not published any workshop yet. Publish your first workshop now!",
    "noUnpublishedWorkshopsFallbackMessage": "No unpublished workshop available to display at the moment",
    "cannotUnPublishWorkshopWithEnrolmentsInfo": "Workshop with enrolled students cannot be unpublished",
    "cannotUnPublishCourseWithEnrolmentsInfo": "Course with enrolled students cannot be unpublished",
    "noWorkshopParticipantsError": "No participants are currently enrolled in this workshop, please check back later",
    "fetchWorkshopDetailsError": "Error: Unable to fetch workshop details. Please try again later or contact support for assistance",
    "failedWorkshopUpdate": "Edit failed! Please try again or contact support if the issue persists.",
    "cannotPublishOngoingWorkshopInfo": "You cannot publish an ongoing workshop",
    "cannotPublishOngoingCourseInfo": "You cannot publish an ongoing course",
    "noUpcomingEnroledWorkshopsFallbackMessage": "No upcoming workshops to display at the moment",
    "onlyStudentEnrolWorkshopError": "Only Students can enrol in workshops",
    "alreadyEnroledInWorkshopError": "You have already enrolled in this workshop",
    "workshopEnrolStartedError": "Oops!! You cannot enrol in workshop. It has already started",
    "unpublishedWorkshopEnrolmentError": "Oops!! You cannot enrol in an unpublished workshop",
    "workshopEnrollmentUserNotStudentError": "Only student can enrol in the workshop",
    "workshopEnrollmentUserNotLoggedInError": "Please login to enrol in competition",
    "submittedStudentsNotMarkedError": "Please make sure that all participants who have submitted their solutions have been assessed before completing the competition.",
    "sessionExpiredPaymentInitiatedForNudge": "Your session has been expired. Please log in to raise the nudge.",
    "sessionExpiredPaymentInitiatedForEnrolment": "Your session has been expired. Please log in to enrol in the ",
    "sessionExpiredCompetitionEnrolmentError": "Your session has been expired. Please log in to enrol in the competition",
    "sessionExpiredCourseEnrolmentError": "Your session has been expired. Please log in to enrol in the course",
    "sessionExpiredWorkshopEnrolmentError": "Your session has been expired. Please log in to enrol in the workshop",
    "expertMarkCompetitionCompleteWarning": "Are you sure you want to mark this competition as complete?",
    "imageError5MbSize": "Please select an image less than 5 Mb in size.",
    "doubleQuotesTitleError": "Please enter a title without double quotes.",
    "expertFollowErrorMessage": "the follow feature is only for students and agencies to keep track of the various learning activities created by the experts. Experts do not have access to this feature.",
    "adminFollowErrorMessage": "the follow feature is only for students and agencies to keep track of the various learning activities created by the experts. You do not have access to this feature.",
    "noExpertsFoundMessage": "No experts found",
    "agencyFollowOtherAgencyError": "Agencies cannot follow other agenies",
    "unfollowingErrorMessage": "Something went wrong while unfollowing",
    "followingErrorMessage": "Something went wrong while following",
    "youHaveAlreadyUnfollowedText": "You have already unfollowed",
    "youHaveAlreadyFollowedText": "You have already followed",
    "pleaseTryAgainText": "Please try again.",
    "noExpertsForSelectedCategory": "No experts available for skill category '{{selectedCategory}}' at the moment",
    "sessionExpiredFollowError": "Your session has been expired. Please log in to continue.",
    "expertObrOrganizationNameQuotesError": "Please enter skill institute name without double quotes",
    "agencyFollowOtherAgencyErrors": "Agency cannot follow other Agencies",
    "agencyFollowAgencyError": "Following Restricted: You can only follow experts and academies on Qrencia",
    "academyFollowAgencyError": "Following Restricted: You can only follow experts on Qrencia.",
    "courseRegistrationDateBeforeEndDate": "Invalid registration closing date. Please select a date that is on or before the course end date",
    "courseRegistrationTimeBeforeEndTimeError": "Invalid registration closing time. Please select a time that is before course end time",
    "workshopRegistrationDateBeforeEndDate": "Invalid registration closing date. Please select a date that is on or before the workshop end date",
    "workshopRegistrationTimeBeforeEndTimeError": "Invalid registration closing time. Please select a time that is before workshop end time",
    "courseRegistrationEndedError": "Oops!! Registration is closed for this course",
    "workshopRegistrationEndedError": "Oops!! Registration is closed for this workshop",
    "learningActivityIdNotExistError": "{{learningActivityType}} does not exist for the given Id: {{learningActivityId}}. Please check the Id and try again.",
    "learningActivityDetailsFetchError": "Something went wrong while fetching the {{learningActivityType}} details. Please try again later.",
    "copyLearningActivityDetailsWarning": "Warning: Your current details will be overwritten by new changes. Do you wish to continue?",
    "resetLearningActivityDetailsWarning": "Warning: Your current changes will be discarded. Do you wish to continue?",
    "reviewProfanityError": "Your review contains inappropriate language. Please revise before submitting.",
    "enterRatingErrorMessage": "Please enter a rating",
    "enterReviewErrorMessage": "Please enter a review",
    "submitReviewErrorMessage": "Something went wrong while posting your review. Please try again.",
    "adminDownloadActiveLernersDetailsError": "Error in downloading learner details",
    "invalidDateRangeError": "Invalid date range!",
    "createOBRInvalidMobileNumberError": "Error! Invalid Phone number",
    "leadEmailAlreadyExistsError": "Email already in use! Please enter a different email id and try again",
    "signupDateOfBirthLessThanAllowedYears": "Your age should be atleast ",
    "signupDateOfBirthLessThanAllowedYearsPart2": " to use the platform.",
    "enterDemoDateError": "Please enter a demo date",
    "enterDemoTimeError": "Please enter a demo time",
    "demoDatePastDateError": "Error! Estimated onboarding date cannot be a past date",
    "enterValidWebsiteLinkError": "Error! Invalid website link",
    "sendOBRFromLeadTableError": "Something went wrong while sending the OBR. Please try again",
    "sendReminderFromLeadError": "Something went wrong while sending the reminder email. Please try again",
    "sendReminderToListOfferingsError": "Something went wrong while sending the reminder email to list offerings. Please try again",
    "selectCourseTypeError": "Please select a course type",
    "minimumCostFlexibleCourseError": "Please enter a cost which is greater than 0",
    "selectCourseWeekdaysError": "Please select the days on which the course is scheduled.",
    "enableEnrolmentsFailedError": "Error: Unable to enable the enrolments at the moment. Please try again or contact suppport for assistance.",
    "disableEnrolmentsFailedError": "Error: Unable to disable the enrolments at the moment. Please try again or contact suppport for assistance.",
    "subscriptionReminderEmailError": "Error: Something went wrong while sending the reminder email. Please try again or contact suppport for assistance",
    "selectMarkAttendanceDateError": "Please select a date to mark the attendance",
    "futureDateMarkAttendanceError": "You cannot mark the attendance for a future date.",
    "studentAttendanceUpdateError": "Something went wrong while marking the attendance. Please try again or contact support for assistance.",
    "structuredCourseDateRangeError": "Please select a date between course start date and end date",
    "selectTrackingTypeMessage": "Please select a tracking type",
    "pleaseAddPackageDurationError": "Please add a valid duration in days",
    "subscriptionActiveEnrolError": "Your course subscription is still active. Enrolment will be allowed once your subscription has expired",
    "enrolmentsDisabledForCourseError": "Enrolments are disabled for this course",
    "pendingCourseEnrolmentRequestUnpublishError": "Course with pending enrolment requests cannot be unpublished",
    "pendingWorkshopEnrolmentRequestUnpublishError": "Workshop with pending enrolment requests cannot be unpublished",
    "pendingEnrolmentFetchDetailsError": "There are no pending enrolment requests for this {{learningActivityType}} at the moment.",
    "reRequestForCODerror": "You cannot enrol in this course at the moment as your previous enrolment request for this course is still pending.",
    "codRequestSendError": "Something went wrong while sending your enrolment request. Please try again or contact support for assistance.",
    "approveCodDiscountError": "Discount cannot be greater than course cost",
    "approveCodDurationError": "Please add a package duration greater than 0",
    "approveCodClassValidityError": "Please add class validity greater than 0",
    "approveCodClassesError": "Please add valid number of classes",
    "rejectCodError": "Something went wrong while rejecting the enrolment request, please try again later.",
    "approveCodError": "Something went wrong while approving the enrolment request, please try again later.",
    "reRequestForCompetitionCODError": "You cannot enrol in this competition at the moment as your previous enrolment request for this competition is still pending.",
    "reRequestForWorkshopCODError": "You cannot enrol in this workshop at the moment as your previous enrolment request for this workshop is still pending.",
    "ambassadorChallengeAcceptException": "Something went wrong while accepting the ambassador challenge. Please try again or contact support for assistance",
    "ambassadorChallengeAlreadyAcceptedError": "You have already accepted the ambassador challenge.",
    "ambassadorChallengeApartFromAcademyError": "Ambassador challenge is only available for experts and academies.",
    "participationSummaryDownloadError": "Error: Unable to download participation summary for the competition. Please try again later or contact support for assistance",
    "networkIssueError": "<p>We have successfully received your payment with Payment ID {{transactionId}}.<br/>However, due to the network issue, your enrolment is taking longer than expected. Please do not reload or refresh this page. Click on the button below to retry.</p>",
    "networkIssueRaiseNudgeError": "<p>We have successfully received your payment with Payment ID {{transactionId}}.<br/>However, due to the network issue,it is taking longer than expected to raise your nudge. Please do not reload or refresh this page. Click on the button below to retry.</p>",
    "noActiveQuizesFoundMessage": "No quizzes available at the moment. Please check back later.",
    "noQuizesFound": "No quizzes found",
    "addClassValidityError": "Please enter a valid class validity",
    "selectCourseForFeedbackMessage": "Please select a course to share the feedback with learners",
    "noLearnersForFeedbackMessage": "There are no active learners enrolled in this course",
    "feedbackSubmitErrorMessage": "Something went wrong while submitting the feedback. Please try again or contact support for assistance",
    "feedbackStudentsFetchError": "Could not fetch the list of enrolled learners. Please try again or later",
    "advanceProfileSelectSkillError": "Please select atleast one skill",
    "completeProfileCountryError": "Please select a country",
    "completeProfileMobleError": "Please enter your mobile number",
    "completeProfileVerifyMobileValidation": "Please verify your mobile number",
    "updateFeedbackErrorMessage": "Something went wrong while updating the feedback. Please try again later!",
    "noAssignedCoursesMessage": "No assigned courses available to display at the moment",
    "assignCourseErrorMessage": "Something went wrong while updating the course assignees. Please try again or contact support for assistance",
    "gymnasticsRubricsErrors": {
        "shortExerciseMaxScore": "Max Short Exercise value for {{criteriaName}} is required. Please enter a valid positive number.",
        "difficultyMaxScore": "Max D-Score value for {{criteriaName}} is required. Please enter a valid positive number.",
        "gender": "Please select gender for {{criteriaName}}"
    },
    "gymnasticsOutOfScoreRequiredError": "Out of score is required. Please enter a valid positive number.",
    "addMedalCriteriaError": "Please add medal criteria",
    "silverMedalCriteriaError": "Silver medal criteria should be less than gold medal criteria",
    "bronzeAndSilverMedalCriteriaError": "Bronze medal criteria should be less than silver medal criteria",
    "bronzeAndGoldMedalCriteriaError": "Bronze medal criteria should be less than gold medal criteria",
    "somethingWentWrongError": "Something went wrong! Please try again or contact support for assistance.",
    "resultsAlreadyDeclaredMessage": "You have already declared the results for this competition",
    "noStudentEnrolledErrorMessage": "No Sutdents are enrolled in this competitition",
    "invalidCompetitionTitle": "Title exceeds maximum length. Please limit the title to 100 characters",
    "addMedalCriteriaForAcademyError": "Please add medal criteria for academy",
    "addMedalCriteriaForSchoolError": "Please add medal criteria for school"
};