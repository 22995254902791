import React, { useState, useEffect, useMemo, useRef } from "react";
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/styles//ag-grid.css';
import 'ag-grid-community/styles//ag-theme-alpine.css';
import { FaArrowLeft, FaMedal } from "react-icons/fa";
import { Button } from "react-bootstrap";
import { AppConstants } from "../../constants/AppConstants";
import { AuthorizedDataService } from "../../services/AuthorizedDataService";
import '../../component/expertManageCompetitionComponent/dataGrid.css';
import Moment from 'react-moment';
import 'moment-timezone';
import { DateTimeUtil } from "../../utils/DateTimeUtil";
import { BiLoaderAlt, BiRightArrowAlt, BiLinkExternal } from "react-icons/bi";
import { ErrorMessages } from "../../constants/ErrorMessages";
import { ErrorSuccessAlertMessage } from "../errorSuccessMessage/ErrorSuccessAlertMessage";
import { FileOperationsUtil } from "../../utils/FileOperationsUtil";
import { ViewResultJudgeDetails } from "./viewResultJudgeDetails";
import { FiDownloadCloud } from 'react-icons/fi';
import { Tooltip } from 'react-tooltip';
import classes from "./expertViewCompetitionResult.module.css"
import { GrDocumentZip } from "react-icons/gr";
import { GymnasticsViewParticipantMarking } from "./GymnasticsViewParticipantMarking";
import { SuccessMessage } from "../../constants/SuccessMessage";
import { DataService } from "../../services/DataService";

export const ExpertViewCompetitionsResult = (props) => {
    const gymnasticsMedals = AppConstants.gymnasticsMedals;
    const gridRef = useRef(AppConstants.nullText);
    const timeZone = DateTimeUtil.getPreferredTimeZoneForUser();
    const [viewDetailsSection, setViewDetailsSection] = useState(AppConstants.falseText);
    const [studentMarksDetails, setStudentMarksDetails] = useState([]);
    const [certificateSpinner, setCertificateSpinner] = useState(AppConstants.falseText);
    const [viewDetailsSectionArray, setViewDetailsSectionArray] = useState([]);
    const [downloadResultSpinner, setDownloadResultSpinner] = useState(AppConstants.falseText);
    const [downloadResultError, setDownloadResultError] = useState(AppConstants.falseText);
    const [downloadCertificateError, setDownloadCertificateError] = useState(AppConstants.emptyString);
    const [downloadSummarrySpinner, setDownloadSummarrySpinner] = useState(AppConstants.falseText);
    const [downloadSummaryError, setDownloadSummaryError] = useState(AppConstants.falseText);
    const [declareResultsError, setDeclareResultsError] = useState(AppConstants.emptyString);
    const [declareResultsSpinner, setDeclareResultSpinner] = useState(AppConstants.emptyString);
    const [declareResultVarient, setDeclareResultVarient] = useState(AppConstants.emptyString);
    const [downloadZipSpinner, setDownloadZipSpinner] = useState(AppConstants.falseText);
    const [downloadZipError, setDownloadZipError] = useState(AppConstants.emptyString);
    const [championshipDetails, setChampionshipDetails] = useState([]);

    const defaultColDef = useMemo(() => ({
        resizable: AppConstants.trueText,
        filter: AppConstants.trueText,
        floatingFilter: AppConstants.trueText,
        suppressMenu: AppConstants.trueText,
        suppressHorizontalScroll: AppConstants.falseText,
        floatingFilterComponentParams: { suppressFilterButton: AppConstants.trueText }
    }), []);

    useEffect(() => {
        const fetchChampionshipData = async () => {
            const url = `${AppConstants.fetchGymnasticsChampionshipAPI}/${props.competitionDetails?.competionSlug}`;
            const response = await DataService.get(url, AppConstants.emptyString, AppConstants.emptyString);
            if (response) {
                setChampionshipDetails(response);
            }
        };
        if (props.competitionDetails?.competionSlug) {
            fetchChampionshipData();
        }
    }, [props.competitionDetails]);

    const getMarksForParticipant = (data) => {
        const { totalMarks, maximumMarksCalculated } = data;
        const { checkCompetitionRubrics, skillName, showPercentageFlag } = props.competitionDetails;

        if (checkCompetitionRubrics === 1) {
            const isGymnastics = (skillName === AppConstants.gymnasticsSkillName);
            const showPercentage = (showPercentageFlag === AppConstants.yText);

            if (isGymnastics) {
                return showPercentage ? `${totalMarks.toFixed(2)} / ${maximumMarksCalculated}` : `${totalMarks.toFixed(2)}`;
            }

            return `${totalMarks} / ${maximumMarksCalculated}`;
        }

        return AppConstants.notAvailableText;
    };

    const columnDefsResultGrid = [
        {
            headerName: "S. No",
            valueGetter: "node.rowIndex + 1",
            maxWidth: 70
        },
        {
            headerName: 'Name',
            field: 'studentName',
            tooltipField: 'studentName',
        },
        {
            headerName: 'Marks',
            field: 'totalMarks/maximumMarksCalculated',
            tooltipValueGetter: ({ data }) => getMarksForParticipant(data),
            valueGetter: ({ data }) => getMarksForParticipant(data)
        },
        {
            headerName: 'Rank',
            field: 'participantRank',
            tooltipValueGetter: ({ data }) => (props.competitionCompletionStatus === AppConstants.completitionStatus.complete && props.competitionDetails.checkCompetitionRubrics === 1) ? `${data.participantRank}` : AppConstants.notAvailableText,
            valueGetter: ({ data }) => (props.competitionCompletionStatus === AppConstants.completitionStatus.complete && props.competitionDetails.checkCompetitionRubrics === 1) ? (data?.participantRank === 0 ? AppConstants.notAvailableText : data?.participantRank) : AppConstants.notAvailableText,
        },
        {
            headerName: 'Judged by',
            field: 'judgedBy',
            cellRendererFramework: ViewResultJudgeDetails,
            cellRendererParams: (params) => ({
                judges: params === AppConstants.nullText ? AppConstants.nullText : params.value
            })
        },
        {
            headerName: 'Submission',
            field: 'Submission Status',
            tooltipValueGetter: ({ data }) => data.submissionFilePath === AppConstants.nullText ? AppConstants.notAvailableText : "Submitted",
            valueGetter: ({ data }) => data.submissionFilePath === AppConstants.nullText ? AppConstants.notAvailableText : "Submitted",
        },
        {
            headerName: 'Result',
            field: 'Result',
            cellRendererFramework: (params) => <div><BiRightArrowAlt className="gridIcon" onClick={() => { handleView(params.data) }} /></div>
        },
    ];
    const getMedalForLearner = (medal) => {
        if (!medal) {
            return AppConstants.hyphen;
        } else {
            return (
                <>
                    {medal} <FaMedal className={medal === gymnasticsMedals.gold.medalName ? classes.goldMedal : medal === gymnasticsMedals.silver.medalName ? classes.silverMedal : classes.bronzeMedal} />
                </>
            );
        }
    };

    const medalColumn = {
        headerName: 'Medal',
        field: 'medal',
        tooltipValueGetter: ({ data }) => data.markedStatus !== AppConstants.doneText ? AppConstants.notAvailableText : data.medal || AppConstants.notAvailableText,
        valueGetter: ({ data }) => data.markedStatus !== AppConstants.doneText ? AppConstants.notAvailableText : data.medal || AppConstants.notAvailableText,
        cellRendererFramework: (params) => {
            const medal = params.data.markedStatus !== AppConstants.doneText ? null : params.data.medal;
            return getMedalForLearner(medal);
        }
    };

    const percentageScoreColumn = {
        headerName: 'Percentage',
        field: 'scorePercentage',
        tooltipValueGetter: ({ data }) => data.markedStatus !== AppConstants.doneText ? AppConstants.notAvailableText : `${data.scorePercentage}%` || AppConstants.notAvailableText,
        valueGetter: ({ data }) => data.markedStatus !== AppConstants.doneText ? AppConstants.notAvailableText : `${data.scorePercentage}%` || AppConstants.notAvailableText,
    };

    const gymnasticsAdditionalColumns = [{
        headerName: 'Gender',
        field: 'gender',
        tooltipValueGetter: ({ data }) => data.gender,
        valueGetter: ({ data }) => data.gender,
    },
    {
        headerName: 'Institute Name',
        field: 'instituteTypeName',
        tooltipValueGetter: ({ data }) => data.instituteTypeName ?? AppConstants.notAvailableText,
        valueGetter: ({ data }) => data.instituteTypeName ?? AppConstants.notAvailableText,
    },
    {
        headerName: 'Institute Type',
        field: 'instituteType',
        tooltipValueGetter: ({ data }) => data.instituteType ?? AppConstants.notAvailableText,
        valueGetter: ({ data }) => data.instituteType ?? AppConstants.notAvailableText,
    },
    {
        headerName: 'Participation Mode',
        field: 'participationMode',
        tooltipValueGetter: ({ data }) => data.participationMode ?? AppConstants.notAvailableText,
        valueGetter: ({ data }) => data.participationMode ?? AppConstants.notAvailableText,
    },
    ];

    const columnDefsStudentMarksGrid = [
        { field: 'criteriaName', tooltipField: 'criteriaName', headerName: 'Criteria' },
        { field: 'aspectDescription', tooltipField: 'aspectDescription', headerName: 'Aspect', cellStyle: { textAlign: 'left' } },
        { field: 'judgeName', tooltipField: 'judgeName', headerName: 'Judge' },
        { field: 'marksalloted', tooltipField: 'marksalloted', headerName: 'Marks' },
    ];

    const championshipTableColumns = [
        {
            headerName: 'Team Name',
            field: 'instituteName',
            tooltipField: 'instituteName',
        },
        {
            headerName: 'Team Type',
            field: 'instituteType',
            tooltipField: 'instituteType',
        },
        {
            headerName: 'Final Score',
            field: 'finalScore',
            tooltipValueGetter: ({ data }) => data.finalScore ?? AppConstants.notAvailableText,
            valueGetter: ({ data }) => data.finalScore ?? AppConstants.notAvailableText,
        },
        {
            headerName: 'Team Rank',
            field: 'rank',
            tooltipValueGetter: ({ data }) => data.rank ?? AppConstants.notAvailableText,
            valueGetter: ({ data }) => data.rank ?? AppConstants.notAvailableText,
        }
    ];

    //handle click on view button
    async function handleView(params) {
        setViewDetailsSection(AppConstants.trueText);
        //fetch student marks
        const url = `${AppConstants.getSelectedStudentMarksWithParam}${props.competitionDetails.id}${AppConstants.getSelectedStudentMarksSecondParam}${params.studentId}${AppConstants.getSelectedStudentMarksThirdParam}${props.competitionDetails.skillName}`;
        const response = await AuthorizedDataService.getRequest(
            url,
            AppConstants.emptyString,
            AppConstants.emptyString
        );
        if (response !== undefined) {
            setStudentMarksDetails(response);
            setViewDetailsSectionArray(params);
        } else {
            setStudentMarksDetails([]);
        }
    }

    //handle click on back button
    const backToGrid = () => {
        setStudentMarksDetails([]);
        setViewDetailsSection(AppConstants.falseText);
    }

    const getJudgeNames = () => {
        const nameSet = new Set();
        studentMarksDetails.forEach(judge => {
            nameSet.add(judge.judgeName);
        });
        let judgeNames = Array.from(nameSet).join(", ");
        return judgeNames || AppConstants.notAvailableText;
    }

    //download competition result
    async function downloadSummary() {
        setDownloadSummarrySpinner(AppConstants.trueText);
        const url = `${AppConstants.downloadResultSummarySheetAPI}${props.competitionDetails.uniqueId}&skillName=${props.competitionDetails.skillName}`;
        const name = `${props.competitionDetails.title}${AppConstants.hyphen}${AppConstants.competitionResultSummaryFileName}`
        const fileName = name.replace(/\s+/g, AppConstants.hyphen);
        const response = await FileOperationsUtil.downloadFile(
            url,
            fileName,
            AppConstants.emptyString,
            AppConstants.emptyString
        );
        if (response !== undefined) {
            if (response.ok) {
                setDownloadSummarrySpinner(AppConstants.falseText);
            } else {
                setDownloadSummarrySpinner(AppConstants.falseText);
                setDownloadSummaryError(AppConstants.trueText);
                let timer = setTimeout(() => {
                    setDownloadSummaryError(AppConstants.falseText);
                }, AppConstants.messageDisappearTime);
                return () => { clearTimeout(timer) };
            }
        } else {
            setDownloadSummarrySpinner(AppConstants.falseText);
            setDownloadSummaryError(AppConstants.trueText);
            let timer = setTimeout(() => {
                setDownloadSummaryError(AppConstants.falseText);
            }, AppConstants.messageDisappearTime);
            return () => { clearTimeout(timer) };
        }
    }

    //download competition result
    async function downloadResult(params) {
        setDownloadResultSpinner(AppConstants.trueText);
        const url = `${AppConstants.downloadStudentReportSheetAPI}${props.competitionDetails.uniqueId}${AppConstants.downloadCompetitionResultSecondAPI}${viewDetailsSectionArray.studentQrenciaId}&skillName=${props.competitionDetails.skillName}&markedStatus=${params.markedStatus}`
        const name = `${props.competitionDetails.title}${AppConstants.hyphen}${params.studentName}${AppConstants.hyphen}${AppConstants.studentReportSheetDownloadFileName}`
        const fileName = name.replace(/\s+/g, AppConstants.hyphen);
        const response = await FileOperationsUtil.downloadFile(
            url,
            fileName,
            AppConstants.emptyString,
            AppConstants.emptyString
        );
        if (response !== undefined) {
            if (response.ok) {
                setDownloadResultSpinner(AppConstants.falseText);
            } else {
                setDownloadResultSpinner(AppConstants.falseText);
                setDownloadResultError(AppConstants.trueText);
                let timer = setTimeout(() => {
                    setDownloadResultError(AppConstants.falseText);
                }, AppConstants.messageDisappearTime);
                return () => { clearTimeout(timer) };
            }
        } else {
            setDownloadResultSpinner(AppConstants.falseText);
            setDownloadResultError(AppConstants.trueText);
            let timer = setTimeout(() => {
                setDownloadResultError(AppConstants.falseText);
            }, AppConstants.messageDisappearTime);
            return () => { clearTimeout(timer) };
        }
    }

    const downloadCertificate = async (params) => {
        setCertificateSpinner(AppConstants.trueText);
        const url = `${AppConstants.downloadCertificateAPI}${props.competitionDetails.uniqueId}${AppConstants.studentQrenciaIdParam}${params.studentQrenciaId}&skillName=${props.competitionDetails.skillName}&markedStatus=${params.markedStatus}`;
        const name = `${params.competitionTitle}${AppConstants.hyphen}${params.studentName}${AppConstants.hyphen}${AppConstants.certificateFileNameWithPDFExtension}`;
        const fileName = name.replace(/\s+/g, AppConstants.hyphen);
        const response = await FileOperationsUtil.downloadFile(
            url,
            fileName,
            AppConstants.emptyString,
            AppConstants.emptyString
        );
        if (response !== undefined) {
            if (response.ok) {
                setCertificateSpinner(AppConstants.falseText);
            } else {
                setCertificateSpinner(AppConstants.falseText);
                setDownloadCertificateError(AppConstants.trueText);
                let timer = setTimeout(() => {
                    setDownloadCertificateError(AppConstants.falseText);
                }, AppConstants.messageDisappearTime);
                return () => { clearTimeout(timer) };
            }
        } else {
            setCertificateSpinner(AppConstants.falseText);
            setDownloadCertificateError(AppConstants.trueText);
            let timer = setTimeout(() => {
                setDownloadCertificateError(AppConstants.falseText);
            }, AppConstants.messageDisappearTime);
            return () => { clearTimeout(timer) };
        }
    }

    const downloadCertificateZip = async () => {
        setDownloadZipSpinner(true);
        let data = {
            uniqueId: props.competitionDetails.uniqueId,
            skillName: props.competitionDetails.skillName,
            participantDetails: props.resultDetails.map(result => ({
                participantName: result.studentName,
                certificateType: result.certificateType,
                participantRank: result.participantRank,
                userQrenciaId: result.studentQrenciaId,
                studentCompetitionMappingUniqueId: result.studentCompetitionMappingUniqueId,
                marksObtained: result.totalMarks,
                maxMarks: result.maximumMarksCalculated,
                medal: result.medal,
                awardType: result.awardType,
                markedStatus: result.markedStatus
            }))
        }
        let url = `${AppConstants.generateCertificateZipForCompetitionAPI}`;
        const name = `${props.competitionDetails.title}${AppConstants.hyphen}${AppConstants.certificatesZipFileName}`;
        const fileName = name.replace(/\s+/g, AppConstants.hyphen);
        const response = await FileOperationsUtil.downloadFileUsingPostRequest(
            url,
            fileName,
            data,
            AppConstants.emptyString,
            AppConstants.emptyString
        );
        if (response !== undefined) {
            if (response.ok) {
                setDownloadZipSpinner(AppConstants.falseText);
            } else {
                setDownloadZipSpinner(AppConstants.falseText);
                setDownloadZipError(`${ErrorMessages.fileDownloadError}ZIP file.${ErrorMessages.fileDownloadErrorPartTwo}`);
                let timer = setTimeout(() => {
                    setDownloadZipError(AppConstants.emptyString);
                }, AppConstants.messageDisappearTime);
                return () => { clearTimeout(timer) };
            }
        } else {
            setDownloadZipSpinner(AppConstants.falseText);
            setDownloadZipError(`${ErrorMessages.fileDownloadError}ZIP file.${ErrorMessages.fileDownloadErrorPartTwo}`);
            let timer = setTimeout(() => {
                setDownloadZipError(AppConstants.emptyString);
            }, AppConstants.messageDisappearTime);
            return () => { clearTimeout(timer) };
        }
    }


    function downloadSubmission(params) {
        let file = params.submissionFilePath;
        var submissionsWindowName = AppConstants.submissionWindowName + props.competitionDetails.competitionId + AppConstants.submissionWindowNamePartTwo + params.studentQrenciaId;
        let startIndex = file.lastIndexOf(".");
        let endIndex = file.indexOf("?");
        let fileExtension = file.substring(startIndex, endIndex);
        if (fileExtension === '.ogg' || fileExtension === '.avi' || fileExtension === '.mp4' || fileExtension === '.mov' || fileExtension === '.webm') {
            var submissionsWindow = window.open("", AppConstants.openInNewTab, "name=submissions,height=600,width=600");
            // Write the video tag to the new window.
            submissionsWindow.document.write('<video controls="controls" width="600" height="600" name="submission"><source src="' + file + '"></video>');

            // Play the video.
            submissionsWindow.document.querySelector('video').play();
        }
        else {
            var submissionsWindow = window.open(file, AppConstants.openInNewTab, "name=submissions,height=600,width=600");
            submissionsWindow.name = submissionsWindowName;
        }
        submissionsWindow.name = submissionsWindowName;
        submissionsWindow.document.title = submissionsWindowName;
    };

    async function declareCompetitionResults(compData) {
        try {
            setDeclareResultSpinner(AppConstants.trueText);
            let data = {
                uniqueId: compData.uniqueId,
                title: compData.title,
                disablePerformanceResultFlag: compData.disablePerformanceResultFlag,
                templateId: compData.templateId
            };

            const url = AppConstants.declareResultsAPI;
            const response = await AuthorizedDataService.postRequest(
                url,
                data,
                AppConstants.emptyString,
                AppConstants.emptyString
            );

            if (response.ok && response.status === AppConstants.httpResponseCodes.responseCode200) {
                setDeclareResultSpinner(AppConstants.falseText);
                let data = await response.json();

                if (data === 1) {
                    setDeclareResultVarient(AppConstants.alertVarient[0]);
                    setDeclareResultsError(SuccessMessage.competitionResultDeclaredSuccess);
                    props.fetchCompetitionDetails(props.competitionDetails);
                    let timer = setTimeout(() => {
                        setDeclareResultsError(AppConstants.falseText);
                        setDeclareResultVarient(AppConstants.emptyString);
                    }, AppConstants.messageDisappearTime);
                    return () => { clearTimeout(timer) };
                } else if (data === -111) {
                    setDeclareResultVarient(AppConstants.alertVarient[1]);
                    setDeclareResultsError(ErrorMessages.resultsAlreadyDeclaredMessage);
                    let timer = setTimeout(() => {
                        setDeclareResultsError(AppConstants.falseText);
                        setDeclareResultVarient(AppConstants.emptyString);
                    }, AppConstants.messageDisappearTime);
                    return () => { clearTimeout(timer) };
                } else if (data === -113) {
                    setDeclareResultVarient(AppConstants.alertVarient[1]);
                    setDeclareResultsError(ErrorMessages.noStudentEnrolledErrorMessage);
                    let timer = setTimeout(() => {
                        setDeclareResultsError(AppConstants.falseText);
                        setDeclareResultVarient(AppConstants.emptyString);
                    }, AppConstants.messageDisappearTime);
                    return () => { clearTimeout(timer) };
                }

            } else {
                setDeclareResultSpinner(AppConstants.falseText);
                setDeclareResultVarient(AppConstants.alertVarient[1]);
                setDeclareResultsError(ErrorMessages.somethingWentWrongError);
                let timer = setTimeout(() => {
                    setDeclareResultsError(AppConstants.falseText);
                    setDeclareResultVarient(AppConstants.emptyString);
                }, AppConstants.messageDisappearTime);
                return () => { clearTimeout(timer) };
            }
        } catch (error) {
            setDeclareResultSpinner(AppConstants.falseText);
            setDeclareResultVarient(AppConstants.alertVarient[1]);
            setDeclareResultsError(ErrorMessages.somethingWentWrongError);
            let timer = setTimeout(() => {
                setDeclareResultsError(AppConstants.falseText);
            }, AppConstants.messageDisappearTime);
            return () => { clearTimeout(timer) };
        }
    };

    const getSummaryColumnDefs = () => {
        if (props.competitionDetails.skillName?.toLowerCase() === AppConstants.gymnasticsSkillName.toLowerCase()) {
            let updatedColumnDefs = [...columnDefsResultGrid];
            if (props.competitionDetails.awardType === AppConstants.gymnasticsAwardType.medalBased) {
                updatedColumnDefs = updatedColumnDefs.filter(column => column.field !== 'participantRank');
                updatedColumnDefs.splice(3, 0, medalColumn);
            }
            updatedColumnDefs = updatedColumnDefs.filter(column => column.field !== 'judgedBy');
            if (props.competitionDetails.showPercentageFlag === AppConstants.yText) {
                updatedColumnDefs.splice(3, 0, percentageScoreColumn);
            }
            updatedColumnDefs.splice(3, 0, ...gymnasticsAdditionalColumns);

            return updatedColumnDefs;
        } else {
            return columnDefsResultGrid
        }
    };

    return (
        <>
            {!viewDetailsSection &&
                <div style={{ width: '100%' }} className="ag-theme-alpine">
                    <div ref={gridRef} style={{ width: '100%', height: '100%' }}>
                        <div className={classes.agGridMobile}>
                            <AgGridReact
                                headerHeight={AppConstants.gridConstants.headerHeight}
                                rowData={props.resultDetails}
                                defaultColDef={defaultColDef}
                                columnDefs={getSummaryColumnDefs()}
                                enableBrowserTooltips={AppConstants.trueText}
                                domLayout='autoHeight'>
                            </AgGridReact>
                        </div>
                        <div className={classes.agGridDesktop}>
                            <AgGridReact
                                headerHeight={AppConstants.gridConstants.headerHeight}
                                rowData={props.resultDetails}
                                defaultColDef={{ ...defaultColDef, flex: 1 }}
                                columnDefs={getSummaryColumnDefs()}
                                enableBrowserTooltips={AppConstants.trueText}
                                domLayout='autoHeight'>
                            </AgGridReact>
                        </div>

                        {props.competitionTab !== AppConstants.competitionTabHeading[0] && <div className="reportButtonSection">
                            {props.competitionDetails.checkCompetitionRubrics === 1 && <>
                                {props.competitionCompletionStatus !== AppConstants.completitionStatus.incomplete && <Button className="expertViewCompetitionDownloadResultBtn" onClick={() => { downloadSummary() }}>Report {downloadSummarrySpinner && <BiLoaderAlt className="expertSpinnerIcon" />}</Button>}
                                {props.competitionDetails.resultDate && props.competitionDetails.checkCompetitionRubrics === 1 && <div data-tooltip-id="declareResultsInfo"> <Button className="expertViewCompetitionDownloadResultBtn"
                                    disabled={(props.competitionDetails.completionStatus === AppConstants.completitionStatus.incomplete || new Date(DateTimeUtil.utcToLocal(props.competitionDetails.endDateTimeUTC)) > new Date()) || (props.competitionDetails.resultDeclaredFlag !== null && props.competitionDetails.resultDeclaredFlag === AppConstants.yText)} onClick={() => declareCompetitionResults(props.competitionDetails)}>Declare Results {declareResultsSpinner && <BiLoaderAlt className="spinner" />}</Button>
                                </div>}
                            </>}
                            {props.competitionDetails.participantsDetails?.length > 0 && props.competitionDetails.templateId !== 0 && props.competitionDetails.templateId !== AppConstants.nullText && props.competitionCompletionStatus !== AppConstants.completitionStatus.incomplete && <Button disabled={downloadZipSpinner} className="expertViewCompetitionDownloadResultBtn" onClick={() => { downloadCertificateZip(viewDetailsSectionArray) }}>Certificates&nbsp;&nbsp;{downloadZipSpinner ? <BiLoaderAlt className="expertSpinnerIcon" /> : <GrDocumentZip />}</Button>}
                        </div>}
                        {(props.competitionDetails.completionStatus === AppConstants.completitionStatus.incomplete || new Date(DateTimeUtil.utcToLocal(props.competitionDetails.endDateTimeUTC)) > new Date()) && <Tooltip className={classes.tooltipMessage} id="declareResultsInfo"><span className={classes.tooltipMessage}>Declare Results option will be available once the competition is marked as complete.</span></Tooltip>}
                        {(props.competitionDetails.resultDeclaredFlag !== null && props.competitionDetails.resultDeclaredFlag === AppConstants.yText) && <Tooltip className={classes.tooltipMessage} id="declareResultsInfo"><span className={classes.tooltipMessage}>You have already declared the results for this competition.</span></Tooltip>}
                    </div>
                    {props.competitionTab !== AppConstants.competitionTabHeading[0] && <div>
                        {downloadSummaryError && <div className="errorSuccessAlertDeclareResult"><ErrorSuccessAlertMessage message={ErrorMessages.downloadCompetitionSummaryError} varient={AppConstants.alertVarient[1]} /></div>}
                        {declareResultsError && <div className="errorSuccessAlertDeclareResult"><ErrorSuccessAlertMessage message={declareResultsError} varient={declareResultVarient} /></div>}
                        {downloadZipError && <div className="errorSuccessAlertDeclareResult"><ErrorSuccessAlertMessage message={downloadZipError} varient={AppConstants.alertVarient[1]} /></div>}
                    </div>}

                    {championshipDetails.length > 0 && <>
                        <p className={classes.championshipHeading}>Team Championship</p>
                        <AgGridReact
                            headerHeight={AppConstants.gridConstants.headerHeight}
                            rowData={championshipDetails}
                            defaultColDef={{ ...defaultColDef, flex: 1 }}
                            columnDefs={championshipTableColumns}
                            enableBrowserTooltips={AppConstants.trueText}
                            domLayout='autoHeight'>
                        </AgGridReact>
                    </>}
                </div>
            }
            {viewDetailsSection && <div className="expertViewCompetitionResultViewSection">
                <FaArrowLeft className="goBackButton" onClick={() => { backToGrid() }} />
                <div className="row">
                    <div className="col-xs-12 col-sm-6 col-md-6 col-lg-4 col-xl-4 ps-0">
                        <p className="detailsHeading">Participant Qrencia Id</p>
                        <p className="detailsSubHeading">{viewDetailsSectionArray.studentQrenciaId}</p>
                    </div>
                    <div className="col-xs-12 col-sm-6 col-md-6 col-lg-4 col-xl-4 ps-0">
                        <p className="detailsHeading">Participant Name</p>
                        <p className="detailsSubHeading">{viewDetailsSectionArray.studentName}</p>
                    </div>
                </div>
                <div className="row py-2">
                    <div className="col-xs-12 col-sm-6 col-md-6 col-lg-4 col-xl-4 ps-0">
                        <p className="detailsHeading">Competition Id</p>
                        <p className="detailsSubHeading">{props.competitionDetails.competitionId}</p>
                    </div>
                    <div className="col-xs-12 col-sm-6 col-md-6 col-lg-4 col-xl-4 ps-0">
                        <p className="detailsHeading">Competition Name</p>
                        <p className="detailsSubHeading">{props.competitionDetails.title}</p>
                    </div>
                    <div className="col-xs-12 col-sm-6 col-md-6 col-lg-4 col-xl-4 ps-0">
                        <p className="detailsHeading">Start Time</p>
                        <p className="detailsSubHeading"><Moment format={AppConstants.twelveHoursTimeFormat} tz={timeZone}>{props.competitionDetails.startDateTimeUTC.replace(' ', 'T') + 'Z'}</Moment> | <Moment format={AppConstants.competitionDatesNewDateFormat} tz={timeZone}>{props.competitionDetails.startDateTimeUTC.replace(' ', 'T') + 'Z'}</Moment> {timeZone === AppConstants.utcTimezone && `(${props.competitionDetails.preferredTimezone})`}</p>
                    </div>
                    <div className="col-xs-12 col-sm-6 col-md-6 col-lg-4 col-xl-4 ps-0">
                        <p className="detailsHeading">End Time</p>
                        <p className="detailsSubHeading"><Moment format={AppConstants.twelveHoursTimeFormat} tz={timeZone}>{props.competitionDetails.endDateTimeUTC.replace(' ', 'T') + 'Z'}</Moment> | <Moment format={AppConstants.competitionDatesNewDateFormat} tz={timeZone}>{props.competitionDetails.endDateTimeUTC.replace(' ', 'T') + 'Z'}</Moment> {timeZone === AppConstants.utcTimezone && `(${props.competitionDetails.preferredTimezone})`}</p>
                    </div>
                    {props.competitionDetails.checkCompetitionRubrics === 1 && <div className="col-xs-12 col-sm-6 col-md-6 col-lg-4 col-xl-4 ps-0">
                        <p className="detailsHeading">{props.competitionDetails.skillName === AppConstants.gymnasticsSkillName ? "Scorer" : "Judged By"}</p>
                        <p className="detailsSubHeading">{getJudgeNames()}</p>
                    </div>}
                </div>
                {props.competitionDetails.checkCompetitionRubrics === 1 && props.competitionDetails.skillName !== AppConstants.gymnasticsSkillName && <>
                    <div className={`ag-theme-alpine ${classes.agGridMobile}`}>
                        <AgGridReact
                            headerHeight={AppConstants.gridConstants.headerHeight}
                            defaultColDef={defaultColDef}
                            rowData={studentMarksDetails}
                            columnDefs={columnDefsStudentMarksGrid}
                            enableBrowserTooltips={AppConstants.trueText}
                            domLayout='autoHeight'>
                        </AgGridReact>
                    </div>
                    <div className={`ag-theme-alpine ${classes.agGridDesktop}`}>
                        <AgGridReact
                            headerHeight={AppConstants.gridConstants.headerHeight}
                            defaultColDef={{ ...defaultColDef, flex: 1 }}
                            rowData={studentMarksDetails}
                            columnDefs={columnDefsStudentMarksGrid}
                            enableBrowserTooltips={AppConstants.trueText}
                            domLayout='autoHeight'>
                        </AgGridReact>
                    </div>
                </>}
                {props.competitionDetails.checkCompetitionRubrics === 1 && props.competitionDetails.skillName === AppConstants.gymnasticsSkillName && <GymnasticsViewParticipantMarking studentMarksDetails={studentMarksDetails} />}
                {viewDetailsSectionArray.submissionFilePath && <Button className="expertViewCompetitionDownloadCertificateBtn" onClick={() => { downloadSubmission(viewDetailsSectionArray) }}>Submission <BiLinkExternal /></Button>}
                {viewDetailsSectionArray.templateId !== 0 && viewDetailsSectionArray.templateId !== AppConstants.nullText && props.competitionCompletionStatus !== AppConstants.completitionStatus.incomplete && <Button className="expertViewCompetitionDownloadCertificateBtn" onClick={() => { downloadCertificate(viewDetailsSectionArray) }}>Certificate {certificateSpinner ? <BiLoaderAlt className="expertSpinnerIcon" /> : <FiDownloadCloud />}</Button>}
                {props.competitionCompletionStatus !== AppConstants.completitionStatus.incomplete && props.competitionDetails.checkCompetitionRubrics === 1 && <Button className="expertViewCompetitionDownloadCertificateBtn" onClick={() => { downloadResult(viewDetailsSectionArray) }}>Result {downloadResultSpinner ? <BiLoaderAlt className="expertSpinnerIcon" /> : <FiDownloadCloud />}</Button>}
                {downloadResultError && <div className="errorSuccessAlertDownloadStudentResult"><ErrorSuccessAlertMessage message={ErrorMessages.downloadCompetitionResultError} varient={AppConstants.alertVarient[1]} /></div>}
                {downloadCertificateError && <div className="errorSuccessAlertDownloadStudentResult"><ErrorSuccessAlertMessage message={ErrorMessages.downloadIndividualCertificateError} varient={AppConstants.alertVarient[1]} /></div>}
            </div>}
        </>
    )
}
