import { Table } from "react-bootstrap";
import classes from "./ScoreboardTable.module.css";

export const ChampionshipTable = ({ championshipDetails }) => {
    return (
        <div>
            <Table bordered className={`${classes.table} mb-4`}>
                <thead className={classes.tableHead}>
                    <tr className={classes.tableHeadings}>
                        <th className={classes.tableData}>Institute Name</th>
                        <th className={classes.tableData}>Institute Type</th>
                        <th className={classes.tableData}>Final Score</th>
                        <th className={classes.tableData}>Rank</th>
                    </tr>
                </thead>
                <tbody className={classes.tableBody}>
                    {championshipDetails.map((record, index) => (
                        <tr key={index} className={classes.tableBodyRow}>
                            <td>{record.instituteName}</td>
                            <td>{record.instituteType}</td>
                            <td>{record.finalScore}</td>
                            <td>{record.rank}</td>
                        </tr>
                    ))}
                </tbody>
            </Table>
        </div>
    )
}