import { RouterProvider, createBrowserRouter } from 'react-router-dom';
import Auth from './utils/Auth';
import { SkillsPage } from './pages/unauthorised/SkillsPage';
import { ErrorPage } from './pages/unauthorised/ErrorPage';
import { AppConstants } from './constants/AppConstants';
import { DataService } from './services/DataService';
import { LandingPage } from './pages/unauthorised/LandingPage';
import { IndividualQtalksPage } from './pages/unauthorised/IndividualQtalksPage';
import { IndividualSkillsPage } from './pages/unauthorised/IndividualSkillsPage';
import { ExpertTemplate } from './pages/expert/ExpertTemplate';
import { ExpertHomePage } from './pages/expert/ExpertHomePage';
import { MyStudentsExpertTemplatePage } from './pages/expert/MyStudentsExpertTemplatePage';
import { MyTeachingExpertTemplatePage } from './pages/expert/MyTeachingExpertTemplatePage';
import { SignupPage } from './pages/unauthorised/SignupPage';
import { ExpertCreateCompetitionsPage } from './pages/expert/ExpertCreateCompetitionsPage';
import { ExpertManageCompetitionsPage } from './pages/expert/ExpertManageCompetitionsPage';
import { ExpertJudgeCompetitionsPage } from './pages/expert/ExpertJudgeCompetitionsPage';
import { ExpertCreateCoursePage } from './pages/expert/ExpertCreateCoursePage';
import { ExpertManageCoursesPage } from './pages/expert/ExpertManageCoursesPage';
import { ExpertNudgesPage } from './pages/expert/ExpertNudgesPage';
import { CreateWorkshopPage } from './pages/expert/CreateWorkshopPage';
import { ManageWorkshopPage } from './pages/expert/ManageWorkshopPage';
import { PublishUnpublishWorkshops } from './pages/expert/PublishUnpublishWorkshops';
import { ExpertMySchoolPage } from './pages/expert/ExpertMySchoolPage';
import { AdminTemplate } from './pages/admin/AdminTemplate';
import { AdminDashboardPage } from './pages/admin/AdminDashboardPage';
import { AdminNumberTrackingPage } from './pages/admin/AdminNumberTrackingPage';
import { AdminAIFeedbackPage } from './pages/admin/AdminAIFeedbackPage';
import { AdminManageAdminUserPage } from './pages/admin/AdminManageAdminUserPage';
import { AdminManageExpertUserPage } from './pages/admin/AdminManageExpertUserPage';
import { AdminManageStudentSubscriptionPage } from './pages/admin/AdminManageStudentSubscriptionPage';
import { AdminManageContentPage } from './pages/admin/AdminManageContentPage';
import { AdminCreateQtalksPage } from './pages/admin/AdminCreateQtalksPage';
import { AdminManageQtalksPage } from './pages/admin/AdminManageQtalksPage';
import { AdminCompetitionsPage } from './pages/admin/AdminCompetitionsPage';
import { AdminSkillLabsPage } from './pages/admin/AdminSkillLabsPage';
import { AdminWorkshopsPage } from './pages/admin/AdminWorkshopsPage';
import { AdminClubQrenciaPage } from './pages/admin/AdminClubQrenciaPage';
import { AdminReferralsAndRewardsPage } from './pages/admin/AdminReferralsAndRewardsPage';
import Login from './common/Login';
import ResetPassword from "./common/ResetPassword";
import PublicRoutes from './common/PublicRoutes';
import PrivateRoutes from './common/PrivateRoutes';
import { PrivacyPolicyPage } from './pages/unauthorised/PrivacyPolicyPage';
import { TermsAndConditionsPage } from './pages/unauthorised/TermsAndConditionsPage';
import { RefundPolicyPage } from './pages/unauthorised/RefundPolicyPage';
import { StudentTemplate } from './pages/student/StudentTemplate';
import { StudentCompleteProfilePage } from './pages/student/studentAdvanceProfile/StudentCompleteProfilePage';
import { StudentMyLearningPage } from './pages/student/StudentMyLearningPage';
import { StudentMyAnalysisPage } from './pages/student/StudentMyAnalysisPage';
import { StudentHomePage } from './pages/student/StudentHomePage';
import { StudentCompetitionsPage } from './pages/student/StudentCompetitionsPage';
import { StudentCoursesPage } from './pages/student/StudentCoursesPage';
import { StudentMentorMePage } from './pages/student/StudentMentorMePage';
import { StudentExploreSkillPage } from './pages/student/StudentExploreSkillPage';
import { StudentWorkshopsPage } from './pages/student/StudentWorkshopsPage';
import { StudentQtalksPage } from './pages/student/StudentQtalksPath';
import { StudentMySchoolPage } from './pages/student/StudentMySchoolPage';
import { IndividualCompetitionPage } from './pages/unauthorised/individualCompetitionPage';
import { ExpertMicrositePage } from './pages/unauthorised/ExpertMicrositePage';
import { ExpertJudgingScreenPage } from './pages/unauthorised/ExpertJudgingScreenPage';
import { ExpertMarkParticipantsOnJudgingScreenPage } from './pages/unauthorised/ExpertMarkParticipantsOnJudgingScreenPage';
import { StudentNudgesPage } from './pages/student/StudentNudgesPage';
import { ExpertBulkMessagingPage } from "./pages/expert/ExpertBulkMessagingPage";
import { ExpertOnboardingStatusPage } from './pages/expert/ExpertOnboardingStatusPage';
import { StudentPerformanceReportPage } from './pages/student/StudentPerformanceReportPage';
import { ExpertPublishUnpublishCourse } from './pages/expert/ExpertPublishUnpublishCourse';
import { DedicatedCoursPage } from './pages/unauthorised/DedicatedCoursePage';
import { ApproveFeeWaivedCompetition } from './pages/admin/ApproveFeeWaivedCompetition'
import { SignupAndEnrolPage } from './common/SignupAndEnrolPage';
import { ExpressionOfIntrestPage } from './pages/expert/ExpressionOfInterestPage';
import { ExpertBulkEnrolPage } from './pages/expert/ExpertBulkEnrolPage';
import { CertificateVerificationPage } from './pages/unauthorised/CertificateVerificationPage';
import { MyStudentsAgencyTemplatePage } from './pages/agency/MyStudentsAgencyTemplatePage';
import { MyTeachingAgencyTemplatePage } from './pages/agency/MyTeachingAgencyTemplatePage';
import { AgencyTemplate } from './pages/agency/AgencyTemplate';
import { AgencyHomePage } from './pages/agency/AgencyHomePage';
import { AuthorizedDataService } from './services/AuthorizedDataService';
import { AgencyOnboardingStatusPage } from './pages/agency/AgencyOnboardingStatusPage';
import { AgencyMicrositePage } from './pages/unauthorised/AgencyMicrositePage';
import { AgencyMapStudentsPage } from './pages/agency/AgencyMapStudentsPage';
import { AgencyMapExpertsPage } from './pages/agency/AgencyMapExpertsPage';
import { ExpertUpdateInvitationStatus } from './component/schoolExpertMapping/ExpertUpdateInvitationStatus';
import { MonitorLearningActivitiesPage } from './pages/agency/MonitorLearningActivitiesPage';
import { SignupAndLinkToSchoolPage } from './common/SignupAndLinkToSchoolPage';
import { SelectionProvider } from '../src/context/SelectionContext';
import { QuizEventPage } from './pages/unauthorised/QuizEventPage';
import { IndividualWorkshopPage } from './pages/unauthorised/IndividualWorkshopPage';
import { ExpertListingPage } from './pages/unauthorised/ExpertListingPage';
import { ExpressionOfInterestThankyouPage } from './pages/unauthorised/ExpressionOfInterestThankyouPage';
import { SalesTemplate } from './pages/sales/SalesTemplate';
import { SalesManageLeadsPage } from './pages/admin/SalesManageLeadsPage';
import { AdminBulkEnrolPage } from './pages/admin/AdminBulkEnrolPage';
import { AdminBulkSignupPage } from './pages/admin/AdminBulkSignupPage';
import { MarketingTemplate } from './pages/marketing/MarketingTemplate';
import { NudgesPage } from './pages/unauthorised/NudgesPage';
import { MarkAttendanceScreen } from './component/markAttendanceComponent/markAttendanceScreen/MarkAttendanceScreen';
import { MarkSpecificCourseAttendance } from './component/markAttendanceComponent/markSpecificCourseAttendance/MarkSpecificCourseAttendance';
import { PendingEnrolmentRequestPage } from './pages/expert/PendingEnrolmentRequestPage';
import { OnboardingWorkshopPage } from './pages/unauthorised/OnboardingWorkshopPage';
import { PartnerSuccessTemplate } from './pages/partnerSuccess/PartnerSuccessTemplate';
import { CompetitionsPage } from './pages/unauthorised/CompetitionsPage';
import { CourseListingPage } from './pages/unauthorised/CourseListingPage';
import { WorkshopListingPage } from './pages/unauthorised/WorkshopListingPage';
import { DateTimeUtil } from "./utils/DateTimeUtil";
import { BulkUploadCreatorLeads } from './pages/sales/BulkUploadCreatorLeads';
import { QuizListingPage } from './pages/unauthorised/QuizListingPage';
import { ShareCourseFeedback } from './component/shareCourseFeedback/ShareCourseFeedback';
import { WelcomeExpertPage } from './pages/unauthorised/WelcomeExpertPage';
import { StudentUpdateProfilePage } from './pages/student/studentAdvanceProfile/StudentUpdateProfilePage';
import { RespondNudgesPage } from './pages/expert/RespondNudgesPage';
import { AgencyAssignCoursesPage } from './pages/agency/AgencyAssignCoursesPage';
import { ExpertAssignedCoursesPage } from './pages/expert/ExpertAssignedCoursePage';
import { CompetitionScoreboard } from './pages/unauthorised/CompetitionScoreboard';


//Loader Function for Landing Page
async function landingPageContentLoader() {
  const url = AppConstants.landingPageDataAPI;
  const response = await DataService.get(
    url,
    AppConstants.emptyString,
    AppConstants.emptyString
  );

  return response;
}

//Loader Function for Templates
async function FooterSectionLoader() {
  const url = AppConstants.landingPageDataAPI;
  const response = await DataService.get(
    url,
    AppConstants.emptyString,
    AppConstants.emptyString
  );

  return response;
}

//Loader Function to fetch All skills Data
async function skillsLoader() {
  const url = AppConstants.getSkillsDataAPI;
  const response = await DataService.get(
    url,
    AppConstants.emptyString,
    AppConstants.emptyString
  );

  return response;
};

//Loader Function to get banner image of All Skills Page
async function allSkillsBannerLoader() {
  const url = `${AppConstants.getPageContentAPI}/${AppConstants.allSkillsPageName}`
  const response = await DataService.get(
    url,
    AppConstants.emptyString,
    AppConstants.emptyString
  );
  return response;
}

//Loader Function to get the learning activity dropdown values on the landing page top navigation
async function getLaDropdownOptionsLoader() {
  const url = `${AppConstants.getPageContentAPI}/${AppConstants.laDropdownValues}`
  const response = await DataService.get(
    url,
    AppConstants.emptyString,
    AppConstants.emptyString
  );
  return response;
}

//Loader function details for nudge page
async function nudgePageContentLoader() {
  const url = `${AppConstants.pageSpecificContentAPI}${AppConstants.nudgePageName}`;
  const response = await DataService.get(
    url,
    AppConstants.emptyString,
    AppConstants.emptyString
  );

  return response;
}

//Loader Function to get banner image of Individual Skill Page
async function individuakSkillBannerLoader() {
  const url = AppConstants.getIndividualSkillBanner;
  const response = await DataService.get(
    url,
    AppConstants.emptyString,
    AppConstants.emptyString
  );
  return response;
}

//Loader Function to get banner image of Individual Skill Page
async function individuakSkillFooterLoader() {
  const url = AppConstants.getIndividualSkillFooterSection;
  const response = await DataService.get(
    url,
    AppConstants.emptyString,
    AppConstants.emptyString
  );
  return response;
}

//Loader Function for page-specific static content
async function pageSpecificStaticContentLoader() {
  const url = AppConstants.pageSpecificContentAPI + AppConstants.loginPageName;
  const response = await DataService.get(
    url,
    AppConstants.emptyString,
    AppConstants.emptyString
  );
  return response;
}

//Loader Function for Signup Page
async function SignupPageContentLoader() {
  const url = AppConstants.getSignupPagespecificContentAPI;
  const response = await DataService.get(
    url,
    AppConstants.emptyString,
    AppConstants.emptyString
  );

  return response;
}
//Loader Function for page-specific(expert judging screen) static content
async function expertJudgingScreenDesktopContentLoader() {
  const url = AppConstants.pageSpecificContentAPI + AppConstants.judgingScreenDesktopPageName;
  const response = await DataService.get(
    url,
    AppConstants.emptyString,
    AppConstants.emptyString
  );
  return response;
}

//Loader Function for page-specific(individual skill page 3 pointers title) static content
async function knowMorAboutSkillHeadingLoader() {
  const url = AppConstants.pageSpecificContentAPI + AppConstants.individualSkillPageKeyHighlightsHeading;
  const response = await DataService.get(
    url,
    AppConstants.emptyString,
    AppConstants.emptyString
  );
  return response;
}

//Loader Function for page-specific(expert judging screen) static content
async function expertJudgingScreenTabletContentLoader() {
  const url = AppConstants.pageSpecificContentAPI + AppConstants.judgingScreenTabletPageName;
  const response = await DataService.get(
    url,
    AppConstants.emptyString,
    AppConstants.emptyString
  );
  return response;
}

//Loader Function for page-specific(expert judging screen) static content
async function expertJudgingScreenMobileContentLoader() {
  const url = AppConstants.pageSpecificContentAPI + AppConstants.judgingScreenMobilePageName;
  const response = await DataService.get(
    url,
    AppConstants.emptyString,
    AppConstants.emptyString
  );
  return response;
}

//Loader Function for page-specific(expert obr status page) static content
async function expertObrDesktopPageContentLoader() {
  const url = AppConstants.pageSpecificContentAPI + AppConstants.expertObrDesktopPageName;
  const response = await DataService.get(
    url,
    AppConstants.emptyString,
    AppConstants.emptyString
  );
  return response;
}


//Loader Function for page-specific(expert obr status page) static content
async function expertObrTabletPageContentLoader() {
  const url = AppConstants.pageSpecificContentAPI + AppConstants.expertObrTabletPageName;
  const response = await DataService.get(
    url,
    AppConstants.emptyString,
    AppConstants.emptyString
  );
  return response;
}

//Loader Function for page-specific(expert obr status screen) static content
async function expertObrMobilePageContentLoader() {
  const url = AppConstants.pageSpecificContentAPI + AppConstants.expertObrMobilePageName;
  const response = await DataService.get(
    url,
    AppConstants.emptyString,
    AppConstants.emptyString
  );
  return response;
}


//Loader Function for page-specific(agency obr status page) static content
async function agencyObrDesktopPageContentLoader() {
  const url = AppConstants.pageSpecificContentAPI + AppConstants.agencyObrDesktopPageName;
  const response = await DataService.get(
    url,
    AppConstants.emptyString,
    AppConstants.emptyString
  );
  return response;
}


//Loader Function for page-specific(agency obr status page) static content
async function agencyObrTabletPageContentLoader() {
  const url = AppConstants.pageSpecificContentAPI + AppConstants.agencyObrTabletPageName;
  const response = await DataService.get(
    url,
    AppConstants.emptyString,
    AppConstants.emptyString
  );
  return response;
}

//Loader Function for page-specific(expert obr status screen) static content
async function agencyObrMobilePageContentLoader() {
  const url = AppConstants.pageSpecificContentAPI + AppConstants.agencyObrMobilePageName;
  const response = await DataService.get(
    url,
    AppConstants.emptyString,
    AppConstants.emptyString
  );
  return response;
}

//Loader Function for page-specific(expert listing page banner) static content
async function expertPageBannerLoader() {
  const url = `${AppConstants.getPageContentAPI}/${AppConstants.expertPageName}`
  const response = await DataService.get(
    url,
    AppConstants.emptyString,
    AppConstants.emptyString
  );

  return response;
}

async function quizPageBannerLoader() {
  const url = `${AppConstants.getPageContentAPI}/${AppConstants.quizPageName}`
  const response = await DataService.get(
    url,
    AppConstants.emptyString,
    AppConstants.emptyString
  );

  return response;
}

async function expertWelcomePageBannerLoader() {
  const url = `${AppConstants.getPageContentAPI}/${AppConstants.expertWelcomePageName}`
  const response = await DataService.get(
    url,
    AppConstants.emptyString,
    AppConstants.emptyString
  );

  return response;
}

//Loader Function to get all the activities for landing page skill develop form
async function activityListLoader() {
  const url = AppConstants.getAllActivitiesAPI;
  const response = await DataService.get(
    url,
    AppConstants.emptyString,
    AppConstants.emptyString
  );
  return response;
}

//Loader function for all skills page - combines data from all loaders functions and returns in array
const competitionsPageLoader = async () => {
  return Promise.all([landingPageContentLoader(), skillsLoader(), competitionSectionLoader(), expertPageBannerLoader()]);
};

const coursePageLoader = async () => {
  return Promise.all([landingPageContentLoader(), skillsLoader(), courseSectionLoader(), expertPageBannerLoader()]);
};

const workshopPageLoader = async () => {
  return Promise.all([landingPageContentLoader(), skillsLoader(), workshopSectionLoader(), expertPageBannerLoader()]);
};

//Loader function for all skills page - combines data from all loaders functions and returns in array
const skillsPageLoader = async () => {
  return Promise.all([landingPageContentLoader(), skillsLoader(), allSkillsBannerLoader()]);
};

//Loader function for all skills page - combines data from all loaders functions and returns in array
const expertsPageLoader = async () => {
  return Promise.all([landingPageContentLoader(), skillsLoader(), expertsSectionLoader(), expertPageBannerLoader()]);
};

const quizListingPageLoader = async () => {
  return Promise.all([landingPageContentLoader(), quizPageBannerLoader()]);
};

const expertWelcomePageLoader = async () => {
  return Promise.all([landingPageContentLoader(), expertWelcomePageBannerLoader()]);
};


//Loader Function for Landing Page
const landingPageLoader = async () => {
  return Promise.all([landingPageContentLoader(), qTalksLoader(), activityListLoader(), getLaDropdownOptionsLoader()])
};

const signupPageLoader = async () => {
  return Promise.all([SignupPageContentLoader()]);
}

//Loader Function for Judging Screen Page
const judgingScreenPageLoader = async () => {
  return Promise.all([FooterSectionLoader(), expertJudgingScreenDesktopContentLoader(), expertJudgingScreenTabletContentLoader(), expertJudgingScreenMobileContentLoader()])
};

const getExpertAssociationStatusWithSchool = async () => {
  const url = "checkLoggedInExpertIsAssociatedToSchool";
  const response = await AuthorizedDataService.getRequest(
    url,
    AppConstants.emptyString,
    AppConstants.emptyString
  );

  return response;
}

//Loader Function for Agency Template
const agencyPageLoader = async () => {
  return Promise.all([FooterSectionLoader(), getClubQrenciaAssociationStatus(), getUserEligibilityForNudges()])
};

const adminPageLoader = async () => {
  return Promise.all([FooterSectionLoader(), getUserEligibilityForNudges()])
};

// Loader function for expert template
const expertPageLoader = async () => {
  return Promise.all([FooterSectionLoader(), getExpertAssociationStatusWithSchool()]);
}

// Experts section loader
async function expertsSectionLoader() {
  const url = "getActiveQrenciaCreators";
  let response;

  if (Auth.isLogin()) {
    response = await AuthorizedDataService.getRequest(
      url,
      AppConstants.emptyString,
      AppConstants.emptyString
    );
  } else {
    response = await DataService.get(
      url,
      AppConstants.emptyString,
      AppConstants.emptyString
    );
  }

  return response || [];
}

// competitions section loader
async function competitionSectionLoader() {
  const url = "getUpcomingPublicCompetitionList";
  let response;

  if (Auth.isLogin()) {
    response = await AuthorizedDataService.getRequest(
      url,
      AppConstants.emptyString,
      AppConstants.emptyString
    );
  } else {
    response = await DataService.get(
      url,
      AppConstants.emptyString,
      AppConstants.emptyString
    );
  }

  return response || [];
}

// courses section loader
async function courseSectionLoader() {
  const timeZone = DateTimeUtil.getPreferredTimeZoneForUser();
  const requestHeaders = {
    preferredTimezone: timeZone
  }
  const url = "getUpcomingPublicCourseList";
  let response;

  if (Auth.isLogin()) {
    response = await AuthorizedDataService.getRequest(
      url,
      AppConstants.emptyString,
      AppConstants.emptyString,
      requestHeaders
    );
  } else {
    response = await DataService.get(
      url,
      AppConstants.emptyString,
      AppConstants.emptyString,
      requestHeaders
    );
  }

  return response || [];
}
// workshops section loader

async function workshopSectionLoader() {
  const url = "getUpcomingPublicWorkshopList";
  let response;

  if (Auth.isLogin()) {
    response = await AuthorizedDataService.getRequest(
      url,
      AppConstants.emptyString,
      AppConstants.emptyString
    );
  } else {
    response = await DataService.get(
      url,
      AppConstants.emptyString,
      AppConstants.emptyString
    );
  }

  return response || [];
}

//Loader Function to fetch All Qtalks Data
async function qTalksLoader() {
  const url = AppConstants.getAllQtalksDataAPI;
  const response = await DataService.get(
    url,
    AppConstants.emptyString,
    AppConstants.emptyString
  );

  return response;
};

//Loader Function to fetch privacy policy content
async function privacyPolicyContentLoader() {
  const url = AppConstants.getPrivacyPolicyPageContent;
  const response = await DataService.get(
    url,
    AppConstants.emptyString,
    AppConstants.emptyString
  );

  return response;
};

//Loader Function to fetch terms and conditions content
async function termsAndConditionsPageContentLoader() {
  const url = AppConstants.getTermsAndConditionsPageContent;
  const response = await DataService.get(
    url,
    AppConstants.emptyString,
    AppConstants.emptyString
  );

  return response;
};


//Loader Function to fetch terms and conditions content
async function refundPolicyPageContentLoader() {
  const url = AppConstants.getRefundPolicyPageContent;
  const response = await DataService.get(
    url,
    AppConstants.emptyString,
    AppConstants.emptyString
  );
  return response;
};


async function getClubQrenciaAssociationStatus() {
  const url = AppConstants.getLoggedInAgencyTypeAPI;
  const response = await AuthorizedDataService.getRequest(
    url,
    AppConstants.emptyString,
    AppConstants.emptyString
  );

  return response;
};

async function getUserEligibilityForNudges() {
  const url = AppConstants.checkUserEligibilityForNudgesAPI;
  const response = await AuthorizedDataService.getRequest(
    url,
    AppConstants.emptyString,
    AppConstants.emptyString
  );

  return response;
};


//Loader Function for page-specific(individual skill page activities section) static content
async function IndividualSkillActivitySectionContentLoader() {
  const url = AppConstants.pageSpecificContentAPI + AppConstants.individualSkillPageActivitySection;
  const response = await DataService.get(
    url,
    AppConstants.emptyString,
    AppConstants.emptyString
  );
  return response;
}

async function eoiPageContentLoader() {
  const url = AppConstants.pageSpecificContentAPI + AppConstants.eoiPageName;
  const response = await DataService.get(
    url,
    AppConstants.emptyString,
    AppConstants.emptyString
  );
  return response;
}

async function eoiCarouselLoader() {
  const url = AppConstants.pageSpecificContentAPI + AppConstants.eoiCarouselPage;
  const response = await DataService.get(
    url,
    AppConstants.emptyString,
    AppConstants.emptyString
  );
  return response;
}

//Loader function for privacy-policy page
const privacyPolicyPageLoader = async () => {
  return Promise.all([landingPageContentLoader(), privacyPolicyContentLoader()]);
};

//Loader function for terms and condition page
const termsAndConditionsPageLoader = async () => {
  return Promise.all([landingPageContentLoader(), termsAndConditionsPageContentLoader()]);
};

//Loader function for individual skills page - combines data from all loaders functions and returns in array
const individualSkillsPageLoader = async () => {
  return Promise.all([landingPageContentLoader(), skillsLoader(), individuakSkillBannerLoader(), individuakSkillFooterLoader(), knowMorAboutSkillHeadingLoader(), IndividualSkillActivitySectionContentLoader(), activityListLoader()]);
};

//Loader function for individual competition page
const individualCompetitionPageLoader = async () => {
  return Promise.all([landingPageContentLoader(), skillsLoader(), individuakSkillBannerLoader(), individuakSkillFooterLoader()]);
};

const loginPageLoader = async () => {
  return Promise.all([pageSpecificStaticContentLoader()]);
};
const dedicatedCoursePageLoader = async () => {
  return Promise.all([landingPageContentLoader()]);
};

const eoiPageLoader = async () => {
  return Promise.all([landingPageContentLoader(), eoiPageContentLoader(), eoiCarouselLoader()]);
};

const nudgePageLoader = async () => {
  return Promise.all([landingPageContentLoader(), nudgePageContentLoader()])
};

const expertObrPageLoader = async () => {
  return Promise.all([landingPageContentLoader(), expertObrDesktopPageContentLoader(), expertObrTabletPageContentLoader(), expertObrMobilePageContentLoader()]);
}


//Loader function for refund policy page
const refundPolicyPageLoader = async () => {
  return Promise.all([landingPageContentLoader(), refundPolicyPageContentLoader()]);
};


const agencyObrPageLoader = async () => {
  return Promise.all([landingPageContentLoader(), agencyObrDesktopPageContentLoader(), agencyObrTabletPageContentLoader(), agencyObrMobilePageContentLoader()]);
}

const router = createBrowserRouter([
  {
    path: AppConstants.forwardSlash,
    element: <LandingPage />,
    loader: landingPageLoader
  },
  {
    path: AppConstants.studentUpdateProfilePagePath,
    element: <PrivateRoutes roles={AppConstants.userRoleStudent}><StudentUpdateProfilePage /></PrivateRoutes>
  },
  {
    path: AppConstants.StudentCompleteProfilePagePath,
    element: <PrivateRoutes roles={AppConstants.userRoleStudent}><StudentCompleteProfilePage /></PrivateRoutes>
  },
  {
    path: AppConstants.loginPageUrl,
    element: <PublicRoutes restricted="true"><Login /></PublicRoutes>,
    errorElement: <ErrorPage />,
    loader: loginPageLoader
  },
  {
    path: AppConstants.skills,
    element: <SkillsPage />,
    loader: skillsPageLoader
  },
  {
    path: AppConstants.skillCategoryRoute,
    element: <SkillsPage />,
    errorElement: <ErrorPage />,
    loader: skillsPageLoader
  },
  {
    path: AppConstants.expertPageRoute,
    element: <ExpertListingPage />,
    loader: expertsPageLoader
  },
  {
    path: AppConstants.competitionsPageRoute,
    element: <CompetitionsPage />,
    loader: competitionsPageLoader
  },
  {
    path: AppConstants.courseListingPageRoute,
    element: <CourseListingPage />,
    loader: coursePageLoader
  },
  {
    path: AppConstants.workshopListingPageRoute,
    element: <WorkshopListingPage />,
    loader: workshopPageLoader
  },
  {
    path: AppConstants.expertPageSkillCategoryRoute,
    element: <ExpertListingPage />,
    errorElement: <ErrorPage />,
    loader: expertsPageLoader
  },
  {
    path: AppConstants.individualSkillsRoute,
    element: <IndividualSkillsPage />,
    errorElement: <ErrorPage />,
    loader: individualSkillsPageLoader
  },
  {
    path: AppConstants.individualQtalksRoute,
    element: <IndividualQtalksPage />,
    loader: landingPageLoader
  },
  {
    path: AppConstants.asterisk,
    element: <ErrorPage />
  },
  {
    path: AppConstants.privacyPolicy,
    element: <PrivacyPolicyPage />,
    loader: privacyPolicyPageLoader
  },
  {
    path: AppConstants.termsAndConditions,
    element: <TermsAndConditionsPage />,
    loader: termsAndConditionsPageLoader
  },
  {
    path: AppConstants.refundPolicyPage,
    element: <RefundPolicyPage />,
    loader: refundPolicyPageLoader
  },
  {
    path: AppConstants.signup,
    element: <PublicRoutes restricted="true"><SignupPage /></PublicRoutes>,
    loader: signupPageLoader
  },
  {
    path: AppConstants.signupAndEnrolPageRoute,
    element: <PublicRoutes restricted="true"><SignupAndEnrolPage /></PublicRoutes>,
    errorElement: <ErrorPage />,
    loader: signupPageLoader
  },
  {
    path: AppConstants.signupAndLinkToSchoolPageRoute,
    element: <PublicRoutes restricted="true"><SignupAndLinkToSchoolPage /></PublicRoutes>,
    errorElement: <ErrorPage />,
    loader: signupPageLoader
  },
  {
    path: AppConstants.resetPasswordUrl,
    element: <PublicRoutes restricted="true"><ResetPassword /></PublicRoutes>,
    errorElement: <ErrorPage />,
    loader: loginPageLoader
  },
  {
    path: AppConstants.individualCompetitionsRoute,
    element: <IndividualCompetitionPage />,
    errorElement: <ErrorPage />,
    loader: individualCompetitionPageLoader
  },
  {
    path: AppConstants.feeWaivedCompetitionRoute,
    element: <ApproveFeeWaivedCompetition />,
    errorElement: <ErrorPage />,
    loader: individualCompetitionPageLoader
  },
  {
    path: AppConstants.expertUpdateInvitationStatusPath,
    element: <ExpertUpdateInvitationStatus />,
    errorElement: <ErrorPage />,
  },
  {
    path: AppConstants.expertMicrositeRoute,
    element: <ExpertMicrositePage />,
    errorElement: <ErrorPage />,
    loader: landingPageContentLoader
  },
  {
    path: AppConstants.agencyMicrositeRoute,
    element: <AgencyMicrositePage />,
    errorElement: <ErrorPage />,
    loader: landingPageContentLoader
  },
  {
    path: AppConstants.dedicatedCoursePageRoute,
    element: <DedicatedCoursPage />,
    errorElement: <ErrorPage />,
    loader: dedicatedCoursePageLoader
  },
  {
    path: AppConstants.individualWorkshopPageRoute,
    element: <IndividualWorkshopPage />,
    errorElement: <ErrorPage />,
    loader: dedicatedCoursePageLoader
  },
  {
    path: AppConstants.expertOnboardingStatusRoute,
    element: <PublicRoutes restricted="true"><ExpertOnboardingStatusPage /> </PublicRoutes>,
    errorElement: <ErrorPage />,
    loader: expertObrPageLoader
  },
  {
    path: AppConstants.agencyOnboardingStatusRoute,
    element: <PublicRoutes restricted="true"><AgencyOnboardingStatusPage /> </PublicRoutes>,
    errorElement: <ErrorPage />,
    loader: agencyObrPageLoader
  },
  {
    path: AppConstants.expressionOfInterestPagePath,
    element: <PublicRoutes restricted={false}><ExpressionOfIntrestPage /> </PublicRoutes>,
    errorElement: <ErrorPage />,
    loader: eoiPageLoader
  },
  {
    path: AppConstants.competitionScoreboardRoute,
    element: <PublicRoutes restricted={false}><CompetitionScoreboard /> </PublicRoutes>,
    errorElement: <ErrorPage />,
    loader: landingPageContentLoader
  },
  {
    path: AppConstants.expressionOfInterestThankyouRoute,
    element: <PublicRoutes restricted={false}><ExpressionOfInterestThankyouPage /> </PublicRoutes>,
    errorElement: <ErrorPage />,
    loader: eoiPageLoader
  },
  {
    path: AppConstants.certificateVerificationPageRoute,
    element: <PublicRoutes restricted={false}><CertificateVerificationPage /> </PublicRoutes>,
    errorElement: <ErrorPage />,
  },
  {
    path: AppConstants.quizEventPageRoute,
    element: <PublicRoutes restricted={false}><QuizEventPage /> </PublicRoutes>,
    errorElement: <ErrorPage />,
  },
  {
    path: AppConstants.quizLisitngPageRoute,
    element: <PublicRoutes restricted={false}><QuizListingPage /> </PublicRoutes>,
    errorElement: <ErrorPage />,
    loader: quizListingPageLoader
  },
  {
    path: AppConstants.welcomeExpertPageRoute,
    element: <PublicRoutes restricted={false}><WelcomeExpertPage /> </PublicRoutes>,
    errorElement: <ErrorPage />,
    loader: expertWelcomePageLoader
  },
  {
    path: AppConstants.nudgesPageRoute,
    element: <PublicRoutes restricted={false}><NudgesPage /> </PublicRoutes>,
    errorElement: <ErrorPage />,
    loader: nudgePageLoader
  },
  {
    path: AppConstants.workshopOnboardingPage,
    element: <PublicRoutes restricted={false}><OnboardingWorkshopPage /></PublicRoutes>,
    errorElement: <ErrorPage />,
    loader: nudgePageLoader
  },
  {
    path: AppConstants.expert,
    element: <PrivateRoutes roles={AppConstants.userRoleExpert}><ExpertTemplate /></PrivateRoutes>,
    loader: expertPageLoader,
    children: [
      {
        path: AppConstants.expertHomePath,
        element: <PrivateRoutes roles={AppConstants.userRoleExpert}><ExpertHomePage /></PrivateRoutes>
      },
      {
        path: AppConstants.myTeachingExpertTemplatePath,
        element: <PrivateRoutes roles={AppConstants.userRoleExpert}><MyTeachingExpertTemplatePage /></PrivateRoutes>
      },
      {
        path: AppConstants.myStudentsExpertTemplatePath,
        element: <PrivateRoutes roles={AppConstants.userRoleExpert}><MyStudentsExpertTemplatePage /></PrivateRoutes>
      },
      {
        path: AppConstants.expertCreateCompetitionsPath,
        element: <PrivateRoutes roles={AppConstants.userRoleExpert}><ExpertCreateCompetitionsPage /></PrivateRoutes>
      },
      {
        path: AppConstants.expertManageCompetitionsPath,
        element: <PrivateRoutes roles={AppConstants.userRoleExpert}><ExpertManageCompetitionsPage /></PrivateRoutes>
      },
      {
        path: AppConstants.expertJudgeCompetitionsPath,
        element: <PrivateRoutes roles={AppConstants.userRoleExpert}><ExpertJudgeCompetitionsPage /></PrivateRoutes>
      },
      {
        path: AppConstants.expertCreateCoursesPath,
        element: <PrivateRoutes roles={AppConstants.userRoleExpert}><ExpertCreateCoursePage /></PrivateRoutes>
      },
      {
        path: AppConstants.expertManageCoursesPath,
        element: <PrivateRoutes roles={AppConstants.userRoleExpert}><ExpertManageCoursesPage /></PrivateRoutes>
      },
      // {
      //   path: AppConstants.expertNudgesPath,
      //   element: <PrivateRoutes roles={AppConstants.userRoleExpert}><ExpertNudgesPage /></PrivateRoutes>
      // },
      {
        path: AppConstants.expertCreateWorkshopPath,
        element: <PrivateRoutes roles={AppConstants.userRoleExpert}><CreateWorkshopPage /></PrivateRoutes>
      },
      {
        path: AppConstants.expertManageWorkshopPath,
        element: <PrivateRoutes roles={AppConstants.userRoleExpert}><ManageWorkshopPage /></PrivateRoutes>
      },
      {
        path: AppConstants.expertpublishUnpublishWorkshopPath,
        element: <PrivateRoutes roles={AppConstants.userRoleExpert}><PublishUnpublishWorkshops /></PrivateRoutes>
      },
      {
        path: AppConstants.expertMySchoolPath,
        element: <PrivateRoutes roles={AppConstants.userRoleExpert}><ExpertMySchoolPage /></PrivateRoutes>
      },
      {
        path: AppConstants.expertBulkMessagingPath,
        element: <PrivateRoutes roles={AppConstants.userRoleExpert}><ExpertBulkMessagingPage /></PrivateRoutes>
      },
      {
        path: AppConstants.expertpublishUnpublishCoursePath,
        element: <PrivateRoutes roles={AppConstants.userRoleExpert}><ExpertPublishUnpublishCourse /></PrivateRoutes>
      },
      {
        path: AppConstants.expertBulkEnrolRoute,
        element: <PrivateRoutes roles="Expert"><ExpertBulkEnrolPage /></PrivateRoutes>
      },
      {
        path: AppConstants.expertAssignedCoursesPath,
        element: <PrivateRoutes roles={AppConstants.userRoleExpert}><ExpertAssignedCoursesPage /></PrivateRoutes>
      },
    ]
  },
  {
    path: AppConstants.admin,
    element: <PrivateRoutes roles={AppConstants.userRoleAdmin}><AdminTemplate /></PrivateRoutes>,
    loader: adminPageLoader,
    children: [
      {
        path: AppConstants.adminDashboardPath,
        element: <PrivateRoutes roles={AppConstants.userRoleAdmin}><AdminDashboardPage /></PrivateRoutes>
      },
      {
        path: AppConstants.adminNumberTrackingPath,
        element: <PrivateRoutes roles={AppConstants.userRoleAdmin}><AdminNumberTrackingPage /></PrivateRoutes>
      },
      {
        path: AppConstants.adminAIFeedbackPath,
        element: <PrivateRoutes roles={AppConstants.userRoleAdmin}><AdminAIFeedbackPage /></PrivateRoutes>
      },
      {
        path: AppConstants.adminBulkEnrolPath,
        element: <PrivateRoutes roles={AppConstants.userRoleAdmin}><AdminBulkEnrolPage /></PrivateRoutes>
      },
      {
        path: AppConstants.adminBulkSignupPath,
        element: <PrivateRoutes roles={AppConstants.userRoleAdmin}><AdminBulkSignupPage /></PrivateRoutes>
      },
      {
        path: AppConstants.adminManageAdminPath,
        element: <PrivateRoutes roles={AppConstants.userRoleAdmin}><AdminManageAdminUserPage /></PrivateRoutes>
      },
      {
        path: AppConstants.adminManageExpertPath,
        element: <PrivateRoutes roles={AppConstants.userRoleAdmin}><AdminManageExpertUserPage /></PrivateRoutes>
      },
      {
        path: AppConstants.adminManageStudentSubsciptionPath,
        element: <PrivateRoutes roles={AppConstants.userRoleAdmin}><AdminManageStudentSubscriptionPage /></PrivateRoutes>
      },
      {
        path: AppConstants.adminContentManagementPath,
        element: <PrivateRoutes roles={AppConstants.userRoleAdmin}><AdminManageContentPage /></PrivateRoutes>
      },
      {
        path: AppConstants.adminCreateQtalksPath,
        element: <PrivateRoutes roles={AppConstants.userRoleAdmin}><AdminCreateQtalksPage /></PrivateRoutes>
      },
      {
        path: AppConstants.adminManageQtalksPath,
        element: <PrivateRoutes roles={AppConstants.userRoleAdmin}><AdminManageQtalksPage /></PrivateRoutes>
      },
      {
        path: AppConstants.adminManageLeadsRoute,
        element: <PrivateRoutes roles={AppConstants.userRoleAdmin}><SalesManageLeadsPage /></PrivateRoutes>
      },
      {
        path: AppConstants.adminCompetitionsPath,
        element: <PrivateRoutes roles={AppConstants.userRoleAdmin}><AdminCompetitionsPage /></PrivateRoutes>
      },
      {
        path: AppConstants.adminSkillLabsPath,
        element: <PrivateRoutes roles={AppConstants.userRoleAdmin}><AdminSkillLabsPage /></PrivateRoutes>
      },
      {
        path: AppConstants.adminWorkshopsPath,
        element: <PrivateRoutes roles={AppConstants.userRoleAdmin}><AdminWorkshopsPage /></PrivateRoutes>
      },
      {
        path: AppConstants.adminClubQrenciaPath,
        element: <PrivateRoutes roles={AppConstants.userRoleAdmin}><AdminClubQrenciaPage /></PrivateRoutes>
      },
      {
        path: AppConstants.adminReferralsAndRewardsPath,
        element: <PrivateRoutes roles={AppConstants.userRoleAdmin}><AdminReferralsAndRewardsPage /></PrivateRoutes>
      },
      {
        path: AppConstants.adminBulkUploadLeads,
        element: <PrivateRoutes roles={AppConstants.userRoleAdmin}><BulkUploadCreatorLeads /></PrivateRoutes>
      }
    ]
  },
  {
    path: AppConstants.student,
    element: <PrivateRoutes roles={AppConstants.userRoleStudent}><StudentTemplate /></PrivateRoutes>,
    children: [
      {
        path: AppConstants.studentMyLearningPath,
        element: <PrivateRoutes roles={AppConstants.userRoleStudent}><StudentMyLearningPage /></PrivateRoutes>
      },
      {
        path: AppConstants.studentMyAnalysisPath,
        element: <PrivateRoutes roles={AppConstants.userRoleStudent}><StudentMyAnalysisPage /></PrivateRoutes>
      },
      {
        path: AppConstants.studentHomePath,
        element: <PrivateRoutes roles={AppConstants.userRoleStudent}><StudentHomePage /></PrivateRoutes>
      },
      {
        path: AppConstants.studentCompetitionsPath,
        element: <PrivateRoutes roles={AppConstants.userRoleStudent}><StudentCompetitionsPage /></PrivateRoutes>
      },
      {
        path: AppConstants.studentCoursesPath,
        element: <PrivateRoutes roles={AppConstants.userRoleStudent}><StudentCoursesPage /></PrivateRoutes>
      },
      {
        path: AppConstants.studentCoursesPath,
        element: <PrivateRoutes roles={AppConstants.userRoleStudent}><StudentWorkshopsPage /></PrivateRoutes>
      },
      {
        path: AppConstants.studentMentorMePath,
        element: <PrivateRoutes roles={AppConstants.userRoleStudent}><StudentMentorMePage /></PrivateRoutes>
      },
      {
        path: AppConstants.studentExploreSkillsPath,
        element: <PrivateRoutes roles={AppConstants.userRoleStudent}><StudentExploreSkillPage /></PrivateRoutes>
      },
      {
        path: AppConstants.studentWorkshopsPath,
        element: <PrivateRoutes roles={AppConstants.userRoleStudent}><StudentWorkshopsPage /></PrivateRoutes>
      },
      {
        path: AppConstants.studentQtalksPath,
        element: <PrivateRoutes roles={AppConstants.userRoleStudent}><StudentQtalksPage /></PrivateRoutes>
      },
      {
        path: AppConstants.studentMySchoolPath,
        element: <PrivateRoutes roles={AppConstants.userRoleStudent}><StudentMySchoolPage /></PrivateRoutes>
      },
      {
        path: AppConstants.studentNudgesPath,
        element: <PrivateRoutes roles={AppConstants.userRoleStudent}><StudentNudgesPage /></PrivateRoutes>
      }
    ]
  },
  {
    path: AppConstants.expertJudgingScreen,
    element: <ExpertJudgingScreenPage />,
    loader: judgingScreenPageLoader,
    errorElement: <ErrorPage />
  },
  {
    path: AppConstants.expertMarkParticipantsScreenRoute,
    element: <ExpertMarkParticipantsOnJudgingScreenPage />,
    loader: judgingScreenPageLoader,
    errorElement: <ErrorPage />
  },
  {
    path: AppConstants.markAttendancePath,
    element: <MarkAttendanceScreen />,
    loader: dedicatedCoursePageLoader,
    errorElement: <ErrorPage />
  },
  {
    path: AppConstants.creatorRespondNudgePage,
    element: <RespondNudgesPage />,
    loader: dedicatedCoursePageLoader,
    errorElement: <ErrorPage />
  },
  {
    path: AppConstants.shareFeedbackForSpecificCoursePath,
    element: <ShareCourseFeedback />,
    loader: dedicatedCoursePageLoader,
    errorElement: <ErrorPage />
  },
  {
    path: AppConstants.shareCourseFeedbackPath,
    element: <ShareCourseFeedback />,
    loader: dedicatedCoursePageLoader,
    errorElement: <ErrorPage />
  },
  {
    path: AppConstants.approvePendingEnrolmentsRoute,
    element: <PendingEnrolmentRequestPage />,
    loader: dedicatedCoursePageLoader,
    errorElement: <ErrorPage />
  },
  {
    path: AppConstants.markAttendanceForSpecificCoursePath,
    element: <MarkSpecificCourseAttendance />,
    loader: dedicatedCoursePageLoader,
    errorElement: <ErrorPage />
  },
  {
    path: AppConstants.studentPerformanceReportPageRoute,
    element: <StudentPerformanceReportPage />
  },
  {
    path: AppConstants.agency,
    element: <PrivateRoutes roles={AppConstants.userRoleAgency}><AgencyTemplate /></PrivateRoutes>,
    loader: agencyPageLoader,
    children: [
      {
        path: AppConstants.agencyHomePath,
        element: <PrivateRoutes roles={AppConstants.userRoleAgency}><AgencyHomePage /></PrivateRoutes>,
        loader: agencyPageLoader,
      },
      {
        path: AppConstants.myTeachingAgencyTemplatePath,
        element: <PrivateRoutes roles={AppConstants.userRoleAgency}><MyTeachingAgencyTemplatePage /></PrivateRoutes>
      },
      {
        path: AppConstants.myStudentsAgencyTemplatePath,
        element: <PrivateRoutes roles={AppConstants.userRoleAgency}><MyStudentsAgencyTemplatePage /></PrivateRoutes>
      },
      {
        path: AppConstants.agencyCreateCompetitionsPath,
        element: <PrivateRoutes roles={AppConstants.userRoleAgency}><ExpertCreateCompetitionsPage /></PrivateRoutes>
      },
      {
        path: AppConstants.agencyManageCompetitionsPath,
        element: <PrivateRoutes roles={AppConstants.userRoleAgency}><ExpertManageCompetitionsPage /></PrivateRoutes>
      },
      {
        path: AppConstants.agencyCreateCoursesPath,
        element: <PrivateRoutes roles={AppConstants.userRoleAgency}><ExpertCreateCoursePage /></PrivateRoutes>
      },
      {
        path: AppConstants.agencyManageCoursesPath,
        element: <PrivateRoutes roles={AppConstants.userRoleAgency}><ExpertManageCoursesPage /></PrivateRoutes>
      },
      {
        path: AppConstants.agencyCreateWorkshopPath,
        element: <PrivateRoutes roles={AppConstants.userRoleAgency}><CreateWorkshopPage /></PrivateRoutes>
      },
      {
        path: AppConstants.agencyManageWorkshopPath,
        element: <PrivateRoutes roles={AppConstants.userRoleAgency}><ManageWorkshopPage /></PrivateRoutes>
      },
      {
        path: AppConstants.agencypublishUnpublishWorkshopPath,
        element: <PrivateRoutes roles={AppConstants.userRoleAgency}><PublishUnpublishWorkshops /></PrivateRoutes>
      },
      {
        path: AppConstants.agencyMapStudentsPath,
        element: <PrivateRoutes roles={AppConstants.userRoleAgency}><AgencyMapStudentsPage /></PrivateRoutes>
      },
      {
        path: AppConstants.agencyMapTeachersPath,
        element: <PrivateRoutes roles={AppConstants.userRoleAgency}><AgencyMapExpertsPage /></PrivateRoutes>
      },
      {
        path: AppConstants.agencyMonitoringDashboardPath,
        element: <PrivateRoutes roles={AppConstants.userRoleAgency}><MonitorLearningActivitiesPage /></PrivateRoutes>
      },
      {
        path: AppConstants.agencyBulkMessagingPath,
        element: <PrivateRoutes roles={AppConstants.userRoleAgency}><ExpertBulkMessagingPage /></PrivateRoutes>
      },
      {
        path: AppConstants.agencyPublishUnpublishCoursePath,
        element: <PrivateRoutes roles={AppConstants.userRoleAgency}><ExpertPublishUnpublishCourse /></PrivateRoutes>
      },
      {
        path: AppConstants.agencyBulkEnrolRoute,
        element: <PrivateRoutes roles={AppConstants.userRoleAgency}><ExpertBulkEnrolPage /></PrivateRoutes>
      },
      {
        path: AppConstants.agencyAssignCoursesPath,
        element: <PrivateRoutes roles={AppConstants.userRoleAgency}><AgencyAssignCoursesPage /></PrivateRoutes>
      },
    ]
  },
  {
    path: AppConstants.sales,
    element: <PrivateRoutes roles={AppConstants.userRoleSales}><SalesTemplate /></PrivateRoutes>,
    loader: FooterSectionLoader,
    children: [
      {
        path: AppConstants.salesDashboardPath,
        element: <PrivateRoutes roles={AppConstants.userRoleSales}><AdminDashboardPage /></PrivateRoutes>
      },
      {
        path: AppConstants.salesNumberTrackingPath,
        element: <PrivateRoutes roles={AppConstants.userRoleSales}><AdminNumberTrackingPage /></PrivateRoutes>
      },
      {
        path: AppConstants.salesLeadsPath,
        element: <PrivateRoutes roles={AppConstants.userRoleSales}><SalesManageLeadsPage /></PrivateRoutes>
      },
      {
        path: AppConstants.salesBulkUploadLeads,
        element: <PrivateRoutes roles={AppConstants.userRoleSales}><BulkUploadCreatorLeads /></PrivateRoutes>
      },
    ]
  },
  {
    path: AppConstants.marketing,
    element: <PrivateRoutes roles={AppConstants.userRoleMarketing}><MarketingTemplate /></PrivateRoutes>,
    loader: FooterSectionLoader,
    children: [
      {
        path: AppConstants.marketingBulkSignupPath,
        element: <PrivateRoutes roles={AppConstants.userRoleMarketing}><AdminBulkSignupPage /></PrivateRoutes>
      },
      {
        path: AppConstants.marketingBulkEnrolPath,
        element: <PrivateRoutes roles={AppConstants.userRoleMarketing}><AdminBulkEnrolPage /></PrivateRoutes>
      },
      {
        path: AppConstants.marketingBulkUploadLeads,
        element: <PrivateRoutes roles={AppConstants.userRoleMarketing}><BulkUploadCreatorLeads /></PrivateRoutes>
      },
      {
        path: AppConstants.marketingDashboardPath,
        element: <PrivateRoutes roles={AppConstants.userRoleMarketing}><AdminDashboardPage /></PrivateRoutes>
      },
      {
        path: AppConstants.marketingNumberTrackingPath,
        element: <PrivateRoutes roles={AppConstants.userRoleMarketing}><AdminNumberTrackingPage /></PrivateRoutes>
      }
    ]
  },
  {
    path: AppConstants.partnerSuccess,
    element: <PrivateRoutes roles={AppConstants.userRolePartnerSuccess}><PartnerSuccessTemplate /></PrivateRoutes>,
    loader: adminPageLoader,
    children: [
      {
        path: AppConstants.partnerSuccessDashboardPath,
        element: <PrivateRoutes roles={AppConstants.userRolePartnerSuccess}><AdminDashboardPage /></PrivateRoutes>
      },
      {
        path: AppConstants.partnerSuccessNumberTracking,
        element: <PrivateRoutes roles={AppConstants.userRolePartnerSuccess}><AdminNumberTrackingPage /></PrivateRoutes>
      },
      {
        path: AppConstants.partnerSuccessBulkEnrolPath,
        element: <PrivateRoutes roles={AppConstants.userRolePartnerSuccess}><AdminBulkEnrolPage /></PrivateRoutes>
      },
      {
        path: AppConstants.partnerSuccessBulkSignupPath,
        element: <PrivateRoutes roles={AppConstants.userRolePartnerSuccess}><AdminBulkSignupPage /></PrivateRoutes>
      },
      {
        path: AppConstants.partnerSuccessManageExpertPath,
        element: <PrivateRoutes roles={AppConstants.userRolePartnerSuccess}><AdminManageExpertUserPage /></PrivateRoutes>
      },
      {
        path: AppConstants.partnerSuccessBulkUploadLeads,
        element: <PrivateRoutes roles={AppConstants.userRolePartnerSuccess}><BulkUploadCreatorLeads /></PrivateRoutes>
      },
      {
        path: AppConstants.partnerSuccessManageLeadsRoute,
        element: <PrivateRoutes roles={AppConstants.userRolePartnerSuccess}><SalesManageLeadsPage /></PrivateRoutes>
      },
    ]
  }
]);

function App() {
  return (
    <>
      <SelectionProvider>
        <RouterProvider router={router} />
      </SelectionProvider>
    </>
  );
}

export default App;