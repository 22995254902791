import { useState, useEffect, useRef } from "react";
import { Button, Form, Row, Col } from "react-bootstrap";
import { useFormik } from "formik";
import moment from "moment";
import * as Yup from 'yup';
import validator from "validator";
import ReactQuill, { Quill } from 'react-quill';
import { MdDelete } from 'react-icons/md';
import Modal from 'react-bootstrap/Modal';
import { Tooltip } from "react-tooltip";
import { AppConstants } from "../../constants/AppConstants";
import { ErrorMessages } from "../../constants/ErrorMessages";
import formClass from '../../common/FormStyling.module.css';
import 'react-quill/dist/quill.snow.css';
import classes from "../createCompetitionComponents/AddCompetitionDetails/AddCompetitionDetails.module.css";
import { RiInformationFill } from "react-icons/ri";
import { DateTime } from "luxon";
import { DateTimeUtil } from "../../utils/DateTimeUtil";
import { GenerateAiImagesComponent } from "../generateAiImagesComponent/GenerateAiImagesComponent";
import { ErrorSuccessAlertMessage } from "../errorSuccessMessage/ErrorSuccessAlertMessage";
import Auth from "../../utils/Auth";
import { FileUploadInput } from "../fileUploadInput/FileUploadInput";
import { FileOperationsUtil } from "../../utils/FileOperationsUtil";
import { BiLoaderAlt } from "react-icons/bi";
import { AiOutlineDownload } from "react-icons/ai";
import { StringUtils } from "../../utils/StringUtils";

export const EditCompetitionDetails = (props) => {

    const { skills = [], saveCurrentStep, stage, changeBasicDetailsFormError, changeBasicFormDetailsData, competitionData, minimumSignupAge } = props;
    const supportedImageFormats = AppConstants.supportedImageFormats;
    const status = competitionData.competitionStatus;
    const competitionStatus = AppConstants.competitionStatus;
    const enrolledParticipants = competitionData.participantsDetails?.length;

    const competitionTypes = AppConstants.competitionTypes;
    const formLabels = AppConstants.addCompetitionDetailsLabels;
    const stages = AppConstants.editCompetitionActions;
    const [validated, setValidated] = useState(AppConstants.falseText);
    const [formSubmitting, setFormSubmitting] = useState(AppConstants.falseText);
    const [guidelines, setGuidelines] = useState(competitionData.guidelinesText !== AppConstants.nullText ? competitionData.guidelinesText : AppConstants.emptyEditorString);
    const [guidelinesPdf, setGuidelinesPdf] = useState({})
    const [guidelinesError, setGuidelinesError] = useState(AppConstants.emptyString);
    const [registrationDateAdded, setRegistrationDateAdded] = useState(AppConstants.trueText);
    const [registrationTimeAdded, setRegistrationTimeAdded] = useState(AppConstants.trueText);
    const [resultByAdded, setResultByAdded] = useState(competitionData.resultDeclared !== AppConstants.nullText ? AppConstants.trueText : AppConstants.falseText);
    const [resultDateAdded, setResultDateAdded] = useState(competitionData.resultDate !== AppConstants.nullText ? AppConstants.trueText : AppConstants.falseText);
    const [showResultDeclareRow, setShowResultDeclareRow] = useState(competitionData.resultDate !== AppConstants.nullText ? AppConstants.trueText : AppConstants.falseText);
    const [description, setDescription] = useState(competitionData.description);
    const [resultDeclareCheckbox, setResultDeclareCheckbox] = useState(AppConstants.falseText);
    const [aiGeneratedImages, setAiGeneratedImages] = useState({});
    const [thumbnailPreview, setThumbnailPreview] = useState(AppConstants.emptyString);
    const [webBannerPreview, setWebBannerPreview] = useState(AppConstants.emptyString);
    const [tabletBannerPreview, setTabletBannerPreview] = useState(AppConstants.emptyString);
    const [mobileBannerPreview, setMobileBannerPreview] = useState(AppConstants.emptyString);
    const [imageGeneratedArgs, setImageGeneratedArgs] = useState({});
    const [aiErrorMessage, setAiErrorMessage] = useState(AppConstants.emptyString);
    const timeZone = DateTimeUtil.getPreferredTimeZoneForUser();
    const [showModal, setShowModal] = useState(AppConstants.emptyString);
    const mediaContent = process.env.REACT_APP_MEDIA_CONTENT;
    const qrenciaGlobeImage = `${mediaContent}${AppConstants.staticContentImagesS3Path}${AppConstants.forwardSlash}${AppConstants.performanceAnalysisPageS3Path}${AppConstants.forwardSlash}${AppConstants.qrenciaGlobeImage}`;
    const defaultImagePath = `${mediaContent}${AppConstants.staticContentImagesS3Path}${AppConstants.forwardSlash}${AppConstants.aiImagGenDefaultPath}`;
    const reactQuillRef = useRef(AppConstants.competitionUploadChallengeAPI);

    const [guidelinesDocumentSpinner, setGuidelinesDocumentSpinner] = useState(AppConstants.falseText);
    const [thumbnailSpinner, setThumbnailSpinner] = useState(AppConstants.falseText);
    const [promotionImageWebSpinner, setPromotionImageWebSpinner] = useState(AppConstants.falseText);
    const [promotionImageTabletSpinner, setPromotionImageTabletSpinner] = useState(AppConstants.falseText);
    const [promotionImageMobileSpinner, setPromotionImageMobileSpinner] = useState(AppConstants.falseText);

    const [uploadedPromotionImageWeb, setUploadedPromotionImageWeb] = useState(competitionData.promotionImagePathWeb);
    const [uploadedPromotionImageTablet, setUploadedPromotionImageTablet] = useState(competitionData.promotionImagePathTablet);
    const [uploadedPromotionImageMobile, setUploadedPromotionImageMobile] = useState(competitionData.promotionImagePathMobile);
    const [uploadedThumbnailImage, setUploadedThumbnailImage] = useState(competitionData.thumbnailImagePath);
    const [uploadedGuidelinesDocument, setUploadedGuidelinesDocument] = useState(competitionData.guidelinesDocumentPath);

    const thumbnailImageInput = document.getElementById(formLabels.thumbnailImage.name);
    const webBannerInput = document.getElementById(formLabels.promotionImageForWeb.name);
    const tabletBannerInput = document.getElementById(formLabels.promotionImageForTablet.name);
    const mobileBannerInput = document.getElementById(formLabels.promotionImageForMobile.name);

    const Link = Quill.import('formats/link');
    Link.sanitize = function (url) {
        try {
            // quill by default creates relative links if scheme is missing.
            if (!url.startsWith(`${AppConstants.internetProtocols.http}://`) && !url.startsWith(`${AppConstants.internetProtocols.https}://`)) {
                return `${AppConstants.internetProtocols.https}://${url}`
            }
            return url;
        } catch (e) {
            return url;
        }
    };

    const initialValues = {
        title: competitionData?.title,
        skill: competitionData.skillUniqueId,
        cost: competitionData.cost,
        date: DateTime.fromISO(`${competitionData.startDateTimeUTC.replace(" ", "T")}Z`, { zone: 'utc' }).setZone(timeZone).toFormat(AppConstants.browserDefaultDateFormat),
        endDate: DateTime.fromISO(`${competitionData.endDateTimeUTC.replace(" ", "T")}Z`, { zone: 'utc' }).setZone(timeZone).toFormat(AppConstants.browserDefaultDateFormat),
        startTime: DateTime.fromISO(`${competitionData.startDateTimeUTC.replace(" ", "T")}Z`, { zone: 'utc' }).setZone(timeZone).toFormat('HH:mm'),
        endTime: DateTime.fromISO(`${competitionData.endDateTimeUTC.replace(" ", "T")}Z`, { zone: 'utc' }).setZone(timeZone).toFormat('HH:mm'),
        registrationClosingDate: DateTime.fromISO(`${competitionData.registrationClosingDateUTC?.replace(" ", "T")}Z`, { zone: 'utc' }).setZone(timeZone).toFormat(AppConstants.browserDefaultDateFormat),
        registrationClosingTime: DateTime.fromISO(`${competitionData.registrationClosingDateUTC?.replace(" ", "T")}Z`, { zone: 'utc' }).setZone(timeZone).toFormat('HH:mm'),
        competitionType: competitionData?.type,
        vcLink: competitionData?.vcLink || AppConstants.emptyString,
        geoLocation: competitionData?.geoLocation || AppConstants.emptyString,
        venueLocation: competitionData?.venueLocation || AppConstants.emptyString,
        promotionImageForMobile: AppConstants.emptyString,
        promotionImageForWeb: AppConstants.emptyString,
        promotionImageForTablet: AppConstants.emptyString,
        thumbnailImage: AppConstants.emptyString,
        allowedNumberOfParticipants: competitionData?.allowedNumberOfParticipants || AppConstants.emptyString,
        maxAge: (competitionData?.maximumAge === 0 ? AppConstants.emptyString : competitionData?.maximumAge) || AppConstants.emptyString,
        minAge: (competitionData?.minimumAge === 0 ? AppConstants.emptyString : competitionData?.minimumAge) || AppConstants.emptyString,
        submission: competitionData?.allowSubmissionFlag || AppConstants.nText,
        resultDeclareDate: competitionData.resultDate || AppConstants.emptyString,
        resultDeclareBy: competitionData?.resultDeclared || AppConstants.emptyString,
    };

    const getRemainingLengthText = (limit, textField) => {
        if (!textField || textField === AppConstants.emptyString) {
            return AppConstants.emptyString;
        }

        return `${limit - textField.length} out of ${limit} characters remaining`;
    };

    const validationSchema = Yup.object().shape({
        title: Yup.string().trim().max(100, ErrorMessages.invalidCompetitionTitle).matches(AppConstants.quotesRegex, { message: ErrorMessages.doubleQuotesTitleError }).required(`${ErrorMessages.pleaseEnterText}${AppConstants.space}${formLabels.title.label}`),
        skill: (enrolledParticipants > 0 || status !== competitionStatus.upcoming) ? Yup.string().notRequired() : Yup.string().required(ErrorMessages.selectSkill).oneOf(skills?.map(s => s.skillId)),
        cost: (competitionData.clubQrenciaFlg === AppConstants.yText || competitionData.competitionFeeWaiverFlag === AppConstants.yText) ? Yup.number().notRequired() : Yup.number().required("Please enter a valid competition cost").min(props.competitionMinimumCost, ErrorMessages.minimumCompetitionCostError + props.competitionMinimumCost),
        date: status !== competitionStatus.upcoming ? Yup.date().notRequired() : Yup.date().required(`${ErrorMessages.pleaseEnterText}${AppConstants.space}${formLabels.date.label}`).min(DateTimeUtil.getConvertedJsDateInPreferredTimeZone(new Date(Date.now() - 86400000), timeZone), `${formLabels.date.label} ${ErrorMessages.pastDateError}`),
        endDate: status === competitionStatus.past ? Yup.date().notRequired() :
            Yup.date().required(`${ErrorMessages.pleaseEnterText}${AppConstants.space}${formLabels.endDate.label}`).test(
                "end_time_before_start", ErrorMessages.competitionEndDateError,
                function (value) {
                    const { date } = this.parent;
                    return moment(date).isSameOrBefore(moment(value));
                }
            ).test("end_date_before_today", ErrorMessages.competitionEndDatePastError, function (value) {
                const dateValue = new Date(value);

                return moment(DateTimeUtil.getConvertedJsDateInPreferredTimeZone(dateValue, timeZone)).isAfter(DateTimeUtil.getConvertedJsDateInPreferredTimeZone(new Date(Date.now() - 86400000), timeZone));
            }),
        startTime: status !== competitionStatus.upcoming ? Yup.string().notRequired() : Yup.string().required(`${ErrorMessages.pleaseEnterText}${AppConstants.space}${formLabels.startTime.label}`).test(
            "start_time_test", ErrorMessages.startTimeError, function (value) {
                const { date } = this.parent;
                if (date) {
                    const d = new Date(date);
                    let hourMins = value.split(":");
                    let currentDateTime = DateTime.now().setZone(timeZone);
                    const selectedDateTime = DateTime.fromObject({ year: d.getFullYear(), month: d.getMonth() + 1, day: d.getDate(), hour: hourMins[0], minute: hourMins[1], second: "00" }, { zone: timeZone });
                    return selectedDateTime.toMillis() > currentDateTime.toMillis()
                } else {
                    return true;
                }
            }
        ),
        endTime: (status === competitionStatus.past) ? Yup.string().notRequired() : Yup.string().required(`${ErrorMessages.pleaseEnterText}${AppConstants.space}${formLabels.endTime.label}`).test(
            "end_time_test", ErrorMessages.endTimeBeforeStartTimeError,
            function (value) {
                const { startTime, date, endDate } = this.parent;
                // if start date and end date are same then check end time must be greater than start time
                if (moment(endDate).isSame(moment(date))) {
                    return moment(value, 'HH:mm').isAfter(moment(startTime, 'HH:mm'));
                } else {
                    return AppConstants.trueText;
                }
            }
        ).test("end_time_before_current_test", ErrorMessages.competitionEndTimePastError, function (value) {
            const { endDate } = this.parent;
            if (endDate) {
                const d = new Date(endDate);
                let hourMins = value.split(":");
                let currentDateTime = DateTime.now().setZone(timeZone);
                const selectedEndDate = DateTime.fromObject({ year: d.getFullYear(), month: d.getMonth() + 1, day: d.getDate(), hour: hourMins[0], minute: hourMins[1], second: "00" }, { zone: timeZone });
                return selectedEndDate.toMillis() > currentDateTime.toMillis()
            } else {
                return true;
            }
        }),
        registrationClosingDate: (moment(competitionData.registrationClosesOn).isBefore(moment(DateTimeUtil.getConvertedJsDateInPreferredTimeZone(new Date(), timeZone))) ? Yup.date().required(`${ErrorMessages.pleaseAddText}${AppConstants.space}${formLabels.registrationClosingDate.label}`).test(
            "registration_closing_edit_test", "Invalid Registration closing date", function (value) {
                if (status === competitionStatus.past) {
                    return true;
                }
                const { endDate } = this.parent;
                const dateTime = DateTime.fromJSDate(new Date(competitionData.registrationClosesOn)).set({ hour: 0, minute: 0, second: 0, millisecond: 0 });
                return (value.toString() === dateTime.toJSDate().toString()) || moment(value).isBetween(DateTime.now().set({ hour: 0, minute: 0, second: 0, millisecond: 0 }).setZone(timeZone).toJSDate(), endDate, AppConstants.nullText, AppConstants.trueText);
            }
        ) : (status === competitionStatus.past) ? Yup.date().notRequired() : registrationTimeAdded ? Yup.date().required(`${ErrorMessages.pleaseAddText}${AppConstants.space}${formLabels.registrationClosingDate.label}`).min(DateTimeUtil.getConvertedJsDateInPreferredTimeZone(new Date(Date.now() - 86400000), timeZone), `${formLabels.registrationClosingDate.label} ${ErrorMessages.pastDateError}`).max(Yup.ref(formLabels.endDate.name), `${ErrorMessages.registrationDateBeforeEndDate}`)
            : registrationDateAdded ? Yup.date().min(DateTimeUtil.getConvertedJsDateInPreferredTimeZone(new Date(Date.now() - 86400000), timeZone), `${formLabels.registrationClosingDate.label} ${ErrorMessages.pastDateError}`).max(Yup.ref(formLabels.endDate.name), `${ErrorMessages.registrationDateBeforeEndDate}`) : Yup.date().notRequired()),
        registrationClosingTime: (status === competitionStatus.past) ? Yup.string().notRequired() : registrationDateAdded ? Yup.string().required(`${ErrorMessages.pleaseEnterText}${AppConstants.space}${formLabels.registrationClosingTime.label}`).test(
            "registration_time_test", ErrorMessages.registrationTimeBeforeEndTimeError,
            function (value) {
                const { endTime, registrationClosingDate, endDate } = this.parent;
                if (moment(registrationClosingDate).isSame(moment(endDate)))
                    return moment(endTime, 'HH:mm').isAfter(moment(value, 'HH:mm'));
                else return AppConstants.trueText;
            }).test("registration_time_before_current_test", ErrorMessages.registrationBeforeCurrentTimeError, function (value) {
                const { registrationClosingDate } = this.parent;
                const d = new Date(registrationClosingDate);
                let hourMins = value.split(":");
                let currentDateTime = DateTime.now().setZone(timeZone);
                const selectedDateTime = DateTime.fromObject({ year: d.getFullYear(), month: d.getMonth() + 1, day: d.getDate(), hour: hourMins[0], minute: hourMins[1], second: "00" }, { zone: timeZone });
                if (moment(competitionData.registrationClosesOn).isBefore(moment(DateTimeUtil.getConvertedJsDateInPreferredTimeZone(new Date(), timeZone))) && (selectedDateTime.toJSDate().toString() != competitionData.registrationClosesOn) && selectedDateTime.toMillis() < currentDateTime.toMillis()) {
                    return AppConstants.falseText;
                } else if (moment(competitionData.registrationClosesOn).isBefore(moment(DateTimeUtil.getConvertedJsDateInPreferredTimeZone(new Date(), timeZone))) && (selectedDateTime.toJSDate().toString() === competitionData.registrationClosesOn)) {
                    return AppConstants.trueText;
                } else return selectedDateTime.toMillis() > currentDateTime.toMillis();
            }) : Yup.string().notRequired(),
        resultDeclareBy: (resultDateAdded || resultByAdded) ? Yup.mixed().required(ErrorMessages.resultDeclareMandatoryError) : Yup.mixed().notRequired(),
        resultDeclareDate: (resultDateAdded || resultByAdded) ? Yup.date().required("Please select result declaration date").test("result_Date_test_before", status === competitionStatus.past ? "Result date cannot be a past date" : "Result date cannot be before competition end date", function (value) {
            const { resultDeclareBy, endDate } = this.parent;
            let minimumResultDate = (status === competitionStatus.past) ? DateTime.now().setZone(timeZone).set({ hour: 0, minute: 0, second: 0, millisecond: 0 }) : DateTime.fromJSDate(endDate).setZone(timeZone);
            let selectedDate = DateTime.fromJSDate(value).setZone(timeZone);

            if (endDate === AppConstants.emptyString || endDate === AppConstants.nullText || endDate === undefined) {
                return AppConstants.trueText;
            }

            if (resultDeclareBy === "On") {
                // return true if result declare date is same or after end date (or today's date in case of past competition)
                return selectedDate.toMillis() >= minimumResultDate.toMillis();
            } else if (resultDeclareBy === "Before") {
                // return true if result declare date is after the competition end date (or today's date in case of past competition)
                return selectedDate.toMillis() > minimumResultDate.toMillis();
            } else if (endDate !== AppConstants.emptyString) {
                return selectedDate.toMillis() >= minimumResultDate.toMillis();
            } else {
                return AppConstants.trueText;
            }
        }) : Yup.date().notRequired(),
        competitionType: Yup.string().required(`${ErrorMessages.pleaseSelectText}${AppConstants.space}${AppConstants.competitionTypeText}`).oneOf(competitionTypes),
        geoLocation: Yup.string().when(formLabels.competitionType.name, {
            is: (val) => val === AppConstants.competitionTypeOffline,
            then: () => Yup.string().test("url_validation", `${ErrorMessages.enterValidUrl} for Venue Link`,
                function (value = "") { return validator.isURL(value) })
        }),
        venueLocation: Yup.string().when(formLabels.competitionType.name, {
            is: (val) => val === AppConstants.competitionTypeOffline,
            then: () => Yup.string().required(`${ErrorMessages.pleaseEnterText}${AppConstants.space}${formLabels.venueLocation.label}`)
        }),
        vcLink: Yup.string().test("url_validation_vclink", `${ErrorMessages.enterValidUrl} for VC Link`,
            function (value = "") { return value === AppConstants.emptyString ? AppConstants.trueText : validator.isURL(value) }).notRequired(),
        allowedNumberOfParticipants: enrolledParticipants > 0 ? Yup.number().required(`${ErrorMessages.pleaseEnterText}${AppConstants.space}Participant limit`).min(enrolledParticipants, "Participants limit can not be less than already enroled students.").integer(`Participants ${ErrorMessages.invalidDecimalNumber}`) : Yup.number().required(`${ErrorMessages.pleaseEnterText}${AppConstants.space}Participant limit`).min(1, ErrorMessages.invalidParticipationLimit).integer(`Participants ${ErrorMessages.invalidDecimalNumber}`),
        minAge: Yup.number().notRequired().min(minimumSignupAge, StringUtils.getParsedMessage(ErrorMessages.invalidAge, { minimumSignupAge })).integer(`Age ${ErrorMessages.invalidDecimalNumber}`),
        maxAge: Yup.number().notRequired().integer(`Age ${ErrorMessages.invalidDecimalNumber}`).min(minimumSignupAge, StringUtils.getParsedMessage(ErrorMessages.invalidAge, { minimumSignupAge })).when("minAge", {
            is: (val) => val > minimumSignupAge - 1,
            then: () => Yup.number().notRequired().min(Yup.ref(formLabels.minAge.name), ErrorMessages.invalidMaxAge)
        }),
        submission: Yup.mixed().required(ErrorMessages.requiredField),
        thumbnailImage: Yup.mixed().notRequired().test('fileSize', `Thumbnail image ${ErrorMessages.fileSizeMoreThan5mbError}`, (value) => { return value ? (value.size <= 5000000) : AppConstants.trueText }).test('fileType', `Thumbnail image ${ErrorMessages.fileTypeOtherThanJpgOrPngError}`, (value) => { return value ? (supportedImageFormats.includes(value.type)) : AppConstants.trueText }),
        promotionImageForMobile: Yup.mixed().notRequired().test('fileSize', `Promotion image for mobile ${ErrorMessages.fileSizeMoreThan5mbError}`, (value) => { return value ? (value.size <= 5000000) : AppConstants.trueText }).test('fileType', `Promotion image for mobile ${ErrorMessages.fileTypeOtherThanJpgOrPngError}`, (value) => { return value ? (supportedImageFormats.includes(value.type)) : AppConstants.trueText }),
        promotionImageForTablet: Yup.mixed().notRequired().test('fileSize', `Promotion image for tablet ${ErrorMessages.fileSizeMoreThan5mbError}`, (value) => { return value ? (value.size <= 5000000) : AppConstants.trueText }).test('fileType', `Promotion image for tablet ${ErrorMessages.fileTypeOtherThanJpgOrPngError}`, (value) => { return value ? (supportedImageFormats.includes(value.type)) : AppConstants.trueText }),
        promotionImageForWeb: Yup.mixed().notRequired().test('fileSize', `Promotion image for web ${ErrorMessages.fileSizeMoreThan5mbError}`, (value) => { return value ? (value.size <= 5000000) : AppConstants.trueText }).test('fileType', `Promotion image for web ${ErrorMessages.fileTypeOtherThanJpgOrPngError}`, (value) => { return value ? (supportedImageFormats.includes(value.type)) : AppConstants.trueText }),
    });

    const { handleSubmit, values, touched, errors, setFieldValue, handleBlur, handleChange, validateForm, setFieldTouched } = useFormik({
        initialValues,
        enableReinitialize: AppConstants.trueText,
        validationSchema,
        handleSubmit: () => { }
    });

    useEffect(() => {
        if (skills?.length === 1) {
            setFieldValue(formLabels.skill.name, skills[0]?.skillId)
        }
        validateForm();
    }, [skills]);

    // const checkCharacterCount = (event) => {
    //     const editor = reactQuillRef.current.unprivilegedEditor;
    //     if (editor.getLength() > 500 && event.key !== 'Backspace') {
    //         event.preventDefault();
    //     }
    // };

    useEffect(() => {
        validateForm();
        setFieldTouched(formLabels.endTime.name);
        setFieldTouched(formLabels.startTime.name);
    }, [resultDateAdded, resultByAdded, values.date, values.endDate, values.resultDeclareDate, values.resultDeclareBy]);

    useEffect(() => {
        if (guidelines === AppConstants.emptyEditorString && guidelinesPdf !== AppConstants.nullText && guidelinesPdf !== undefined && guidelinesPdf.name?.length !== 0) {
            if (guidelinesPdf.type === AppConstants.contentTypeMap.pdf) {
                if (guidelinesPdf.size > 5000000) {
                    setGuidelinesError(`Guidelines file ${ErrorMessages.fileSizeMoreThan5mbError}`);
                } else {
                    setGuidelinesError(AppConstants.emptyString);
                }
            } else if (guidelinesPdf?.size) {
                setGuidelinesError(ErrorMessages.fileTypeOtherThanPdfError);
            }
        } else {
            setGuidelinesError(AppConstants.emptyString);
        }
    }, [guidelinesPdf, guidelines]);

    useEffect(() => {
        if (!props.rubricsConfigured) {
            changeResultDeclareRowStatus(AppConstants.falseText);
        } else if (competitionData.resultDate) {
            setShowResultDeclareRow(AppConstants.trueText);
            setResultDeclareCheckbox(AppConstants.trueText);
            setResultDateAdded(AppConstants.trueText);
            setResultByAdded(AppConstants.trueText);
            setFieldValue(formLabels.resultDeclareDate.name, competitionData.resultDate);
            setFieldValue(formLabels.resultDeclareBy.name, competitionData.resultDeclared);
        }
    }, [props.rubricsConfigured]);

    useEffect(() => {
        setShowResultDeclareRow(competitionData.resultDate !== AppConstants.nullText ? AppConstants.trueText : AppConstants.falseText);
        setResultDeclareCheckbox(competitionData.resultDate !== AppConstants.nullText ? AppConstants.trueText : AppConstants.falseText)
    }, [competitionData.resultDate]);

    useEffect(() => {
        const changeParentState = () => {
            changeBasicFormDetailsData({
                ...values,
                guidelinesText: guidelines,
                guidelinesFile: guidelinesPdf,
                description: description,
                uploadedThumbnailImage, uploadedPromotionImageWeb,
                uploadedPromotionImageTablet, uploadedPromotionImageMobile, uploadedGuidelinesDocument
            });
        }
        changeParentState();
    }, [values, guidelines, guidelinesPdf, description, uploadedThumbnailImage, uploadedPromotionImageWeb, uploadedPromotionImageTablet, uploadedPromotionImageMobile, uploadedGuidelinesDocument]);

    useEffect(() => {
        const changeParentState = () => {
            changeBasicDetailsFormError(errors, guidelinesError);

            if (description?.replace(/<(.|\n)*?>/g, '').trim().length === 0) {
                if (errors == {} && values.title === AppConstants.emptyString) {
                    changeBasicDetailsFormError(errors, guidelinesError);
                } else if (values.title === AppConstants.emptyString || values.title?.includes(`"`)) {
                    changeBasicDetailsFormError(errors, guidelinesError);
                } else {
                    changeBasicDetailsFormError({ description: ErrorMessages.enterCompetitionDescriptionError, ...errors }, guidelinesError);
                }
            } else {
                changeBasicDetailsFormError(errors, guidelinesError);
            }
        }
        changeParentState();
    }, [errors, guidelinesError, description]);

    const removeFile = (id) => {
        const input = document.getElementById(id);
        input.value = AppConstants.emptyString;
        input.type = AppConstants.inputFieldTypes.file;
        if (id === "guidelinesPdfInput") {
            setGuidelinesPdf({});
            setGuidelinesError(AppConstants.emptyString);
        } else {
            setFieldValue(id, AppConstants.emptyString)
        }
    };

    const changeCompetitionType = (event) => {
        setFieldValue(formLabels.competitionType.name, event.target.value);
        if (event.target.value) {
            if (event.target.value === AppConstants.competitionTypeOffline) {
                setFieldValue(formLabels.vcLink.name, AppConstants.emptyString);
            } else if (event.target.value === AppConstants.competitionTypeOnline) {
                setFieldValue(formLabels.venueLocation.name, AppConstants.emptyString);
                setFieldValue(formLabels.geoLocation.name, AppConstants.emptyString);
            }
        } else {
            setFieldValue(formLabels.vcLink.name, AppConstants.emptyString);
            setFieldValue(formLabels.venueLocation.name, AppConstants.emptyString);
            setFieldValue(formLabels.geoLocation.name, AppConstants.emptyString);
        }
    };

    const changeRegistrationClosingDate = (e) => {
        setFieldValue(formLabels.registrationClosingDate.name, e.target.value)
        if (e.target.value) {
            setRegistrationDateAdded(AppConstants.trueText);
        } else {
            setRegistrationDateAdded(AppConstants.falseText);
        }
    }

    const changeRegistrationClosingTime = (e) => {
        setFieldValue(formLabels.registrationClosingTime.name, e.target.value);
        if (e.target.value) {
            setRegistrationTimeAdded(AppConstants.trueText)
        } else {
            setRegistrationTimeAdded(AppConstants.falseText)
        }
    };

    const handleFormSubmit = () => {
        saveCurrentStep({ step: stages.indexOf(stage) });
    };

    const changeResultDeclareBy = (e) => {
        setFieldValue(formLabels.resultDeclareBy.name, e.target.value);
        setResultByAdded(AppConstants.trueText);
        validateForm();
    };

    const changeResultDeclareDate = (e) => {
        setFieldValue(formLabels.resultDeclareDate.name, e.target.value);
        if (e.target.value) {
            setResultDateAdded(AppConstants.trueText);
        } else {
            setResultDateAdded(AppConstants.falseText);
        }
        validateForm();
    };

    const changeResultDeclareRowStatus = (value) => {
        setResultDeclareCheckbox(value);
        setShowResultDeclareRow(value);
        setResultDateAdded(AppConstants.falseText);
        setResultByAdded(AppConstants.falseText);
        setFieldValue(formLabels.resultDeclareDate.name, AppConstants.emptyString);
        setFieldValue(formLabels.resultDeclareBy.name, AppConstants.emptyString);
        validateForm();
    };

    const disableImageGeneration = () => {
        if (values.title.trim() !== AppConstants.emptyString && values.skill !== AppConstants.emptyString) {
            if (values.title.trim() === imageGeneratedArgs.competitionName && values.skill === imageGeneratedArgs.skillName) {
                return AppConstants.trueText;
            }
            return AppConstants.falseText;
        } else {
            return AppConstants.trueText;
        }
    };

    const selectAiImage = (selectedImage, name) => {
        setFieldValue(name, selectedImage.dataTransfer.files.item(0));
    };

    const getAiImages = (imageData) => {
        closeModal();
        if (imageData) {
            setAiErrorMessage(AppConstants.emptyString);
            setAiGeneratedImages(imageData);

            setWebBannerPreview(imageData[0].imageURL);
            selectAiImage(imageData[0], formLabels.promotionImageForWeb.name);

            setTabletBannerPreview(imageData[1].imageURL);
            selectAiImage(imageData[1], formLabels.promotionImageForTablet.name);

            setMobileBannerPreview(imageData[2].imageURL);
            selectAiImage(imageData[2], formLabels.promotionImageForMobile.name);

            setThumbnailPreview(imageData[3].imageURL);
            selectAiImage(imageData[3], formLabels.thumbnailImage.name);
        } else {
            setAiErrorMessage(ErrorMessages.aiImageGenerationError);
            let timer = setTimeout(() => {
                setAiErrorMessage(AppConstants.emptyString);
            }, AppConstants.messageDisappearTime);
            return () => { clearTimeout(timer) };
        }
    };

    const closeModal = () => {
        setShowModal(AppConstants.falseText);
    };

    const handleShowModal = () => {
        setShowModal(AppConstants.trueText);
    }

    const checkValidCost = (event) => {
        let excludedKeywords = ["-", "e", "+", "E", AppConstants.emptyString];
        if (excludedKeywords.includes(event.key)) event.preventDefault();
    };


    const checkValidNumber = (event) => {
        let excludedKeywords = [".", "-", "e", "+", "E", AppConstants.emptyString];
        if (excludedKeywords.includes(event.key)) event.preventDefault();
    }

    const extractFileName = (name) => {
        let url = name.substr(0, name.lastIndexOf("\/"));
        let arr = name.split("/");
        const regex = /[0-9]*-/
        return arr[3]?.replace(regex, "");
    };

    const getDataTransfer = (name) => {
        const dataTransfer = new DataTransfer()
        const file = new File([''], name, { type: AppConstants.contentTypeMap.txt })
        dataTransfer.items.add(file);
        return dataTransfer;
    };

    const getFileExtension = (fileUrl) => {
        return fileUrl?.split(".").pop();
    }

    const downloadFile = async (fileUrl, name, relativePath) => {
        let resp = await FileOperationsUtil.downloadFileWithLoader(fileUrl, `${competitionData.title}-${name}${AppConstants.dot}${getFileExtension(relativePath)}`);
        if (resp) {
            if (name === "Guidelines") {
                setGuidelinesDocumentSpinner(AppConstants.falseText);
            } else if (name === formLabels.thumbnailImage.name) {
                setThumbnailSpinner(AppConstants.falseText);
            } else if (name === formLabels.promotionImageForWeb.name) {
                setPromotionImageWebSpinner(AppConstants.falseText);
            } else if (name === formLabels.promotionImageForTablet.name) {
                setPromotionImageTabletSpinner(AppConstants.falseText);
            } else if (name === formLabels.promotionImageForMobile.name) {
                setPromotionImageMobileSpinner(AppConstants.falseText);
            }
        } else {
            if (name === "Guidelines") {
                setGuidelinesDocumentSpinner(AppConstants.falseText);
            } else if (name === formLabels.thumbnailImage.name) {
                setThumbnailSpinner(AppConstants.falseText);
            } else if (name === formLabels.promotionImageForWeb.name) {
                setPromotionImageWebSpinner(AppConstants.falseText);
            } else if (name === formLabels.promotionImageForTablet.name) {
                setPromotionImageTabletSpinner(AppConstants.falseText);
            } else if (name === formLabels.promotionImageForMobile.name) {
                setPromotionImageMobileSpinner(AppConstants.falseText);
            }
        }
    };

    const handleGuidelinesChange = (value) => {
        let regex = /^<p\s+class="[^"]*"><br><\/p>$/;
        let htmlText = value.replace(regex, AppConstants.emptyEditorString);
        setGuidelines(htmlText);
    };

    return (
        <>
            <div className={classes.formContainer}>
                <Form noValidate validated={validated} onSubmit={handleSubmit} >
                    <Row className="mb-2" >
                        <Form.Group className="mb-2" controlId="basicDetailsTitle">
                            <Form.Label className={status === competitionStatus.upcoming ? `${formClass.formLabel}` : `${formClass.formLabelDisabled}`}>{formLabels.title.label}<span className="redAstrisk">*</span></Form.Label>
                            <Form.Control className={formClass.formInput}
                                required
                                disabled={status !== competitionStatus.upcoming}
                                placeholder="Title for your Competition"
                                type="text" maxLength={100}
                                name={formLabels.title.name}
                                value={values.title} onBlur={handleBlur}
                                onChange={handleChange}
                                isInvalid={touched.title && errors.title}
                            />
                            <Form.Control.Feedback className={classes.errorText} type="invalid" >{errors.title}</Form.Control.Feedback>
                            {values.title?.length > 0 && <span className={classes.remainingChars}>{getRemainingLengthText(100, values.title)}</span>}
                        </Form.Group>
                    </Row>

                    <Row className={`mb-12 mt-2 ${classes.textEditorContainer}`}>
                        <Form.Group className={classes.textEditorContainer} controlId="basicDetailsDescriptionText">
                            <Form.Label className={`${formClass.formLabel} ${classes.textEditorLabel}`}>{formLabels.description.label}<span className="redAstrisk">*</span></Form.Label>
                            <ReactQuill
                                ref={reactQuillRef}
                                readOnly={status !== competitionStatus.upcoming}
                                formats={AppConstants.richTextEditorFormats}
                                onChange={setDescription}
                                value={description} preserveWhitespace={AppConstants.trueText}
                                modules={AppConstants.richTextEditorModules} className={classes.textEditor} theme="snow" placeholder="Description of your competition"
                            />
                            {/* {reactQuillRef && reactQuillRef.current && reactQuillRef.current.unprivilegedEditor && reactQuillRef.current.unprivilegedEditor.getLength() > 1 && 501 - reactQuillRef.current.unprivilegedEditor.getLength() >= 0 && <span className={classes.remainingChars}>{501 - reactQuillRef.current.unprivilegedEditor.getLength()} out of 500 characters remaining</span>}
                            {reactQuillRef && reactQuillRef.current && reactQuillRef.current.unprivilegedEditor && reactQuillRef.current.unprivilegedEditor.getLength() > 1 && 501 - reactQuillRef.current.unprivilegedEditor.getLength() < 0 && <span className={classes.remainingChars}>0 out of 500 characters remaining</span>} */}
                        </Form.Group>
                    </Row>
                    <Row className="mb-2 mt-3">
                        {skills?.length > 1 && <Form.Group className="mb-2" as={Col} controlId="basicDetailsSkill">
                            <Form.Label className={(enrolledParticipants > 0 || status !== competitionStatus.upcoming) ? `${formClass.formLabelDisabled}` : `${formClass.formLabel}`}>{formLabels.skill.label}<span className="redAstrisk">*</span></Form.Label>
                            <Form.Select className={formClass.selectInput}
                                name={formLabels.skill.name}
                                disabled={enrolledParticipants > 0 || status !== competitionStatus.upcoming}
                                value={values.skill} onBlur={handleBlur}
                                onChange={handleChange}
                                isInvalid={touched.skill && errors.skill}
                            ><option value={AppConstants.emptyString} defaultValue>Select Skill</option>
                                {skills?.map(option => (
                                    <option key={option.skillId} value={option.skillId}>
                                        {option.skillName}
                                    </option>
                                ))}
                            </Form.Select>
                            <Form.Control.Feedback className={classes.errorText} type="invalid">{errors.skill}</Form.Control.Feedback>
                        </Form.Group>}
                        {competitionData.clubQrenciaFlg === AppConstants.nText && competitionData.competitionFeeWaiverFlag === AppConstants.nText && <Form.Group className="mb-2" as={Col} controlId="basicDetailsCost">
                            <Form.Label className={(enrolledParticipants > 0 || status !== competitionStatus.upcoming) ? `${formClass.formLabelDisabled}` : `${formClass.formLabel}`}>Cost (INR) <span className="redAstrisk">*</span></Form.Label>
                            <Form.Control className={formClass.formInput}
                                type="number"
                                placeholder="Competition Cost"
                                disabled={enrolledParticipants > 0 || status !== competitionStatus.upcoming}
                                name={formLabels.cost.name}
                                value={values.cost} onBlur={handleBlur}
                                onChange={handleChange}
                                onKeyDown={checkValidCost}
                                isInvalid={touched.cost && errors.cost}
                            />
                            <Form.Control.Feedback className="errorText" type="invalid">{errors.cost}</Form.Control.Feedback>
                        </Form.Group>}
                    </Row>

                    <Row xs={1} sm={2} md={1} lg={2} xl={2} className="mb-2">
                        <Form.Group className={values.date === AppConstants.emptyString ? `mb-2 ${formClass.dateTimeEmptyInput}` : `mb-2 ${formClass.dateTimeInput}`} as={Col} controlId="basicDetailsDate">
                            <Form.Label className={(enrolledParticipants > 0 || status !== competitionStatus.upcoming) ? `${formClass.formLabelDisabled}` : `${formClass.formLabel}`}>{formLabels.date.label}<span className="redAstrisk">*</span></Form.Label>
                            <Form.Control className={formClass.formInput}
                                name={formLabels.date.name}
                                disabled={enrolledParticipants > 0 || status !== competitionStatus.upcoming}
                                type="date"
                                min={new Date().toISOString().split('T')[0]}
                                isInvalid={touched.date && errors.date}
                                value={values.date} onBlur={handleBlur}
                                onChange={handleChange}
                            />
                            <Form.Control.Feedback className={classes.errorText} type="invalid">{errors.date}</Form.Control.Feedback>
                        </Form.Group>
                        <Form.Group className={values.startTime === AppConstants.emptyString ? `mb-2 ${formClass.dateTimeEmptyInput}` : `mb-2 ${formClass.dateTimeInput}`} as={Col} controlId="basicDetailsStarttime">
                            <Form.Label className={(enrolledParticipants > 0 || status !== competitionStatus.upcoming) ? `${formClass.formLabelDisabled}` : `${formClass.formLabel}`}>{formLabels.startTime.label}<span className="redAstrisk">*</span></Form.Label>
                            <Form.Control className={formClass.formInput}
                                name={formLabels.startTime.name}
                                disabled={enrolledParticipants > 0 || status !== competitionStatus.upcoming}
                                type="time"
                                value={values.startTime} onBlur={handleBlur}
                                onChange={handleChange}
                                isInvalid={touched.startTime && errors.startTime}
                            />
                            <Form.Control.Feedback className={classes.errorText} type="invalid">{errors.startTime}</Form.Control.Feedback>
                        </Form.Group>
                    </Row>

                    <Row xs={1} sm={2} md={1} lg={2} xl={2} className="mb-2">
                        <Form.Group className={values.endDate === AppConstants.emptyString ? `mb-2 ${formClass.dateTimeEmptyInput}` : `mb-2 ${formClass.dateTimeInput}`} as={Col} controlId="basicDetailsDate">
                            <Form.Label className={status === competitionStatus.past ? formClass.formLabelDisabled : formClass.formLabel}>{formLabels.endDate.label}<span className="redAstrisk">*</span></Form.Label>
                            <Form.Control className={formClass.formInput}
                                name={formLabels.endDate.name}
                                type="date"
                                disabled={status === competitionStatus.past}
                                min={new Date().toISOString().split('T')[0]}
                                isInvalid={touched.endDate && errors.endDate}
                                value={values.endDate} onBlur={handleBlur}
                                onChange={handleChange}
                            />
                            <Form.Control.Feedback className={classes.errorText} type="invalid">{errors.endDate}</Form.Control.Feedback>
                        </Form.Group>
                        <Form.Group className={values.endTime === AppConstants.emptyString ? `mb-2 ${formClass.dateTimeEmptyInput}` : `mb-2 ${formClass.dateTimeInput}`} as={Col} controlId="basicDetailsEndTime">
                            <Form.Label className={status === competitionStatus.past ? formClass.formLabelDisabled : formClass.formLabel}>{formLabels.endTime.label}<span className="redAstrisk">*</span></Form.Label>
                            <Form.Control className={formClass.formInput}
                                name={formLabels.endTime.name}
                                disabled={status === competitionStatus.past}
                                type="time"
                                value={values.endTime} onBlur={handleBlur}
                                onChange={handleChange}
                                isInvalid={touched.endTime && errors.endTime}
                            />
                            <Form.Control.Feedback className={classes.errorText} type="invalid">{errors.endTime}</Form.Control.Feedback>
                        </Form.Group>
                    </Row>
                    <Row className="mb-2 mt-2">
                        <Form.Group controlId="basicDetailsTitle" className={`${classes.registrationDetails} mb-2 mt-2`}>
                            <Form.Label className={`${formClass.formLabel} ${classes.registrationDetailsLabel}`}>Registration window <RiInformationFill className={classes.infoButton} data-tooltip-id="registrationEndInfoIcon" /></Form.Label>
                            <Tooltip className={classes.tooltipMessage} id="registrationEndInfoIcon"><span className={classes.tooltipMessage}>{AppConstants.competitionRegistrationDeadlineInfo}</span></Tooltip>
                        </Form.Group>
                    </Row>

                    <Row xs={1} sm={2} md={2} lg={2} xl={2} className="mb-2">
                        <Form.Group className={values.registrationClosingDate === AppConstants.emptyString ? `mb-2 ${formClass.dateTimeEmptyInput}` : `mb-2 ${formClass.dateTimeInput}`} as={Col} controlId="basicDetailsRegistrationDate">
                            <Form.Label className={status === competitionStatus.past ? formClass.formLabelDisabled : formClass.formLabel}>{formLabels.registrationClosingDate.label}{(registrationTimeAdded || registrationDateAdded) && <span className="redAstrisk">*</span>}</Form.Label>
                            <Form.Control className={formClass.formInput}
                                name={formLabels.registrationClosingDate.name}
                                disabled={status === competitionStatus.past}
                                type="date"
                                min={new Date().toISOString().split('T')[0]}
                                isInvalid={touched.registrationClosingDate && errors.registrationClosingDate}
                                value={values.registrationClosingDate} onBlur={handleBlur}
                                onChange={(e) => changeRegistrationClosingDate(e)}
                            />
                            <Form.Control.Feedback className={classes.errorText} type="invalid">{errors.registrationClosingDate}</Form.Control.Feedback>
                        </Form.Group>
                        <Form.Group className={values.registrationClosingTime === AppConstants.emptyString ? `mb-2 ${formClass.dateTimeEmptyInput}` : `mb-2 ${formClass.dateTimeInput}`} as={Col} controlId="registrationClosingTime">
                            <Form.Label className={status === competitionStatus.past ? formClass.formLabelDisabled : formClass.formLabel}>{formLabels.registrationClosingTime.label}{(registrationTimeAdded || registrationDateAdded) && <span className="redAstrisk">*</span>}</Form.Label>
                            <Form.Control className={formClass.formInput}
                                name={formLabels.registrationClosingTime.name}
                                disabled={status === competitionStatus.past}
                                type="time"
                                value={values.registrationClosingTime} onBlur={handleBlur}
                                onChange={(e) => changeRegistrationClosingTime(e)}
                                isInvalid={touched.registrationClosingTime && errors.registrationClosingTime}
                            />
                            <Form.Control.Feedback className={classes.errorText} type="invalid">{errors.registrationClosingTime}</Form.Control.Feedback>
                        </Form.Group>
                    </Row>
                    <div className={classes.switchContainer}>
                        <div className={classes.switchText}>Select result declaration date <RiInformationFill className={classes.infoButton} data-tooltip-id="resultDeclareInfo" /><span><input className={classes.switchInput} disabled={!props.rubricsConfigured} checked={resultDeclareCheckbox} type="checkbox" onChange={() => changeResultDeclareRowStatus(!showResultDeclareRow)} /></span>
                        </div>
                        <Tooltip className={classes.tooltipMessage} id="resultDeclareInfo"><span className={classes.tooltipMessage}>{AppConstants.resultDeclareInfoText}</span></Tooltip>
                    </div>

                    {showResultDeclareRow && <Row xs={1} sm={2} md={2} lg={2} xl={2} className="mb-2">
                        <Form.Group as={Col} className="mb-2" controlId="basicDetailsSubmissions">
                            <Form.Label className={` ${classes.radioLabel}`}>{formLabels.resultDeclareBy.label}{(resultDateAdded || resultByAdded) && <span className="redAstrisk">*</span>}</Form.Label>
                            <Row>
                                <Form.Check
                                    className={`${classes.radioLabelButton} ${classes.formcheck}`}
                                    type="radio"
                                    disabled={competitionData.completionStatus === competitionStatus.complete || competitionData.resultDeclaredFlag === AppConstants.yText}
                                    readOnly={competitionData.completionStatus === competitionStatus.complete || competitionData.resultDeclaredFlag === AppConstants.yText}
                                    label="On"
                                    name={formLabels.resultDeclareBy.name}
                                    id="result_declare_on"
                                    value="On"
                                    checked={values.resultDeclareBy === "On"}
                                    onChange={changeResultDeclareBy}
                                />
                                <Form.Check
                                    className={`${classes.radioLabelButton} ${classes.formcheck}`}
                                    type="radio"
                                    label="Before"
                                    disabled={competitionData.completionStatus === competitionStatus.complete || competitionData.resultDeclaredFlag === AppConstants.yText}
                                    readOnly={competitionData.completionStatus === competitionStatus.complete || competitionData.resultDeclaredFlag === AppConstants.yText}
                                    name={formLabels.resultDeclareBy.name}
                                    id="result_declare_before"
                                    value="Before"
                                    checked={values.resultDeclareBy === "Before"}
                                    onChange={changeResultDeclareBy}
                                />
                            </Row>
                        </Form.Group>
                        <Form.Group className={values.resultDeclareDate === AppConstants.emptyString ? `mb-2 ${formClass.dateTimeEmptyInput}` : `mb-2 ${formClass.dateTimeInput}`} as={Col} controlId="basicDetailsResultDeclareDate">
                            <Form.Label className={formClass.formLabel}>{formLabels.resultDeclareDate.label}{(resultDateAdded || resultByAdded) && <span className="redAstrisk">*</span>}</Form.Label>
                            <Form.Control className={formClass.formInput}
                                name={formLabels.resultDeclareDate.name}
                                type="date"
                                disabled={competitionData.completionStatus === competitionStatus.complete || competitionData.resultDeclaredFlag === AppConstants.yText}
                                min={new Date().toISOString().split('T')[0]}
                                isInvalid={touched.resultDeclareDate && errors.resultDeclareDate}
                                value={values.resultDeclareDate} onBlur={handleBlur}
                                onChange={changeResultDeclareDate}
                            />
                            <Form.Control.Feedback className={classes.errorText} type="invalid">{errors.resultDeclareDate}</Form.Control.Feedback>
                        </Form.Group>
                    </Row>}


                    <Row className="mb-2">
                        <Form.Group className="mb-2" as={Col} controlId="basicDetailsType">
                            <Form.Label className={(enrolledParticipants > 0 || (status === competitionStatus.past)) ? formClass.formLabelDisabled : formClass.formLabel}>{formLabels.competitionType.label}<span className="redAstrisk">*</span></Form.Label>
                            <Form.Select className={formClass.selectInput}
                                name={formLabels.competitionType.name}
                                disabled={enrolledParticipants > 0 || (status === competitionStatus.past)}
                                value={values.competitionType} onBlur={handleBlur}
                                onChange={(event) => changeCompetitionType(event)}
                                isInvalid={touched.competitionType && errors.competitionType}
                            ><option value={AppConstants.emptyString} defaultValue>Select Type</option>
                                {competitionTypes?.map((type, i) => (
                                    <option key={i} value={type}>
                                        {type}
                                    </option>
                                ))}
                            </Form.Select>
                            <Form.Control.Feedback className={classes.errorText} type="invalid">{errors.competitionType}</Form.Control.Feedback>
                        </Form.Group>

                        {values.competitionType === competitionTypes[1] && <Form.Group className="mb-2" as={Col} controlId="basicDetailVenue">
                            <Form.Label className={(enrolledParticipants > 0 || (status === competitionStatus.past)) ? formClass.formLabelDisabled : formClass.formLabel}>{formLabels.geoLocation.label}<span className="redAstrisk">*</span></Form.Label>
                            <Form.Control className={formClass.formInput}
                                type="url"
                                placeholder="Competition Venue Location URL"
                                name={formLabels.geoLocation.name}
                                disabled={enrolledParticipants > 0 || (status === competitionStatus.past)}
                                value={values.geoLocation} onBlur={handleBlur}
                                onChange={handleChange}
                                isInvalid={touched.geoLocation && errors.geoLocation} />
                            <Form.Control.Feedback className={classes.errorText} type="invalid">{errors.geoLocation}</Form.Control.Feedback>
                        </Form.Group>}
                        {values.competitionType === competitionTypes[0] && <Form.Group className="mb-2" as={Col} controlId="basicDetailsVcLink">
                            <Form.Label className={(status === competitionStatus.past) ? formClass.formLabelDisabled : formClass.formLabel}>{formLabels.vcLink.label}</Form.Label>
                            <Form.Control className={formClass.formInput}
                                type="url"
                                placeholder="Video Conferencing link"
                                name={formLabels.vcLink.name}
                                disabled={status === competitionStatus.past}
                                value={values.vcLink} onBlur={handleBlur}
                                onChange={handleChange}
                                isInvalid={touched.vcLink && errors.vcLink} />
                            <Form.Control.Feedback className={classes.errorText} type="invalid">{errors.vcLink}</Form.Control.Feedback>
                        </Form.Group>}
                    </Row>
                    {values.competitionType === competitionTypes[1] && <Row>
                        <Form.Group className="mb-2" controlId="basicDetailsVenueLocation">
                            <Form.Label className={(enrolledParticipants > 0 || (status === competitionStatus.past)) ? formClass.formLabelDisabled : formClass.formLabel}>{formLabels.venueLocation.label}<span className="redAstrisk">*</span></Form.Label>
                            <Form.Control className={formClass.formInput}
                                name={formLabels.venueLocation.name}
                                as="textarea"
                                placeholder="Address"
                                disabled={enrolledParticipants > 0 || (status === competitionStatus.past)}
                                isInvalid={touched.venueLocation && errors.venueLocation}
                                value={values.venueLocation} onBlur={handleBlur}
                                onChange={handleChange} />
                            <Form.Control.Feedback className={classes.errorText} type="invalid"> {errors.venueLocation} </Form.Control.Feedback>
                        </Form.Group>
                    </Row>}

                    <Row className="mb-2">
                        <Form.Group className="mb-2" as={Col} controlId="basicDetailsMaxLimit">
                            <Form.Label className={status === competitionStatus.past ? formClass.formLabelDisabled : formClass.formLabel}>{formLabels.allowedNumberOfParticipants.label}<span className="redAstrisk">*</span></Form.Label>
                            <Form.Control className={formClass.formInput}
                                type="number"
                                placeholder="Max Limit"
                                disabled={status === competitionStatus.past}
                                name={formLabels.allowedNumberOfParticipants.name}
                                value={values.allowedNumberOfParticipants} onBlur={handleBlur}
                                onKeyDown={checkValidNumber}
                                onChange={handleChange}
                                isInvalid={touched.allowedNumberOfParticipants && errors.allowedNumberOfParticipants}
                            />
                            <Form.Control.Feedback className={classes.errorText} type="invalid">{errors.allowedNumberOfParticipants}</Form.Control.Feedback>
                        </Form.Group>
                    </Row>

                    <Row className="mb-2" >
                        <Form.Group className="mb-2" as={Col} controlId="basicDetailsMinAge">
                            <Form.Label className={(enrolledParticipants > 0 || status === competitionStatus.past) ? formClass.formLabelDisabled : formClass.formLabel}>{formLabels.minAge.label}</Form.Label>
                            <Form.Control type="number" className={formClass.formInput}
                                placeholder="Min Age"
                                disabled={enrolledParticipants > 0 || status === competitionStatus.past}
                                name={formLabels.minAge.name}
                                onKeyDown={checkValidNumber}
                                value={values.minAge} onBlur={handleBlur}
                                onChange={handleChange}
                                isInvalid={touched.minAge && errors.minAge}
                            />
                            <Form.Control.Feedback className={classes.errorText} type="invalid">{errors.minAge}</Form.Control.Feedback>
                        </Form.Group>

                        <Form.Group className="mb-2" as={Col} controlId="basicDetailsMaxAge">
                            <Form.Label className={(enrolledParticipants > 0 || status === competitionStatus.past) ? formClass.formLabelDisabled : formClass.formLabel}>{formLabels.maxAge.label}</Form.Label>
                            <Form.Control className={formClass.formInput}
                                type="number"
                                placeholder="Max Age"
                                disabled={enrolledParticipants > 0 || status === competitionStatus.past}
                                name={formLabels.maxAge.name}
                                onKeyDown={checkValidNumber}
                                value={values.maxAge} onBlur={handleBlur}
                                onChange={handleChange}
                                isInvalid={touched.maxAge && errors.maxAge}
                            />
                            <Form.Control.Feedback className={classes.errorText} type="invalid">{errors.maxAge}</Form.Control.Feedback>
                        </Form.Group>
                    </Row>
                    <Row className={classes.radioDiv}>
                        <Form.Group as={Col} className="mb-2" controlId="basicDetailsSubmissions">
                            <Form.Label className={` ${classes.radioLabel}`}>{formLabels.submission.label}</Form.Label>
                            <Row>
                                <Form.Check
                                    className={`${classes.radioLabelButton} ${classes.formcheck}`}
                                    type="radio"
                                    label="No"
                                    name={formLabels.submission.name}
                                    id="submission_falg_no"
                                    readOnly={(enrolledParticipants > 0 && status === competitionStatus.ongoing) || status === competitionStatus.past}
                                    disabled={(enrolledParticipants > 0 && status === competitionStatus.ongoing) || status === competitionStatus.past}
                                    value="N"
                                    checked={values.submission === "N"}
                                    onChange={handleChange}
                                />
                                <Form.Check
                                    className={`${classes.radioLabelButton} ${classes.formcheck}`}
                                    type="radio"
                                    label="Yes"
                                    name={formLabels.submission.name}
                                    readOnly={(enrolledParticipants > 0 && status === competitionStatus.ongoing) || status === competitionStatus.past}
                                    disabled={(enrolledParticipants > 0 && status === competitionStatus.ongoing) || status === competitionStatus.past}
                                    id="submission_falg_yes"
                                    value="Y"
                                    checked={values.submission === "Y"}
                                    onChange={handleChange}
                                />
                            </Row>
                        </Form.Group>
                    </Row>
                    <Row className={`mb-12 mt-2 ${classes.textEditorContainer} ${((guidelinesPdf && guidelinesPdf.name?.length) || uploadedGuidelinesDocument || ((enrolledParticipants > 0 && status == competitionStatus.ongoing) || status === competitionStatus.past)) && "textEditorInactive"}`}>
                        <Form.Group className={classes.textEditorContainer} controlId="basicDetailsGuidelinesText">
                            <Form.Label className={`${formClass.formLabel} ${classes.textEditorLabel}`}>{formLabels.guidelines.label}</Form.Label>
                            <ReactQuill formats={AppConstants.richTextEditorFormats} modules={AppConstants.richTextEditorModules} readOnly={(guidelinesPdf && guidelinesPdf.name?.length > 0) || uploadedGuidelinesDocument || ((enrolledParticipants > 0 && status == competitionStatus.ongoing) || status == competitionStatus.past)} className={classes.textEditor} theme="snow" placeholder="Enter competition Guidelines"
                                value={guidelines} preserveWhitespace={AppConstants.trueText}
                                onChange={handleGuidelinesChange} />
                        </Form.Group>
                    </Row>
                    {guidelines === AppConstants.emptyEditorString && !guidelinesPdf?.size && <h4 className={classes.separatorLine}>OR</h4>}
                    <Row className='mt-2 mb-5'>
                        <div className={classes.uploadFileRow}>
                            <FileUploadInput id="guidelinesPdfInput" name="guidelines_file" disabled={guidelines !== AppConstants.emptyEditorString || ((enrolledParticipants > 0 && status == competitionStatus.ongoing) || status === competitionStatus.past)} accept=".pdf" label={formLabels.uploadGuidelines.label} handleFileChange={e => { setGuidelinesPdf(e.target.files[0]); setUploadedGuidelinesDocument(AppConstants.emptyString) }} inputFile={guidelinesPdf}
                                defaultFile={uploadedGuidelinesDocument ? getDataTransfer(extractFileName(competitionData.guidelinesDocumentRelativePath) || "Guidelines_Document").files[0] : ""} />
                            {competitionData.guidelinesDocumentPath && <button type="button" onClick={() => { downloadFile(competitionData.guidelinesDocumentPath, "Guidelines", competitionData.guidelinesDocumentRelativePath); setGuidelinesDocumentSpinner(AppConstants.trueText) }} data-tooltip-id="downloadGuidelines" className={guidelinesError ? `${classes.removeBtn} ${classes.iconError}` : classes.removeBtn}>{guidelinesDocumentSpinner ? <BiLoaderAlt className="spinner" /> : <AiOutlineDownload />}</button>}
                            <button type="button" disabled={((enrolledParticipants > 0 && status == competitionStatus.ongoing) || status === competitionStatus.past)} onClick={() => { removeFile("guidelinesPdfInput"); setUploadedGuidelinesDocument(AppConstants.emptyString); }} data-tooltip-id="removeGuideline" className={((enrolledParticipants > 0 && status == competitionStatus.ongoing) || status === competitionStatus.past) ? `${classes.removeBtn} ${classes.removeBtnInactive}` : classes.removeBtn}><MdDelete /></button>
                            {!((enrolledParticipants > 0 && status == competitionStatus.ongoing) || status === competitionStatus.past) && <Tooltip className={classes.tooltipMessage} id="removeGuideline"><span className={classes.tooltipMessage}>Remove file</span></Tooltip>}
                            <Tooltip className={classes.tooltipMessage} id="downloadGuidelines"><span className={classes.tooltipMessage}>Download file</span></Tooltip>
                        </div>
                        {guidelinesError && <div className={classes.errorText}>{guidelinesError}</div>}
                    </Row>
                    <Row className="mb-2">
                        <div className={classes.uploadFileRow}>
                            <div>
                                <FileUploadInput id={formLabels.thumbnailImage.name} name={formLabels.thumbnailImage.name} accept="image/png,image/jpeg,image/jpg" label={formLabels.thumbnailImage.label} handleFileChange={(e) => { setFieldValue("thumbnailImage", e.target.files[0]); setUploadedThumbnailImage(AppConstants.emptyString) }} inputFile={values.thumbnailImage} infoMessage={AppConstants.expertCreateCompetitionsThumbnailMessage}
                                    disabled={status !== competitionStatus.upcoming} defaultFile={uploadedThumbnailImage && getDataTransfer(extractFileName(competitionData.thumbnailImageRelativePath) || "Thumbnail_Image").files[0]} />
                                {errors.thumbnailImage && <div className="errorText">{errors.thumbnailImage}</div>}
                            </div>
                            {competitionData.thumbnailImagePath && <button type="button" onClick={() => { downloadFile(competitionData.thumbnailImagePath, formLabels.thumbnailImage.name, competitionData.thumbnailImageRelativePath); setThumbnailSpinner(AppConstants.trueText) }} data-tooltip-id="downloadThumbnail" className={errors.thumbnailImage ? `${classes.removeBtn} ${classes.iconError}` : classes.removeBtn}>{thumbnailSpinner ? <BiLoaderAlt className="spinner" /> : <AiOutlineDownload />}</button>}
                            <button disabled={status !== competitionStatus.upcoming} type="button" onClick={() => { removeFile(formLabels.thumbnailImage.name); setUploadedThumbnailImage(AppConstants.emptyString) }} data-tooltip-id="removeThumbnail" className={(status !== competitionStatus.upcoming) ? `${classes.removeBtn} ${classes.removeBtnInactive}` : classes.removeBtn}><MdDelete /></button>
                            {(status === competitionStatus.upcoming) && <Tooltip className={classes.tooltipMessage} id="removeThumbnail"><span className={classes.tooltipMessage}>Remove file</span></Tooltip>}
                            <Tooltip className={classes.tooltipMessage} id="downloadThumbnail"><span className={classes.tooltipMessage}>Download file</span></Tooltip>
                        </div>
                        {thumbnailPreview && <div className={classes.previewContainer}>
                            <img data-tooltip-id="selectThumbnailImage" onClick={() => selectAiImage(aiGeneratedImages[3], formLabels.thumbnailImage.name)} className={classes.thumbnailPreviewImage} src={thumbnailPreview} alt="thumbnailImagePreview" />
                        </div>}
                        {(thumbnailPreview && thumbnailImageInput?.files !== aiGeneratedImages[3].dataTransfer.files) && <Tooltip className={classes.tooltipMessage} id="selectThumbnailImage"><span className={classes.tooltipMessage}>Click to select this image</span></Tooltip>}
                    </Row>
                    <Row className="mb-2">
                        <div className={classes.uploadFileRow}>
                            <Form.Group className={`${formClass.fileUpload} mb-2`} as={Col} >
                                <FileUploadInput id={formLabels.promotionImageForWeb.name} name={formLabels.promotionImageForWeb.name} accept="image/png,image/jpeg,image/jpg" label={formLabels.promotionImageForWeb.label} handleFileChange={(e) => { setFieldValue(formLabels.promotionImageForWeb.name, e.target.files[0]); setUploadedPromotionImageWeb(AppConstants.emptyString) }} inputFile={values.promotionImageForWeb} infoMessage={AppConstants.expertCreateCompetitionsWebBannerMessage}
                                    disabled={status !== competitionStatus.upcoming} defaultFile={uploadedPromotionImageWeb ? getDataTransfer(extractFileName(competitionData.promotionImageWebRelativePath) || "Promotion_Image_Web").files[0] : ""} />
                                {errors.promotionImageForWeb && <div className="errorText">{errors.promotionImageForWeb}</div>}
                            </Form.Group>
                            {competitionData.promotionImagePathWeb && <button type="button" onClick={() => { downloadFile(competitionData.promotionImagePathWeb, formLabels.promotionImageForWeb.name, competitionData.promotionImageWebRelativePath); setPromotionImageWebSpinner(AppConstants.trueText) }} data-tooltip-id="downloadWebImage" className={errors.promotionImageForWeb ? `${classes.removeBtn} ${classes.iconError}` : classes.removeBtn}>{promotionImageWebSpinner ? <BiLoaderAlt className="spinner" /> : <AiOutlineDownload />}</button>}
                            <button disabled={(status !== competitionStatus.upcoming)} type="button" onClick={() => { removeFile(formLabels.promotionImageForWeb.name); setUploadedPromotionImageWeb(AppConstants.emptyString) }} data-tooltip-id="removeWebImage" className={(status !== competitionStatus.upcoming) ? `${classes.removeBtn} ${classes.removeBtnInactive}` : classes.removeBtn}><MdDelete /></button>
                            {(status === competitionStatus.upcoming) && <Tooltip className={classes.tooltipMessage} id="removeWebImage"><span className={classes.tooltipMessage}>Remove file</span></Tooltip>}
                            <Tooltip className={classes.tooltipMessage} id="downloadWebImage"><span className={classes.tooltipMessage}>Download file</span></Tooltip>
                        </div>
                        {webBannerPreview && <div className={classes.previewContainer}>
                            <img data-tooltip-id="selectWebImage" onClick={() => selectAiImage(aiGeneratedImages[0], formLabels.promotionImageForWeb.name)} className={classes.webPreviewImage} src={webBannerPreview} alt="webImagePreview" />
                        </div>}
                        {(webBannerPreview && webBannerInput?.files !== aiGeneratedImages[0].dataTransfer.files) && <Tooltip className={classes.tooltipMessage} id="selectWebImage"><span className={classes.tooltipMessage}>Click to select this image</span></Tooltip>}
                    </Row>
                    <Row className="mb-2">
                        <div className={classes.uploadFileRow}>
                            <Form.Group className={`${formClass.fileUpload} mb-2`} as={Col}>
                                <FileUploadInput id={formLabels.promotionImageForTablet.name} name={formLabels.promotionImageForTablet.name} accept="image/png,image/jpeg,image/jpg" label={formLabels.promotionImageForTablet.label} handleFileChange={(e) => { setFieldValue(formLabels.promotionImageForTablet.name, e.target.files[0]); setUploadedPromotionImageTablet(AppConstants.emptyString) }} inputFile={values.promotionImageForTablet} infoMessage={AppConstants.expertCreateCompetitionsTabletBannerMessage}
                                    disabled={status !== competitionStatus.upcoming} defaultFile={uploadedPromotionImageTablet ? getDataTransfer(extractFileName(competitionData.promotionImageTabletRelativePath) || "Promotion_Image_Tablet").files[0] : ""} />
                                {errors.promotionImageForTablet && <div className="errorText">{errors.promotionImageForTablet}</div>}
                            </Form.Group>
                            {competitionData.promotionImagePathTablet && <button type="button" onClick={() => { downloadFile(competitionData.promotionImagePathTablet, formLabels.promotionImageForTablet.name, competitionData.promotionImageTabletRelativePath); setPromotionImageTabletSpinner(AppConstants.trueText) }} data-tooltip-id="downloadTabletImage" className={errors.promotionImageForTablet ? `${classes.removeBtn} ${classes.iconError}` : classes.removeBtn}>{promotionImageTabletSpinner ? <BiLoaderAlt className="spinner" /> : <AiOutlineDownload />}</button>}
                            <button disabled={(status !== competitionStatus.upcoming)} type="button" onClick={() => { removeFile(formLabels.promotionImageForTablet.name); setUploadedPromotionImageTablet(AppConstants.emptyString) }} data-tooltip-id="removeTabletImage" className={(status !== competitionStatus.upcoming) ? `${classes.removeBtn} ${classes.removeBtnInactive}` : classes.removeBtn}><MdDelete /></button>
                            {(status === competitionStatus.upcoming) && <Tooltip className={classes.tooltipMessage} id="removeTabletImage"><span className={classes.tooltipMessage}>Remove file</span></Tooltip>}
                            <Tooltip className={classes.tooltipMessage} id="downloadTabletImage"><span className={classes.tooltipMessage}>Download file</span></Tooltip>
                        </div>

                        {tabletBannerPreview && <div data-tooltip-id="selectThumbnail" className={classes.previewContainer}>
                            <img data-tooltip-id="selectTabletImage" onClick={(e) => selectAiImage(aiGeneratedImages[1], formLabels.promotionImageForTablet.name)} className={classes.tabPreviewImage} src={tabletBannerPreview} alt="tabletImagePreview" />
                        </div>}
                        {tabletBannerPreview && tabletBannerInput?.files !== aiGeneratedImages[1].dataTransfer.files && <Tooltip className={classes.tooltipMessage} id="selectTabletImage"><span className={classes.tooltipMessage}>Click to select this image</span></Tooltip>}
                    </Row>
                    <Row className="mb-2">
                        <div className={classes.uploadFileRow}>
                            <Form.Group className={`${formClass.fileUpload} mb-2`} as={Col}>
                                <FileUploadInput id={formLabels.promotionImageForMobile.name} name={formLabels.promotionImageForMobile.name} accept="image/png,image/jpeg,image/jpg" label={formLabels.promotionImageForMobile.label} handleFileChange={(e) => { setFieldValue(formLabels.promotionImageForMobile.name, e.target.files[0]); setUploadedPromotionImageMobile(AppConstants.emptyString) }} inputFile={values.promotionImageForMobile} infoMessage={AppConstants.expertCreateCompetitionsMobileBannerMessage}
                                    disabled={status !== competitionStatus.upcoming} defaultFile={uploadedPromotionImageMobile ? getDataTransfer(extractFileName(competitionData.promotionImageMobileRelativePath) || "Promotion_Image_Mobile").files[0] : ""} />
                                {errors.promotionImageForMobile && <div className="errorText">{errors.promotionImageForMobile}</div>}
                            </Form.Group>
                            {competitionData.promotionImagePathMobile && <button type="button" onClick={() => { downloadFile(competitionData.promotionImagePathMobile, formLabels.promotionImageForMobile.name, competitionData.promotionImageMobileRelativePath); setPromotionImageMobileSpinner(AppConstants.trueText); }} data-tooltip-id="downloadMoblieImage" className={errors.promotionImageForMobile ? `${classes.removeBtn} ${classes.iconError}` : classes.removeBtn}>{promotionImageMobileSpinner ? <BiLoaderAlt className="spinner" /> : <AiOutlineDownload />}</button>}
                            <button disabled={(status !== competitionStatus.upcoming)} type="button" onClick={() => { removeFile(formLabels.promotionImageForMobile.name); setUploadedPromotionImageMobile(AppConstants.emptyString) }} data-tooltip-id="removeMobileImage" className={(status !== competitionStatus.upcoming) ? `${classes.removeBtn} ${classes.removeBtnInactive}` : classes.removeBtn}><MdDelete /></button>
                            {(status === competitionStatus.upcoming) && <Tooltip className={classes.tooltipMessage} id="removeMobileImage"><span className={classes.tooltipMessage}>Remove file</span></Tooltip>}
                            <Tooltip className={classes.tooltipMessage} id="downloadMoblieImage"><span className={classes.tooltipMessage}>Download file</span></Tooltip>
                        </div>
                        {mobileBannerPreview && <div className={classes.previewContainer}>
                            <img data-tooltip-id="selectMobileImage" onClick={() => selectAiImage(aiGeneratedImages[2], formLabels.promotionImageForMobile.name)} className={classes.mobilePreviewImage} src={mobileBannerPreview} alt="mobileImagePreview" />
                        </div>}
                        {(mobileBannerPreview && mobileBannerInput?.files !== aiGeneratedImages[2].dataTransfer.files) && <Tooltip className={classes.tooltipMessage} id="selectMobileImage"><span className={classes.tooltipMessage}>Click to select this image</span></Tooltip>}
                    </Row>

                    {((values.title?.trim() !== imageGeneratedArgs.competitionName || values.skill !== imageGeneratedArgs.skillName) && Auth.getLoggedInUserDetails()?.generativeAiAccessDetails?.competitionAiAccess === AppConstants.trueText) && <Row className="mb-2">
                        {<h4 className={classes.separatorLine}>OR</h4>}
                        <Form.Group>
                            <Form.Label className={classes.aiGenerateImageText}>Let AI generate the promotional images for you!</Form.Label>
                        </Form.Group>
                        <div className={classes.defaultAiImageState}>
                            <img src={defaultImagePath} />
                        </div>
                        <Tooltip className={classes.tooltipMessage} id="generateAiImageInfo"><span className={classes.tooltipMessage}></span></Tooltip>
                        <div data-tooltip-id="generateImageButtonTooltip" className={classes.generateImageBtn}>
                            <GenerateAiImagesComponent disabled={disableImageGeneration() || status !== competitionStatus.upcoming} getAiImages={getAiImages} payload={{ competitionName: values.title?.trim(), skillName: values.skill }} skills={skills} setImageGeneratedArgs={setImageGeneratedArgs} handleShowModal={handleShowModal} />
                        </div>
                        {(disableImageGeneration()) && <Tooltip className={classes.tooltipMessage} id="generateImageButtonTooltip"><span className={classes.tooltipMessage}>Enter a competition title and select a skill to generate the images</span></Tooltip>}
                        {/* {(status !== competitionStatus.upcoming) && <Tooltip className={classes.tooltipMessage} id="generateImageButtonTooltip"><span className={classes.tooltipMessage}>Images cannot be generated once the competition has started</span></Tooltip>} */}
                    </Row>}
                    {aiErrorMessage && <ErrorSuccessAlertMessage message={aiErrorMessage} varient={AppConstants.alertVarient[1]} />}

                    <div className={classes.nextBtn}>
                        <Button type="submit" onClick={() => handleFormSubmit(errors)} disabled={formSubmitting}>{AppConstants.nextText}</Button>
                    </div>
                </Form>

                <Modal show={showModal} onHide={closeModal} backdropClassName={classes.modal} className="aiGenerateImageModal" backdrop="static" keyboard={false} centered>
                    <Modal.Body className={classes.modalBody}>
                        <div className={classes.preLoaderContainer}>
                            <div className={classes.preLoaderDiv}>
                                <div className={classes.loader}></div>
                                <img className={classes.qrenciaGlobe} src={qrenciaGlobeImage} alt="Qrencia globe" />
                                <p className={classes.loaderText}>Please wait while we are generating the creatives for you..</p>
                            </div>
                        </div>
                        <div className={classes.modalText}>
                        </div>
                    </Modal.Body>
                </Modal>
            </div >
        </>
    )
};
