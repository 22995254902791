import React, { useEffect, useState } from "react";
import { Button, Col, Form, Row, Table } from "react-bootstrap";
import { AppConstants } from "../../../constants/AppConstants";
import classes from "./AddGymnasticsJudgementParameters.module.css";
import { AuthorizedDataService } from "../../../services/AuthorizedDataService";
import formClass from "../../../common/FormStyling.module.css";
import { ErrorMessages } from "../../../constants/ErrorMessages";
import { StringUtils } from "../../../utils/StringUtils";
import { FormValidationUtils } from "../../../utils/FormValidationUtils";

export const AddGymnasticsJudgementParameters = (props) => {
    const { saveCurrentStep, changeRubricsData, skillId, stage, previousRubricsDetails, previousSkill, selectedShowPercentageFlag } = props;
    const stages = AppConstants.createCompetitionActions;
    const [rubrics, setRubrics] = useState([]);
    const [judgingParameters, setJudgingParameters] = useState([]);
    const [errors, setErrors] = useState([]);
    const [showPercentageFlag, setShowPercentageFlag] = useState(AppConstants.nText);
    const [outOfScore, setOutOfScore] = useState(AppConstants.emptyString);
    const rubricsTableHeaders = AppConstants.rubricsTableHeaders;
    const formLabels = AppConstants.gymnasticsJudgementLabels;

    useEffect(() => {
        const changeParentState = () => {
            changeRubricsData({ rubrics, rubricsConfigured: hasIncludedAspect() > 0 ? AppConstants.trueText : AppConstants.falseText, rubricsErrors: errors, outOfScore, showPercentageFlag });
        }

        changeParentState();
    }, [rubrics, errors, outOfScore, showPercentageFlag]);

    useEffect(() => {
        let updatedParameters = [];
        const previousRubricsLength = previousRubricsDetails.length;
        if (previousRubricsLength > 0) {
            setShowPercentageFlag(selectedShowPercentageFlag);
            if (selectedShowPercentageFlag === AppConstants.yText) {
                setOutOfScore(previousRubricsDetails[0].levelWiseOutOfValue);
            } else {
                setOutOfScore(AppConstants.emptyString);
            }

            updatedParameters = judgingParameters.map(parameter => {
                const matchingPreviousAspect = previousRubricsDetails.find(previous => (previous.aspectDescription === parameter.aspectDescription && previous.criteriaId === parameter.criterionId));
                if (matchingPreviousAspect) {
                    return {
                        ...parameter,
                        aspectMarks: matchingPreviousAspect.aspectMarks,
                        gender: matchingPreviousAspect.gender,
                        isAspectIncluded: AppConstants.trueText
                    }
                } else {
                    return { ...parameter, isAspectIncluded: AppConstants.falseText }
                }
            });
        } else {
            updatedParameters = [...judgingParameters];
            setShowPercentageFlag(AppConstants.nText);
        }

        const getAspectValue = (aspect) => {
            return {
                aspectUniqueId: aspect.aspectUniqueId,
                aspectDescription: aspect.aspectDescription,
                included: previousRubricsLength > 0 ? aspect.isAspectIncluded : AppConstants.trueText
            }
        }

        const rbx = updatedParameters.reduce((acc, aspect) => {
            const existingCriterion = acc.find(criterion => criterion.criterionId === aspect.criterionId);

            if (existingCriterion) {
                existingCriterion.aspects.push(getAspectValue(aspect));
            } else {
                acc.push({
                    criterionId: aspect.criterionId,
                    criterionUniqueId: aspect.criterionUniqueId,
                    criterionName: aspect.criterionName,
                    skillId: aspect.skillId,
                    gender: aspect.gender || AppConstants.emptyString,
                    isCriteriaIncluded: previousRubricsDetails.length > 0 ? aspect.isAspectIncluded : previousSkill ? AppConstants.falseText : AppConstants.trueText,
                    aspects: [getAspectValue(aspect)],
                });
            }

            return acc;
        }, []);

        setRubrics(rbx);
        setErrors(rbx.map((criteria) => ({
            gender: (previousSkill) ? AppConstants.emptyString : StringUtils.getParsedMessage(ErrorMessages.gymnasticsRubricsErrors.gender, { criteriaName: criteria.criterionName }),
        })));

    }, [judgingParameters, previousRubricsDetails, selectedShowPercentageFlag]);

    useEffect(() => {
        const fetchJudgingParametersForSkill = async () => {
            try {
                const url = `${AppConstants.getJudgingParametersForSkillApi}${skillId}`
                let response = await AuthorizedDataService.getRequest(
                    url,
                    AppConstants.emptyString,
                    AppConstants.emptyString
                );
                if (response) setJudgingParameters(response);
                else setJudgingParameters([]);
            } catch (error) {
                setJudgingParameters([]);
            }
        }


        if (skillId) {
            fetchJudgingParametersForSkill();
        } else {
            setJudgingParameters([]);
        }
    }, [skillId]);

    const hasIncludedAspect = () => rubrics.some(criterion => criterion.isCriteriaIncluded) ? 1 : 0;

    const handleMaxDScoreChange = (event) => {
        const { value } = event.target;

        if (Number(value) <= 0 && value !== AppConstants.emptyString) {
            event.preventDefault();
            return;
        }

        setOutOfScore(value)
    }

    // Method to update the text field values for particular criteria
    const handleChange = (event, rbx, idx) => {
        const { name, value } = event.target;
        if (name !== formLabels.gender.name && Number(value) < 0) {
            event.preventDefault();
            return;
        }

        const updatedRbx = { ...rbx, [name]: value };
        setRubrics(rubrics.map((r, i) => (i === idx ? updatedRbx : r)));

        if (value !== AppConstants.emptyString) {
            setErrors(errors.map((error, i) => (i === idx ? { ...error, [name]: AppConstants.emptyString } : error)));
        } else {
            setErrors(errors.map((error, i) => (i === idx ? { ...error, [name]: StringUtils.getParsedMessage(ErrorMessages.gymnasticsRubricsErrors[name], { criteriaName: rbx.criterionName }) } : error)));
        }
    };

    const manageErrorsOnToggleChange = (event, rbx, idx) => {
        const targetErrorValues = errors[idx];
        targetErrorValues.gender = event.target.checked ? StringUtils.getParsedMessage(ErrorMessages.gymnasticsRubricsErrors.gender, { criteriaName: rbx.criterionName }) : AppConstants.emptyString;

        return targetErrorValues;
    };

    const toggleCriteria = (event, rbx, idx) => {
        const updatedAspects = rbx.aspects.map(aspect => ({
            ...aspect,
            included: event.target.checked
        }));

        const updatedRbx = { ...rbx, gender: AppConstants.emptyString, isCriteriaIncluded: event.target.checked, aspects: updatedAspects }
        setRubrics(rubrics.map((r, i) => (i === idx ? updatedRbx : r)));
        let targetErrorValues = manageErrorsOnToggleChange(event, rbx, idx);
        setErrors(errors.map((err, i) => i === idx ? targetErrorValues : err));
    };

    const toggleJudgementParameters = (event) => {
        let updatedRubrics = [];
        let updatedErrorValues = [];

        rubrics.forEach((rbx, idx) => {
            const updatedAspects = rbx.aspects.map(aspect => ({
                ...aspect,
                included: event.target.checked
            }));
            updatedErrorValues.push(manageErrorsOnToggleChange(event, rbx, idx));
            updatedRubrics.push({ ...rbx, gender: AppConstants.emptyString, isCriteriaIncluded: event.target.checked, aspects: updatedAspects })
        });
        setShowPercentageFlag(AppConstants.nText);
        setOutOfScore(AppConstants.emptyString);
        setRubrics(updatedRubrics);
        setErrors(updatedErrorValues);
    }

    const toggleCheckboxValue = ({ e, rbx, i, criteriaIndex }) => {
        const { aspectDescription } = rbx.aspects[i];

        if (AppConstants.gymnasticsStaticAspects.some(aspect => aspect.toLowerCase() === aspectDescription.toLowerCase()?.trim())) return;

        const updatedAspects = rbx.aspects.map((aspect, idx) => idx === i ? { ...aspect, included: e.target.checked } : aspect);
        const allFalse = updatedAspects.every(aspect => AppConstants.gymnasticsStaticAspects.includes(aspect.aspectDescription) || !aspect.included);

        if (allFalse) {
            toggleCriteria(e, rbx, criteriaIndex);
        } else {
            const newRubrics = rubrics.map(r =>
                r.criterionId === rbx.criterionId
                    ? { ...r, aspects: updatedAspects }
                    : r
            );

            setRubrics(newRubrics);
        }
    };

    const handleSubmit = () => {
        saveCurrentStep({ step: stages.indexOf(stage) });
    };

    const validateKeyDown = (event) => {
        const regex = /^\d*\.?\d*$/;

        if ((!regex.test(event.key) && event.key !== "Backspace") || (event.key === "." && outOfScore?.includes("."))) {
            event.preventDefault();
            return;
        }

        FormValidationUtils.preventNumberWithSpecialCharacters(event, ["-", "+"])
    };

    const changePercentageFlag = (e) => {
        const { value } = e.target;
        setShowPercentageFlag(value);
        if (value === AppConstants.nText) {
            setOutOfScore(AppConstants.emptyString);
        }
    };

    return (<>
        <div>
            <div className={classes.switchContainer}>
                <div className={classes.switchText}>Enable Judgement Parameters <span><input type="checkbox" checked={hasIncludedAspect() > 0} onChange={toggleJudgementParameters} /></span></div>
            </div>
            <div>
                <Row xs={1} sm={2} md={2} lg={2} xl={3}>
                    <Form.Group className="mb-2" as={Col}>
                        <Form.Label className={formClass.formLabel}>{formLabels.showPercentage.label}<span className="redAstrisk">*</span></Form.Label>
                        <Form.Select className={formClass.selectInput}
                            name={formLabels.showPercentage.name}
                            value={showPercentageFlag}
                            disabled={hasIncludedAspect() === 0}
                            readOnly={hasIncludedAspect() === 0}
                            onChange={changePercentageFlag} >
                            <option value={AppConstants.nText} defaultValue>No</option>
                            <option value={AppConstants.yText}>Yes</option>

                        </Form.Select>
                    </Form.Group>
                    {showPercentageFlag === AppConstants.yText && <Form.Group className="mb-2" as={Col}>
                        <Form.Label className={formClass.formLabel}>{formLabels.levelWiseOutOf.label}<span className="redAstrisk">*</span></Form.Label>
                        <Form.Control className={formClass.formInput}
                            type="number"
                            placeholder="Level wise (Out of score)"
                            disabled={hasIncludedAspect() === 0}
                            readOnly={hasIncludedAspect() === 0}
                            name={formLabels.levelWiseOutOf.name}
                            value={outOfScore}
                            inputMode="numeric"
                            onKeyDown={validateKeyDown}
                            onChange={handleMaxDScoreChange}
                        />
                    </Form.Group>}
                </Row>
                {rubrics.map((rbx, idx) => (
                    <div key={rbx.criterionUniqueId} className={classes.rubricsSection}>
                        <div className={classes.switchContainer}>
                            <div className={classes.switchText}>{idx + 1}. {rbx.criterionName} <span><input type="checkbox" checked={rbx.isCriteriaIncluded} onChange={(e) => toggleCriteria(e, rbx, idx)} /></span></div>
                        </div>
                        {rbx.isCriteriaIncluded ? <>
                            <div>
                                <Row xs={1} sm={3} md={2} lg={2} xl={3} className="mb-2">
                                    <Form.Group className="mb-2" as={Col}>
                                        <Form.Label className={formClass.formLabel}>{formLabels.gender.label}<span className="redAstrisk">*</span></Form.Label>
                                        <Form.Select className={formClass.selectInput}
                                            name={formLabels.gender.name}
                                            value={rubrics[idx].gender}
                                            onChange={(e) => handleChange(e, rubrics[idx], idx)}
                                        ><option value={AppConstants.emptyString} defaultValue>Select Gender</option>
                                            {AppConstants.gymnasticsGenderValues?.map((type, i) => (
                                                <option key={i} value={type}>
                                                    {type}
                                                </option>
                                            ))}
                                        </Form.Select>
                                    </Form.Group>
                                </Row>
                            </div>
                            <Row>
                                <Table className={classes.addRubricsTable} striped bordered hover cellPadding={0} cellSpacing={0} responsive="lg">
                                    <thead>
                                        <tr align="center">
                                            <th className={classes.headers}>{rubricsTableHeaders[1]}</th>
                                            <th className={classes.headers}>{rubricsTableHeaders[3]}</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            rbx.aspects?.map((aspect, i) => {
                                                return <tr align="left" id={aspect.aspectUniqueId} key={aspect.aspectUniqueId}
                                                    className={classes.tableRow}
                                                >
                                                    <td>{aspect.aspectDescription}</td>
                                                    <td>
                                                        <input type="checkbox"
                                                            key={aspect.aspectUniqueId}
                                                            checked={aspect.included === true}
                                                            disabled={AppConstants.gymnasticsStaticAspects.includes(aspect.aspectDescription)} readOnly={AppConstants.gymnasticsStaticAspects.includes(aspect.aspectDescription)}
                                                            onChange={(e) => toggleCheckboxValue({ e, rbx, i, criteriaIndex: idx })}
                                                        ></input>
                                                    </td>
                                                </tr>
                                            })
                                        }
                                    </tbody>
                                </Table>
                                {idx < rubrics.length - 1 && <hr />}
                            </Row>
                        </> : <>
                            <Row className={classes.warningErrorContainer}>
                                <div className={classes.warningMessage}><p>Please select {rbx.criterionName} to configure its judging parameters</p></div>
                            </Row>
                        </>}

                    </div>
                ))}
            </div>
            <div className={classes.generalInfoContainer}>
                <h3 className={classes.generalInfoHeading}>{AppConstants.generalInformationText}</h3>
                {
                    AppConstants.gymnasticsGeneralInfo.map((info, id) => <p key={id}
                        className={classes.generalInfoText}>
                        {info}
                    </p>)
                }
                <div className={classes.checkboxContainer}>
                    <input type="checkbox" readOnly checked={true} />
                    <p>{AppConstants.includeText}</p>
                </div>
                <div className={classes.checkboxContainer}>
                    <input type="checkbox" readOnly checked={false} />
                    <p>{AppConstants.excludeText}</p>
                </div>
            </div>
        </div>
        <div className={classes.nextBtn}>
            <Button type="submit" onClick={handleSubmit}>{AppConstants.nextText}</Button>
        </div>
    </>
    );
};